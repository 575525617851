import React from "react";
import { Col, Row } from "antd";
import "../../styles/cookiePolicy.css";

function CookiePolicySecondSection({ currLangJSON }) {
    const details = currLangJSON["cookiePolicyPage"].secondSec.details;
    const subSections = currLangJSON["cookiePolicyPage"].secondSec.subSections;

    return (
        <div className="mt-10 w-full flex justify-center">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="cookie-data-para px-4 md:px-8 lg:px-0">
                    <div className="cookie-data-section-heading">
                        {currLangJSON["cookiePolicyPage"].secondSec.heading}
                    </div>
                    <ul className="cookie-data-definition-list">
                        {details.map((item, index) => (
                            <li key={index} className="cookie-data-definition-item flex">
                                <strong className="cookie-data-term">{item.subheading}</strong>
                                <p className="cookie-data-definition">{item.description}</p>
                            </li>
                        ))}
                    </ul>
                    {subSections.map((section, index) => (
                        <div key={index}>
                            <div className="cookie-data-sub-section-heading">
                                {section.subHeading}
                            </div>
                            <p className="cookie-data-sub-section-description">{section.description}</p>
                            {section.details && (
                                <ul className="cookie-data-sub-definition-list">
                                    {section.details.map((subDetail, subIndex) => (
                                        <li key={subIndex} className="cookie-data-sub-definition-item">
                                            <strong className="cookie-data-sub-term">{subDetail.type}</strong>
                                            <ul className="cookie-data-sub-details">
                                                {subDetail.subDetails.map((detail, detailIndex) => (
                                                    <li key={detailIndex}>
                                                        <strong>{detail.subType}</strong>: {detail.administeredBy}<br />
                                                        <strong>Purpose:</strong> {detail.purpose}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
}

export default CookiePolicySecondSection;
