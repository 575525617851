import React from 'react';
import "../../styles/jobsPage/thirdSection.css";
import { Flex } from "antd";
import teamImg from '../../assets/jobsPage/team.jpeg';

function JobsThirdSection({ currLangJSON }) {
    return (
        <div className="w-[80%] mt-10 justify-self-center thirdComponentMainContainer">
            <div className="w-[100%] m-auto">
                <div className="publicLanding-thirdSec-secSubHeader text-center mt-1">
                    {currLangJSON["jobsPage.thirdSec.secSubHeader.subText"]}
                    <span className="yellow-text">
                        {currLangJSON["jobsPage.thirdSec.secSubHeader.yellowText"]}
                    </span>{" "}
                </div>
                <div className="text-[0.9rem] text-center text-[#475467] w-[80%] m-auto mt-2">
                    {currLangJSON["jobsPage.thirdSec.secSubHeader.subTextSm"]}
                </div>
            </div>
            {/* Image code */}
            {/* <Flex justify="center" align="middle" className="image-row">
                <img src={teamImg} alt="Team" className="team-image" />
            </Flex> */}
            <div className="w-[100%] m-auto">
                <div className="text-[0.9rem] text-center text-[#475467] w-[60%] m-auto mt-2">
                    {currLangJSON["jobsPage.thirdSec.companyMission"]}
                </div>
            </div>
            {/* <Flex justify="center" align="middle" className="button-row">
                <button className="apply-button">Apply Today</button>
            </Flex> */}
        </div>
    );
}

export default JobsThirdSection;
