import { Input, Pagination, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import JobCard from "./JobCard";
import Icon from "../../assets/icon/Icons";
import { getJobsApi, getJobsCountApi } from "../../Api";
import "../../styles/jobsPage/jobsResultsSection.css";

function JobsApplySection({ currLangJSON }) {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState();
  const [jdState, setJdState] = useState("ACTIVE");
  const pageSize = 5;
  const [searchValue, setSearchValue] = useState("");
  const [inputText, setInputText] = useState("");
  const jobsContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const fetchJobsCount = async () => {
    let api = `${getJobsCountApi}?searchInput=${searchValue}&jdState=${jdState}`;
    try {
      const response = await fetch(api);
      const data = await response.json();
      if (data.resultStatusInfo.resultCode === "Success") {
        setTotalJobs(data.data);
      } else {
        console.error("Could not retrieve jobs");
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
  useEffect(() => {
    fetchJobsCount();
  }, [searchValue]);

  useEffect(() => {
    fetchJobs();
  }, [currentPage, searchValue]);

  const fetchJobs = async () => {
    setLoading(true);
    let api = `${getJobsApi}?searchInput=${searchValue}&pageNumber=${
      currentPage - 1
    }&pageSize=${pageSize}&jdState=${jdState}`;
    try {
      const response = await fetch(api);
      const data = await response.json();
      if (data.resultStatusInfo.resultCode === "Success") {
        setJobs(data.data);
      } else if (data.resultStatusInfo.resultCode === "NotFound") {
        setJobs([]);
      } else {
        console.error("Could not retrieve jobs");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (jobsContainerRef.current) {
      jobsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSearchJobs = () => {
    setSearchValue(inputText);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setInputText("");
    setSearchValue("");
    setCurrentPage(1);
  };

  return (
    <section
      id="hirePPJobList"
      className="w-[80%] lg:w-[65%] m-auto mt-12 mb-14"
      ref={jobsContainerRef}
    >
      <div className="text-center">
        <div
          className="text-3xl font-semibold"
          dangerouslySetInnerHTML={{
            __html: currLangJSON["jobsPage.jobResults.heading"],
          }}
        ></div>
        <div className="text-base text-[#475467] mt-3 w-full lg:w-[55%] mx-auto">
          {currLangJSON["jobsPage.jobResults.subHeading"]}
        </div>
      </div>

      <main className="w-full lg:w-[full] mx-auto mt-8 mb-6">
        <div className="flex flex-col items-center mb-5">
          <div className="flex flex-col md:flex-row items-center justify-center w-full gap-4">
            <Input
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className="basis-[50%] px-2 py-2 border border-[#D0D5DD] shadow-sm"
              prefix={<Icon type="job-section-search" className="mr-2" />}
              placeholder={`${currLangJSON["jobsPage.jobResults.searchJobs"]}...`}
            />
            <div className="flex gap-2 mt-4 md:mt-0">
              <button
                onClick={handleSearchJobs}
                className="search-btn w-[120px] px-3 py-[9px] font-semibold text-[#F2F8FE] bg-gradient-to-tr from-[#103662] to-[#206DC5] text-[13px] rounded-lg shadow-sm"
              >
                {currLangJSON["jobsPage.jobResults.search"]}
              </button>
              <button
                onClick={handleClearSearch}
                className="clear-btn w-[120px] px-3 py-[9px] text-[13px] font-semibold text-[#344054] bg-white border border-[#D0D5DD] rounded-lg shadow-sm"
              >
                {currLangJSON["jobsPage.jobResults.clear"]}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {loading ? (
            Array.from({ length: pageSize }).map((_, index) => (
              <Skeleton key={index} active />
            ))
          ) : jobs.length > 0 ? (
            jobs.map((job) => <JobCard key={job.jdIdentifier} job={job} />)
          ) : (
            <p className="text-center text-base text-[#475467] mt-5 mb-8">
              No jobs available currently...
            </p>
          )}
        </div>
        {jobs.length > 0 && !loading ? (
          <Pagination
            current={currentPage}
            total={totalJobs}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
            className="mt-5 text-right"
          />
        ) : null}
      </main>
    </section>
  );
}

export default JobsApplySection;
