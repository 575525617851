import React from 'react';
import "../../styles/jobsPage/thirdSection.css";
import { Row, Col } from "antd";

function JobsFifthSection({ currLangJSON }) {
    return (
        <div className="w-[83%] mt-10 justify-self-center fifthComponentMainContainer">
            <div className="w-[100%] m-auto text-center">
                <div className="publicLanding-thirdSec-secSubHeader text-center mt-1">
                    {currLangJSON["jobsPage.fifthSec.heading.subText1"]}
                    <span className="yellow-text">
                        {currLangJSON["jobsPage.fifthSec.heading.subText2"]}
                    </span>{" "}
                </div>
                <div className="text-[0.9rem] text-center text-[#475467] w-[80%] m-auto mt-2">
                    {currLangJSON["jobsPage.thirdSec.secSubHeader.subTextSm"]}
                </div>
            </div>
            <div className="w-[100%] m-auto" style={{ marginTop: "40px" }}>
                <Row gutter={[8, 8]} justify="center">
                    <Col xs={24} md={12} lg={6}>
                        <div className="text-left">
                            <h3 className="fifthheading">{currLangJSON["jobsPage.fifthSec.talentDiscovery.heading"]}</h3>
                            <p className="fifthdescription">{currLangJSON["jobsPage.fifthSec.talentDiscovery.description"]}</p>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="text-left">
                            <h3 className="fifthheading">{currLangJSON["jobsPage.fifthSec.skillEvaluation.heading"]}</h3>
                            <p className="fifthdescription">{currLangJSON["jobsPage.fifthSec.skillEvaluation.description"]}</p>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="text-left">
                            <h3 className="fifthheading">{currLangJSON["jobsPage.fifthSec.culturalFit.heading"]}</h3>
                            <p className="fifthdescription">{currLangJSON["jobsPage.fifthSec.culturalFit.description"]}</p>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="text-left">
                            <h3 className="fifthheading">{currLangJSON["jobsPage.fifthSec.valueSystemAlignment.heading"]}</h3>
                            <p className="fifthdescription">{currLangJSON["jobsPage.fifthSec.valueSystemAlignment.description"]}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default JobsFifthSection;
