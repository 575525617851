import React, { useState, useEffect, useRef } from "react";
import "../../styles/landingPage/thirdSection.css";
import { Col, Row, Flex } from "antd";
import HorizontalSection from "./HorizontalSection";
import VideoPlayer from "./VideoPlayer";
import vid1 from "../../assets/landingPage/thirdSecVids/vid_1.mp4";
import vid2 from "../../assets/landingPage/thirdSecVids/vid_2.mp4";
import vid3 from "../../assets/landingPage/thirdSecVids/vid_3.mp4";
import vid4 from "../../assets/landingPage/thirdSecVids/vid_4.mp4";
import vid5 from "../../assets/landingPage/thirdSecVids/vid_5.mp4";
import Icon from "../../assets/icon/Icons";

function ThirdSection({ currLangJSON }) {
  const [currTile, setCurrTile] = useState(1);
  const [currVid, setCurrVideo] = useState(vid1);
  const [fade, setFade] = useState(false);
  const intervalRef = useRef(null);
 
  const startInterval = (duration) => {
    intervalRef.current = setInterval(() => {
      setCurrTile((prevTile) => {
        const nextTile = (prevTile % 5) + 1;
        setCurrVideo(videoSelect(nextTile));
        return nextTile;
      });
    }, duration);
  };

  useEffect(() => {
    startInterval(15000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    const currActiveBtn = document.querySelector(".btnCol_thirdSec.currActive");
    if (currActiveBtn) {
      currActiveBtn.classList.remove("currActive");
    }
    const newActiveBtn = document.querySelector(
      `.btnCol_thirdSec.btn${currTile}`
    );
    if (newActiveBtn) {
      newActiveBtn.classList.add("currActive");
    }
  }, [currTile]);

  useEffect(() => {
    setFade(false);
    const timer = setTimeout(() => {
      setFade(true);
    }, 500); // Delay for text fade transition
    return () => clearTimeout(timer);
  }, [currTile]);

  function videoSelect(num) {
    switch (num) {
      case 1:
        return vid1;
      case 2:
        return vid2;
      case 3:
        return vid3;
      case 4:
        return vid4;
      case 5:
        return vid5;
      default:
        return vid1;
    }
  }

  function slideChangerFunc(event, num) {
    const currBtn = event.target;
    clearInterval(intervalRef.current);
    startInterval(15000);
    if (window.innerWidth > 768) {
      const currBtnCol = currBtn.closest(".btnCol_thirdSec");
      const currActiveBtn = document.querySelector(".currActive");
      if (currActiveBtn) {
        currActiveBtn.classList.remove("currActive");
        // return;
      }else{

        currBtnCol.classList.add("currActive");
      }
    } else {
      const currBtnCol = currBtn.closest(".btn3ImgContainer");
      const currActiveBtn = document.querySelector(".btn3ImgContainer.currActive");
      if (currActiveBtn) {
        currActiveBtn.classList.remove("currActive");
      }
      currBtnCol.classList.add("currActive");
    }
    const updateVid = videoSelect(num);
    setCurrVideo(updateVid);
    setCurrTile(num);
  }

  return (
    <div className="w-[80%] mt-10 justify-self-center thirdComponentMainContainer">
      <div className="publicLanding-thirdSec-header">
        {currLangJSON["publicLanding.thirdSec.header"]}
      </div>

      <div className="w-[100%] m-auto">
        <div className="publicLanding-thirdSec-secSubHeader text-center mt-1">
          {currLangJSON["publicLanding.thirdSec.secSubHeader.subText1"]}
          <span className="yellow-text">
            {currLangJSON["publicLanding.thirdSec.secSubHeader.yellowText"]}
          </span>{" "}
          {currLangJSON["publicLanding.thirdSec.secSubHeader.subText2"]}
        </div>
      </div>
      <div className="midSpace1"></div>

      <Row className="mbSection_three_btn">
        <HorizontalSection
          slideChangerFunc={slideChangerFunc}
          currLangJSON={currLangJSON}
          currTile={currTile}
        />
      </Row>
      <Row justify="space-evenly" className="dtSection_three_btn">
        <Col md={4} className="btnCol_thirdSec btn1 currActive">
          <Flex justify="center">
            <div
              className="icon-container border p-5 rounded-[12px] bg-[#D4E7FD]"
              onClick={(e) => slideChangerFunc(e, 1)}
            >
              <Icon type="feature-section-ai" />
            </div>
          </Flex>
          <p className="btnTxt mt-3" onClick={(e) => slideChangerFunc(e, 1)}>
            {currLangJSON["publicLanding.thirdSec.btn1.Header"]}
          </p>
        </Col>
        <Col md={4} className="btnCol_thirdSec btn2">
          <Flex justify="center">
            <div
              className="icon-container border p-5 rounded-[12px] bg-[#D4E7FD]"
              onClick={(e) => slideChangerFunc(e, 2)}
            >
              <Icon type="feature-section-aiSearch" />
            </div>
          </Flex>
          <p className="btnTxt mt-3" onClick={(e) => slideChangerFunc(e, 2)}>
            {currLangJSON["publicLanding.thirdSec.btn2.Header"]}
          </p>
        </Col>
        <Col md={4} className="btnCol_thirdSec btn3">
          <Flex justify="center">
            <div
              className="icon-container border p-5 rounded-[12px] bg-[#D4E7FD]"
              onClick={(e) => slideChangerFunc(e, 3)}
            >
              <Icon type="feature-section-candidate" />
            </div>
          </Flex>
          <p className="btnTxt mt-3" onClick={(e) => slideChangerFunc(e, 3)}>
            {currLangJSON["publicLanding.thirdSec.btn3.Header"]}
          </p>
        </Col>
        <Col md={4} className="btnCol_thirdSec btn4">
          <Flex justify="center">
            <div
              className="icon-container border p-5 rounded-[12px] bg-[#D4E7FD]"
              onClick={(e) => slideChangerFunc(e, 4)}
            >
              <Icon type="feature-section-inDepth" />
            </div>
          </Flex>
          <p className="btnTxt mt-3" onClick={(e) => slideChangerFunc(e, 4)}>
            {currLangJSON["publicLanding.thirdSec.btn4.Header"]}
          </p>
        </Col>
        <Col md={4} className="btnCol_thirdSec btn5">
          <Flex justify="center">
            <div
              className="icon-container border p-5 rounded-[12px] bg-[#D4E7FD]"
              onClick={(e) => slideChangerFunc(e, 5)}
            >
              <Icon type="feature-section-report" />
            </div>
          </Flex>
          <p className="btnTxt mt-3" onClick={(e) => slideChangerFunc(e, 5)}>
            {currLangJSON["publicLanding.thirdSec.btn5.Header"]}
          </p>
        </Col>
      </Row>
      <div className="midSpace1"></div>

      <Row justify="space-evenly" className="my-2 ">
        <Col md={12}>
          <div className={`centered-content ${fade ? "fade-in" : ""}`}>
            <p className="btnHeading text-4xl">
              {currLangJSON[`publicLanding.thirdSec.btn${currTile}.Header`]}
            </p>
            <div className="midSpace2"></div>
            <p className="landingPgGreyTxt text-base">
              {currLangJSON[`publicLanding.thirdSec.btn${currTile}.subText`]} 
            </p>
          </div>
        </Col>
        <Col md={12} className="flex justify-center">
          <VideoPlayer
            src={currVid}
            type="video/mp4"
            poster=""
            width="100%"
            height="max-content"
            fixedHeight="max-content" // Set the fixed height for the video container
          />
        </Col>
      </Row>
    </div>
  );
}

export default ThirdSection;
