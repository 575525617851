import { useEffect } from "react";

function SkillsSection(props) {
  const { title, description, parentId } = props;
  let counter = 0;
  const scrollHandler = () => {
    console.log("scrollHandler");
  };
  const currentUrl = window.location.href;
//   console.log(currentUrl);
  useEffect(() => {
    let counter = 0; // Initialize the counter inside the useEffect

    const myFunction = () => {
        const skillSectionWrapper = document.getElementById(
            `skill-section-wrapper-${parentId}`
        );

        // console.log(skillSectionWrapper, counter);
        if (skillSectionWrapper) {
            const children = skillSectionWrapper.childNodes;

            // Check if there are more children to clone
            if (counter < children.length) {
                const clone = children[counter].cloneNode(true);
                clone.setAttribute("data-index", counter);
                skillSectionWrapper.append(clone);
                counter++; // Increment the counter only if cloning is successful
            } else {
                counter = 0; // Reset counter if all children have been cloned
            }
        } else {
            counter = 0; // Reset counter if skillSectionWrapper is null
        }
    };

    const intervalId = setInterval(myFunction, 3000); // Store intervalId for later clearInterval

    // Clear the interval when the component unmounts
    return () => {
        clearInterval(intervalId);
    };
}, [parentId]);

//   useEffect(() => {
//     // counter = 0;
//     const myFunction = () => {
//         let skillSectionWrapper = document.getElementById(
//             `skill-section-wrapper-${parentId}`
//         );
        
//         console.log(skillSectionWrapper,counter)
//         if (skillSectionWrapper) {
//             const childern = skillSectionWrapper.childNodes;
//             if (childern) {
//                 // if(childern[counter].cloneNode ===undefined){
//                     // counter=0
//                   var clone = childern[counter].cloneNode(true);
//                   clone.setAttribute("data-index", counter);
//                   skillSectionWrapper.append(clone);
//                 // }
//         }
//         counter++;
//       }else{
//         counter=0;
//         // skillSectionWrapper=null;
//         // clearInterval(myFunction);
//         // return;
//       }
     
//     };
//     clearInterval(myFunction);
//     setInterval(myFunction, 3000);
//   }, [parentId]);

  return (
    <div className="eight-section">
      {/* {title}
            {description} */}
      <marquee direction="left" scrollamount={7}>
        <div
          id={`skill-section-wrapper-${parentId}`}
          className=" items-center "
          style={{
            display: "flex",
            alignItems: "center",
            transform: "translateX(-1000px)",
            minWidth: 2400,
          }}
        >
          <div className=" logo-wrapper ">
            <div className="skill-1  box-wrapper " style={{ width: 180 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-2  box-wrapper" style={{ width: 240 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-3 box-wrapper" style={{ width: 370 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-4 box-wrapper" style={{ width: 230 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-5 box-wrapper" style={{ width: 280 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-6 box-wrapper" style={{ width: 170 }}></div>
          </div>

          <div className=" logo-wrapper">
            <div className="skill-7 box-wrapper" style={{ width: 330 }}></div>
          </div>
          <div className=" logo-wrapper">
            <div className="skill-8 box-wrapper" style={{ width: 310 }}></div>
          </div>
          {/* <div className=" logo-wrapper">
                    <div className="skill-10"  style={{width: 54, height: 54 }}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-17"  style={{width: 28, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-16" style={{width: 140, height: 54}}></div>
                </div>

                <div className=" logo-wrapper">
                    <div className="skill-14" style={{width: 188, height: 54}}></div>
                </div>

                <div className=" logo-wrapper">
                    <div className="skill-1"  style={{width: 58, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-7"  style={{width: 84, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-5"  style={{width: 186, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-6"  style={{width: 244, height: 53}}></div>
                </div>

                <div className=" logo-wrapper">
                    <div className="skill-9"  style={{width: 72, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-13" style={{width: 142, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-15" style={{width: 72, height: 54}}></div>
                </div>
                <div className=" logo-wrapper">
                    <div className="skill-18" style={{width: 170, height: 54}}></div>
                </div> */}
        </div>
      </marquee>
    </div>
  );
}
export default SkillsSection;

//https://sage-answers.com/how-does-a-marquee-text-move/#:~:text=How%20do%20you%20delay%20marquee%3F%20Attribute%20for%20Marquee,milliseconds%20%28a%20millisecond%20is%201%2F1000th%20of%20a%20second%29.
