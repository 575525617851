import React from "react";
import SkillsSection from "../SkillsSection";

function EightSection({ currLangJSON }) {
  return (
    <div>
      <div className=" w-[90%] m-auto text-center mt-14 font-bold mb-4" style={{fontSize:"1.2rem"}}>
        {currLangJSON["publicLanding.eightSec.header"]} {" "}
        <span className="yellow-text">
          {currLangJSON["publicLanding.eightSec.header.yellowText"]}
        </span>
      </div>
      <SkillsSection parentId="public-page" />;
    </div>
  );
}

export default EightSection;
