import React from "react";
import "../../styles/landingPage/sixthSection.css";
import { Col, Row, Flex } from "antd";

import { useState, useEffect } from "react";
import landingPageImg from "../../assets/image/landingPageImg.png";
import mobileImg from "../../assets/image/mobile.png";

function SixthSection({ currLangJSON }) {
  const [currTile, setCurrTile] = useState(1);
  function cardChangerFunc(event, num) {
    var currBtn = event.target;
    var currBtnCol = currBtn.closest(".sixthCardCol");
    var currActiveBtn = document.querySelector(".activeCard");
    currActiveBtn.classList.remove("activeCard");
    currBtnCol.classList.add("activeCard");
    setCurrTile(num);
  }
  return (
    <div className="w-[80%] mt-14 justify-self-center thirdComponentMainContainer">
      <div className="publicLanding-sixthSection-header">
        {currLangJSON["publicLanding.sixthSection.header"]} 
      </div>
      <div className="w-[100%] m-auto">
        <div className="publicLanding-sixthSection-secSubHeader text-center mt-1">
          {currLangJSON["publicLanding.sixthSection.subHeader.blackText1"]}
          <span className="yellow-text">
            {currLangJSON["publicLanding.sixthSection.subHeader.yellowText1"]}
          </span>{" "}
          {currLangJSON["publicLanding.sixthSection.subHeader.blackText2"]}
          <span className="yellow-text">
            {currLangJSON["publicLanding.sixthSection.subHeader.yellowText2"]}
          </span>{" "}
          {currLangJSON["publicLanding.sixthSection.subHeader.blackText3"]}
        </div>
      </div>
      <div className="midSpace1"></div>
      <div className="w-[100%] m-auto">
        <Row>
          <Col md={24} lg={12} xl={12}>
            <Col md={24}>
              <div className="sixth-img-container">
                <img
                  className="sixthSecImgContainerimgMB"
                  src={landingPageImg}
                  alt="interview"
                ></img>
                <img
                  className="sixthSecMobileImgMB"
                  src={mobileImg}
                  alt="mobile"
                ></img>
              </div>
            </Col>
            <Col md={24} className="sixthCardCol activeCard">
              <div
                onMouseOver={(e) => cardChangerFunc(e, 1)}
                className="sixthSecCards"
              >
                <p className="text-2xl font-[600]">
                  {currLangJSON["publicLanding.sixthSection.card1.header"]}
                </p>
                <p className="text-base text-[#475467] font-[400]">
                  {currLangJSON["publicLanding.sixthSection.card1.subHeader"]} 
                </p>
              </div>
            </Col>
            <Col md={24} className="sixthCardCol">
              <div
                onMouseOver={(e) => cardChangerFunc(e, 2)}
                className="sixthSecCards"
              >
                <p className="text-2xl font-[600]">
                  {currLangJSON["publicLanding.sixthSection.card2.header"]}
                </p>
                <p className="text-base text-[#475467] font-[400]">
                  {currLangJSON["publicLanding.sixthSection.card2.subHeader"]}
                </p>
              </div>
            </Col>
            <Col md={24} className="sixthCardCol">
              <div
                onMouseOver={(e) => cardChangerFunc(e, 3)}
                className="sixthSecCards"
              >
                <p className="text-2xl font-[600]">
                  {currLangJSON["publicLanding.sixthSection.card3.header"]}
                </p>
                <p className="text-base text-[#475467] font-[400]">
                  {currLangJSON["publicLanding.sixthSection.card3.subHeader"]}
                </p>
              </div>
            </Col>
          </Col>
          <Col md={1} offset={1}></Col>
          <Col md={24} lg={10} xl={10}>
            <div className="sixth-img-container  pt-1">
              <img
                className="sixthSecImgContainerimg"
                src={landingPageImg}
                alt="interview"
              ></img>
              <img
                className="sixthSecMobileImg"
                src={mobileImg}
                alt="mobile"
              ></img>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SixthSection;
