import React, { useEffect, useRef, useState } from "react";
import HeroSection from "../components/landingPage/HeroSection";
import Topbar from "../components/TopBar";
import "../styles/publicLandingPage.css";
import Footer from "../components/Footer";
import NotificationSection from "../components/NotificationSection";
import SecondSection from "../components/landingPage/SecondSection";
import English from "../utils/language/English.json";
import German from "../utils/language/German.json";
import French from "../utils/language/French.json";
import ForthSection from "../components/landingPage/ForthSection";
import FifthSection from "../components/landingPage/FifthSection";
import ThirdSection from "../components/landingPage/thirdSection";
import EightSection from "../components/landingPage/EightSection";
import SixthSection from "../components/landingPage/SixthSection";
import SeventhSection from "../components/landingPage/SeventhSection";
import ScriptInjector from "../components/ScriptInjector"; 
// Import the ScriptInjector component
import Cookies from "js-cookie";

let storedLang = localStorage.getItem("hirePPCurrLang");

function LandingPage() {
  const landingPage = useRef();
  const [globalLang, setGlobalLang] = useState(English);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scriptFlag, setScriptFlag] = useState(false);

  useEffect(() => {
    const hirePPInitCookie = Cookies.get("hirePPInitCookie");
    if (!hirePPInitCookie) {
      setIsModalVisible(true);
    }
    const handleScroll = () => {
      const topBar = document.querySelector(".topbar-logo");
      const hamburger = document.querySelector(".fas.fa-bars");
      const backCover = document.querySelector(".back-cover");
      const weServe = document.querySelector(".weServe-text");
      const topbardText = document.querySelectorAll(".topbard-text");
      const topBarNav = document.querySelector(
        ".topbar .right-section .nav-menu "
      );
      var aiIcon = document.querySelector(".ai-topbar-icon path")

      if (window.scrollY > 0) {
        topBar.style.background = "white";
        backCover.style.background = "white";
        backCover.style.boxShadow =
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px";
        topBar.style.boxShadow =
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px";
        hamburger.style.color = "#0638c3";
        topbardText.forEach((text) => (text.style.color = "#0638c3"));
        weServe.style.color = "#0638c3";
        topBar.style.transition = "background 0.3s ease";
        topBarNav.style.transition = "background 0.3s ease";
        if (aiIcon) {
          // aiIcon.style.fill = 'blue';
          aiIcon.style.stroke = 'rgb(32, 109, 197)';;
          aiIcon.style.transition = 'all ease-in-out 0.2ms';
        }
      } else {
        if (aiIcon) {
          // aiIcon.style.fill = 'blue';
          aiIcon.style.stroke = 'white';
          aiIcon.style.transition = 'all ease-in-out 0.2ms';
        }
        hamburger.style.color = "white";
        weServe.style.color = "white";
        topbardText.forEach((text) => (text.style.color = "white"));
        topBar.style.background = "none";
        backCover.style.background = "none";
        backCover.style.boxShadow = "none";
        topBar.style.boxShadow = "none";
        topBar.style.transition = "background 0.3s ease";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAccept = () => {
    Cookies.set("hirePPInitCookie", "true");
    setIsModalVisible(false);
    setScriptFlag(!scriptFlag);
  };

  const handleReject = () => {
    Cookies.set("hirePPInitCookie", "false");
    setIsModalVisible(false);
    setScriptFlag(!scriptFlag);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const langChange = (storedLang) => {
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
  };

  return (
    <div>
      <div
        className="public-landing-page"
        ref={landingPage}
        style={{ overflowX: "hidden" }}
      >
        <Topbar page="main" globalLang={langChange} />
        <HeroSection currLangJSON={globalLang} />
        <SecondSection currLangJSON={globalLang} />
        <ThirdSection currLangJSON={globalLang} />
        <ForthSection currLangJSON={globalLang} />
        <FifthSection currLangJSON={globalLang} />
        <div style={{ overflowX: "hidden" }}>
          <SixthSection currLangJSON={globalLang} />
        </div>
        <SeventhSection currLangJSON={globalLang} />
        <EightSection currLangJSON={globalLang} />
        <NotificationSection currLangJSON={globalLang} />
        <Footer currLangJSON={globalLang} />
      </div>

      {/* {isModalVisible && (
        <div className="fixed inset-0 flex items-end justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white py-6 px-10 shadow-lg rounded-lg w-11/12 md:w-9/12 mb-3 relative">
            <button
              className="absolute top-1 right-4 text-gray-600 text-2xl"
              onClick={handleClose}
            >
              &times;
            </button>
            <div className="p-4 text-left">
              <p className="text-sm">
                We use cookies to enhance your browsing experience. By
                continuing to use our site, you consent to our use of cookies.
                For more information, please refer to our{" "}
                <a href="/cookie-policy" className="text-blue-600">
                  Cookie Policy
                </a>
                . You can adjust your cookie settings at any time through your
                browser or our{" "}
                <a href="/cookie-settings" className="text-blue-600">
                  Cookie Settings
                </a>{" "}
                page.
              </p>
            </div>
            <div className="mt-2 flex flex-col sm:flex-row justify-end gap-2 sm:gap-4">
              <button
                className="w-full sm:w-[150px] py-[10px] px-5 text-[13px] font-semibold text-[#475467] bg-white border border-[#D0D5DD] rounded-[8px] shadow-sm"
                onClick={handleReject}
              >
                Reject All
              </button>
              <button
                className="w-full sm:w-auto py-[10px] px-5 text-[13px] font-semibold text-white bg-gradient-to-tr from-[#103662] to-[#206DC5] border border-[#039855] rounded-[8px] shadow-sm"
                onClick={handleAccept}
              >
                Accept All Cookies
              </button>
            </div>
          </div>
        </div>
      )} */}

      <ScriptInjector scriptFlag={scriptFlag} />
    </div>
  );
}

export default LandingPage;
