import Lottie from "lottie-react";
import React from "react";
import errorAnimation from "../../assets/lotties/404.json";

function ErrorLandingPage({currLangJSON}) {
  return (
    <div className="w-[80%] pt-[140px] m-auto mb-24">
      <Lottie
        className="m-auto w-[80%] h-[400px]"
        animationData={errorAnimation}
        loop={true}
        autoplay={true}
      />
      <h1 className="text-center font-semibold text-lg text-[#101828]">404 - Page Not Found</h1>
      <p className="text-center text-base mt-1 text-[#667085]">Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default ErrorLandingPage;
