import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function EmployerTermsFirstSection({ currLangJSON }) {
    return (
        <>
            <Row className="terms-hero-section" justify="center">
                <Col xs={24} md={20} lg={16} className="terms-content">
                    <div className="terms-banner">
                        <span>{currLangJSON["employerTerms.firstSec.currentDate"]}</span>
                    </div>
                    <div className="terms-main-heading">
                        {currLangJSON["employerTerms.firstSec.heading"]}
                    </div>
                    <div className="terms-sub-heading">
                        {currLangJSON["employerTerms.firstSec.subHeading"]}
                    </div>
                </Col>
            </Row>
            <div className="w-[100%] mt-10 justify-self-center termsParaMainContainer">
                    <Row>
                        <Col xs={20} md={20} lg={20} className="terms-para">
                            <Flex justify="center">
                                <div className="terms-intro-paragraph empTermsSecOnePara">
                                    <p className="bold-text">
                                        {currLangJSON["employerTerms.firstSec.introParagraph1"]}
                                    </p>
                                    <p className="empTermsSecOnePara">
                                        {currLangJSON["employerTerms.firstSec.introParagraph2"]}
                                    </p>
                                    <p className="empTermsSecOnePara">
                                        {currLangJSON["employerTerms.firstSec.introParagraph3"]}
                                    </p>
                                    <p className="empTermsSecOnePara">
                                        {currLangJSON["employerTerms.firstSec.introParagraph4"]}
                                    </p>
                                </div>
                            </Flex>
                        </Col>
                    </Row>
           
            </div>
        </>
    );
}

export default EmployerTermsFirstSection;
