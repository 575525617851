import React from "react";
import { Col, Row } from "antd";
import "../../styles/dataProtection.css";

function GDPRExhibitESection({ currLangJSON }) {
    const details = currLangJSON["dataProtectionPage"].thirteenthSec.details;

    return (
        <div className="mt-10 w-full flex justify-center">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="data-para px-4 md:px-8 lg:px-0">
                    <div className="data-section-heading">
                        {currLangJSON["dataProtectionPage"].thirteenthSec.heading}
                    </div>
                    <p className="data-definition">
                        {currLangJSON["dataProtectionPage"].thirteenthSec.intro}
                    </p>
                    <ul className="data-definition-list">
                        {details.map((item, index) => (
                            <li key={index} className="data-definition-item flex">
                                <span className="data-term-number mr-2">{item.subheadingNumber}</span>
                                <div>
                                    <strong className="data-term">{item.subheading}</strong>
                                    <ul className="list-disc list-inside ml-4">
                                        {item.bullets.map((bullet, bulletIndex) => (
                                            <li key={bulletIndex} className="data-definition">{bullet}</li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    );
}

export default GDPRExhibitESection;
