import React, { useEffect, useState } from 'react';
import Topbar from '../components/TopBar';
import Footer from '../components/Footer';
import English from "../utils/language/English.json";
import German from "../utils/language/German.json";
import French from "../utils/language/French.json";
import "../styles/dataProtection.css";
import GDPRFirstSection from '../components/GDPR/GDPRFirstSection';
import GDPRSecondSection from '../components/GDPR/GDPRSecondSection';
import GDPRThirdSection from '../components/GDPR/GDPRThirdSection';
import GDPRFourthSection from '../components/GDPR/GDPRFourthSection';
import GDPRFifthSection from '../components/GDPR/GDPRFifthSection';
import GDPRSixthSection from '../components/GDPR/GDPRSixthSection';
import GDPRSeventhSection from '../components/GDPR/GDPRSeventhSection';
import GDPREightSection from '../components/GDPR/GDPREightSection';
import GDPRNinthSection from '../components/GDPR/GDPRNinthSection';
import GDPRTenthSection from '../components/GDPR/GDPRTenthSection';
import GDPREleventhSection from '../components/GDPR/GDPREleventhSection';
import GDPRTwelthSection from '../components/GDPR/GDPRTwelthSection';
import GDPRThirteenthSection from '../components/GDPR/GDPRThirteenthSection';


function GDPRPage() {
    const [globalLang, setGlobalLang] = useState(English);

    useEffect(() => {
        const handleScroll = () => {
            var topBar = document.querySelector('.topbar-logo');
            var hamburger = document.querySelector('.fas.fa-bars');
            var backCover = document.querySelector('.back-cover');
            var weServe = document.querySelector('.weServe-text');
            var topbardText = document.querySelectorAll('.topbard-text');
            var topBarNav = document.querySelector('.topbar .right-section .nav-menu ');
            var aiIcon = document.querySelector(".ai-topbar-icon path")

            if (window.scrollY > 0) {
                topBar.style.background = 'white';
                backCover.style.background = 'white';
                backCover.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
                topBar.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
                hamburger.style.color = '#0638c3';
                topbardText.forEach((text) => (text.style.color = '#206DC5'));
                weServe.style.color = '#0638c3';
                topBar.style.transition = 'background 0.3s ease';
                topBarNav.style.transition = 'background 0.3s ease';
                if (aiIcon) {
                    // aiIcon.style.fill = 'blue';
                    aiIcon.style.stroke = 'rgb(32, 109, 197)';;
                    aiIcon.style.transition = 'all ease-in-out 0.2s';
                  }
            } else {
                if (aiIcon) {
                    // aiIcon.style.fill = 'blue';
                    aiIcon.style.stroke = 'white';
                    aiIcon.style.transition = 'all ease-in-out 0.2s';
                  }
                hamburger.style.color = 'white';
                weServe.style.color = 'white';
                topbardText.forEach((text) => (text.style.color = 'white'));
                topBar.style.background = 'none';
                backCover.style.background = 'none';
                backCover.style.boxShadow = "none";
                topBar.style.boxShadow = "none";
                topBar.style.transition = 'background 0.3s ease';
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const langChange = (storedLang) => {
        if (storedLang === null) {
            setGlobalLang(English);
        } else if (storedLang === "english") {
            setGlobalLang(English);
        } else if (storedLang === "french") {
            setGlobalLang(French);
        } else if (storedLang === "german") {
            setGlobalLang(German);
        }
    };

    return (
        <div className="public-landing-page">
            <Topbar page="" topbarCss="css" globalLang={langChange} />
            <GDPRFirstSection currLangJSON={globalLang} />
            <GDPRSecondSection currLangJSON={globalLang} />
            <GDPRThirdSection currLangJSON={globalLang} />
            <GDPRFourthSection currLangJSON={globalLang} />
            <GDPRFifthSection currLangJSON={globalLang} />
            <GDPRSixthSection currLangJSON={globalLang} />
            <GDPRSeventhSection currLangJSON={globalLang} />
            <GDPREightSection currLangJSON={globalLang} />
            <GDPRNinthSection currLangJSON={globalLang} />
            <GDPRTenthSection currLangJSON={globalLang} />
            <GDPREleventhSection currLangJSON={globalLang} />
            <GDPRTwelthSection currLangJSON={globalLang} />
            <GDPRThirteenthSection currLangJSON={globalLang} />
            <Footer currLangJSON={globalLang} />
        </div>
    );
}

export default GDPRPage;
