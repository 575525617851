import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacyNinthSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.ninthSec.table.columns.right"],
      dataIndex: 'right',
      key: 'right',
    },
    {
      title: currLangJSON["privacyPage.ninthSec.table.columns.description"],
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: currLangJSON["privacyPage.ninthSec.table.columns.howToExercise"],
      dataIndex: 'howToExercise',
      key: 'howToExercise',
    },
  ];

  const data = currLangJSON["privacyPage.ninthSec.table.rows"].map((row, index) => ({
    key: index,
    right: row.right,
    description: row.description,
    howToExercise: row.howToExercise,
  }));

  const additionalInfo = currLangJSON["privacyPage.ninthSec.additionalInfo"].map((info, index) => (
    <div key={index} className="mb-4">
      <h4 className="font-semibold">{info.title}</h4>
      <p>{info.description}</p>
    </div>
  ));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.ninthSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.ninthSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.ninthSec.additionalInfoHeading"]}</h3>
      <div>{additionalInfo}</div>
    </div>
  );
};

export default PrivacyNinthSection;
