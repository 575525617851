import React, { useState } from "react";
import Card1 from "../../assets/image/forthSecCard1.svg";
import Card2 from "../../assets/image/forthSecCard2.svg";
import Card3 from "../../assets/image/forthSecCard3.svg";
import Card4 from "../../assets/image/forthSecCard4.svg";
import { Col, Collapse, Row } from "antd";

function ForthSection({ currLangJSON }) {
  const [key, setkey] = useState();
  const item1 = [
    {
      key: "1",
      label: (
        <p className="bg-[#EAF3FE] p-0 m-0 font-bold text-[1.1rem]">
          {currLangJSON["publicLanding.forthSec.card1.header"]}
        </p>
      ),

      children: (
        <p className="bg-[#EAF3FE] p-0 m-0 text-[.8rem]">
          {currLangJSON["publicLanding.forthSec.card1.subHeader"]}
        </p>
      ),
    },
  ];
  const item2 = [
    {
      key: "2",
      label: (
        <p className="bg-[#EAF3FE] p-0 m-0 font-bold text-[1.1rem]">
          {currLangJSON["publicLanding.forthSec.card2.header"]}
        </p>
      ),

      children: (
        <p className="bg-[#EAF3FE] p-0 m-0 text-[.8rem] ">
          {currLangJSON["publicLanding.forthSec.card2.subHeader"]}
        </p>
      ),
    },
  ];
  const item3 = [
    {
      key: "3",
      label: (
        <p className="bg-[#EAF3FE] p-0 m-0 font-bold text-[1.1rem]">
          {currLangJSON["publicLanding.forthSec.card3.header"]}
        </p>
      ),

      children: (
        <p className="bg-[#EAF3FE] p-0 m-0 text-[.8rem]">
          {currLangJSON["publicLanding.forthSec.card3.subHeader"]}
        </p>
      ),
    },
  ];
  const item4 = [
    {
      key: "4",
      label: (
        <p className="bg-[#EAF3FE] p-0 m-0 font-bold text-[1.1rem]">
          {currLangJSON["publicLanding.forthSec.card4.header"]}
        </p>
      ),

      children: (
        <p className="bg-[#EAF3FE] p-0 m-0 text-[.8rem]">
          {currLangJSON["publicLanding.forthSec.card4.subHeader"]}
        </p>
      ),
    },
  ];
  const handleChange = (e) => {
    console.log(e[0]);
    setkey(e[0]);
  };
  // lassName='hirePPLandingPageBoxShadow'
  return (
    <div className="mt-14 w-full">
      <div className="publicLanding-secondSec-header">
        {currLangJSON["publicLanding.forthSec.header"]}
      </div>
      <div>
        <div className="publicLanding-secondSec-secSubHeader text-center mt-1">
          <span className="yellow-text">
            {currLangJSON["publicLanding.forthSec.subHeader.yellowText"]}
          </span>{" "}
          {currLangJSON["publicLanding.forthSec.subHeader"]}
        </div>
      </div>

      <div className="publicLanding-forthSec-card w-[80%] mx-auto my-auto gap-1 mt-3  flex flex-wrap justify-start items-start">
        <div className="bg-[#EAF3FE] mt-3  p-2 items-center mx-auto w-[280px] hirePPLandingPageBoxShadow">
          <img src={Card1} alt="" className="mx-auto py-2 " />
          <Collapse
            accordion={true}
            // activeKey={["1"]}
            ghost={true}
            style={{ background: "#EAF3FE ", border: "none" }}
            // defaultActiveKey={["1"]}
            bordered={false}
            // onChange={onChange}
            expandIconPosition="end"
            items={item1}
            onChange={handleChange}
          />
        </div>
        <div
          md={5}
          sm={12}
          xs={24}
          className="bg-[#EAF3FE] p-2 mt-3 items-center mx-auto w-[280px] hirePPLandingPageBoxShadow"
        >
          <img src={Card2} alt="" className="mx-auto py-2 " />
          <Collapse
            accordion={true}
            ghost={true}
            // style={{ background: "#F2F8FE", border: "none" }}
            // defaultActiveKey={["1"]}
            bordered={false}
            // onChange={onChange}
            expandIconPosition="end"
            items={item2}
          />
        </div>
        <div
          md={5}
          sm={12}
          xs={24}
          className="bg-[#EAF3FE] p-2 mt-3 items-center mx-auto w-[280px] hirePPLandingPageBoxShadow"
        >
          <img src={Card3} alt="" className="mx-auto py-2" />
          <Collapse
            style={{ background: "#EAF3FE", border: "none" }}
            // defaultActiveKey={["1"]}
            bordered={false}
            // onChange={onChange}
            expandIconPosition="end"
            items={item3}
          />
        </div>
        <div
          md={5}
          sm={12}
          xs={24}
          className="bg-[#EAF3FE] mt-3 p-2 items-center mx-auto w-[280px] hirePPLandingPageBoxShadow"
        >
          <img src={Card4} alt="" className="mx-auto py-2" />
          <Collapse
            style={{ background: "#EAF3FE", border: "none" }}
            // defaultActiveKey={["1"]}
            bordered={false}
            // onChange={onChange}
            expandIconPosition="end"
            items={item4}
          />
        </div>
      </div>
    </div>
  );
}

export default ForthSection;
