import React from "react";
import { Link } from "react-router-dom";

let parserLoginUrl = "https://parser-dev.hireplusplus.com/login";
let parserSignupUrl = "https://parser.dev.hireplusplus.com/signup";

if (
  window.location.origin &&
  (window.location.origin.includes("https://hireplusplus") ||
    window.location.origin.includes("https://www.hireplusplus"))
) {
  parserLoginUrl = "https://parser.hireplusplus.com/login";
  parserSignupUrl = "https://parser.hireplusplus.com/signup";
}

function LastSection() {
  return (
    <div className="text-center mt-12 py-12 bg-[#EAF3FE]">
      <p className="font-[600] text-4xl text-[#101828] text-center">
        Start your 30-day <span className="text-[#DC6803]">free trial</span>
      </p>
      <p className="text-[#475467] lg:w-[60%] w-90% m-auto mt-4">
        Convert CVs and Job Data with Incredible Accuracy. Transform CV/resume
        and job data into a structured, searchable format with pinpoint accuracy
        across multiple languages.
      </p>
      <p className="mt-4">
        <Link to={parserLoginUrl} target="_blank">
          <button className="bg-[#206DC5] text-white py-2 px-6 rounded-lg">
            Get started
          </button>
        </Link>
      </p>
    </div>
  );
}

export default LastSection;
