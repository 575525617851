import React, { useEffect, useState } from "react";
import { Spin } from "antd";

const CalendlyWidget = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      setTimeout(() => {
        setLoading(false);
      }, 400);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Spin spinning={loading} size="large" className="mt-12 lg:mt-24">
      <div className="p-5">
        <div
          className="calendly-inline-widget mt-[55px] mb-[10px]"
          data-url="https://calendly.com/discover-hireplusplus/discover-hireplusplus?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=081B31&text_color=F2F8FE"
          style={{ minWidth: "320px", height: "795px" }}
        ></div>
      </div>
    </Spin>
  );
};

export default CalendlyWidget;
