import React from "react";
import "../../styles/landingPage/secondSection.css";
import Card1 from "../../assets/symbol/multipleCandidateAdd.svg"
import Card2 from "../../assets/symbol/thunder.svg"
import Card3 from "../../assets/symbol/parternership.svg"
import RightArrow from "../../assets/symbol/rightArrow.svg"
import { Col, Row } from "antd";

function SecondSection({ currLangJSON }) {
  return (
    <div className=" w-full mt-14" data-aos="fade-up" data-aos-duration="1000" data-aos-once={true} data-aos-easing="ease">
      <div className="publicLanding-secondSec-header">
        {currLangJSON["publicLanding.secondSec.header"]} Hire++ (HirePlusPlus)
      </div>
      <div className="publicLanding-secondSec-subHeader text-base  landingPgGreyTxt w-[80%] m-auto mt-3">
        {currLangJSON["publicLanding.secondSec.subHeader"]}
      </div>
      <div className="publicLanding-secondSec-header mt-14">
        {currLangJSON["publicLanding.secondSec.secHeader"]}
      </div>
      <div className="w-[80%] m-auto">
        <div className="publicLanding-secondSec-secSubHeader text-center mt-1 break-words">
          <span className="yellow-text">
            {currLangJSON["publicLanding.secondSec.secSubHeader.yellowText"]}
          </span>{" "}
          {currLangJSON["publicLanding.secondSec.secSubHeader.subText"]}
        </div>
        <div className="text-center text-[#475467]">
          {currLangJSON["publicLanding.secondSec.secSubHeader.sub.subText"]}
        </div>
      </div>
      <div className=" w-[90%] m-auto flex mt-8 gap-8">
        <Row className="flex gap-2 justify-around" data-aos="zoom-in" data-aos-duration="1000" data-aos-once={true} data-aos-easing="ease">

          <Col sm={7} className="py-4 mainLandingSectionCenterAlign">
            <img src={Card1} className='mainLandingSecondSecImg' alt="" />
            <span>
              <p className="font-bold text-[#101828] mt-4" style={{fontSize:"17px"}}>{currLangJSON["publicLanding.secondSec.card1.header"]}</p>
              <p className="mt-2 text-sm text-[#475467]">{currLangJSON["publicLanding.secondSec.card1.subHeader"]}</p>
            </span>
            <span className="flex gap-2 align-center text-[#206DC5] mt-3">

              {/* <p>{currLangJSON["publicLanding.secondSec.card.learnMore"]} </p> */}
              {/* <img src={RightArrow} alt="" /> */}
            </span>

          </Col>
          <Col sm={7} className="py-4 mainLandingSectionCenterAlign">
            <img src={Card2} className='mainLandingSecondSecImg' alt="" />
            <span>
              <p className="font-bold text-[#101828] mt-4" style={{fontSize:"17px"}}>{currLangJSON["publicLanding.secondSec.card2.header"]}</p>
              <p className="mt-2 text-sm text-[#475467]">{currLangJSON["publicLanding.secondSec.card2.subHeader"]}</p>
            </span>
            <span className="flex gap-2 align-center text-[#206DC5] mt-3">

              {/* <p>{currLangJSON["publicLanding.secondSec.card.learnMore"]} </p> */}
              {/* <img src={RightArrow} alt="" /> */}
            </span>

          </Col>
          <Col sm={7} className="py-4 mainLandingSectionCenterAlign">
            <img src={Card3} className='mainLandingSecondSecImg' alt="" />
            <span>
              <p className="font-bold text-[#101828] mt-4" style={{fontSize:"17px"}}>{currLangJSON["publicLanding.secondSec.card3.header"]}</p>
              <p className="mt-2 text-sm text-[#475467]">{currLangJSON["publicLanding.secondSec.card3.subHeader"]}</p>
            </span>
            <span className="flex gap-2 align-center text-[#206DC5] mt-3">

              {/* <p>{currLangJSON["publicLanding.secondSec.card.learnMore"]} </p> */}
              {/* <img src={RightArrow} alt="" /> */}
            </span>

          </Col>
        </Row>

      </div>
    </div>
  );
}

export default SecondSection;
