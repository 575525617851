import React from 'react'
import AccessLandingPage from '../components/accessPage/AccessLandingPage'

function Access() {
  return (
    <>
      <AccessLandingPage />
    </>
  )
}

export default Access