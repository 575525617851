import { Carousel } from "antd";
import React from "react";
import "../../styles/landingPage/seventhSection.css";

function SeventhSection({ currLangJSON }) {
  const contentStyle = {
    // height: "160px",
    // color: "#fff",
    // lineHeight: "160px",
    textAlign: "center",
    // background: "#EAF3FE",
  };
  return (
    <div className="mt-16 ">
      <div className="publicLanding-secondSec-header">
        {currLangJSON["publicLanding.seventhSec.header"]}
      </div>
      <div className="publicLanding-secondSec-secSubHeader text-center mt-1">
        {currLangJSON["publicLanding.seventhSec.subHeader"]}{" "}
        <span className="yellow-text">
          {currLangJSON["publicLanding.seventhSec.subHeader.yellowText"]}
        </span>
      </div>
      <div className="carousel-container">
        <Carousel autoplay autoplaySpeed={3000}>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 mt-1" style={contentStyle}>
              <div className="rounded-lg min-w-[200px] border-[#D4E7FD] flex items-center  h-[215px] border-2 p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card1.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card1.subHeader"]}
                  </p>
                </div>
              </div>
              <div className="rounded-lg min-w-[200px] border-[#D4E7FD] border-2 flex items-center  h-[215px]   p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card2.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card2.subHeader"]}
                  </p>
                </div>
              </div>
              <div className="rounded-lg min-w-[200px] border-[#D4E7FD] border-2  flex items-center  h-[215px]  p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card3.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card3.subHeader"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="rounded-lg min-w-[200px] flex items-center  h-[215px] border-[#D4E7FD] border-2 p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card4.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card4.subHeader"]}
                  </p>
                </div>
              </div>
              <div className="rounded-lg min-w-[200px]  h-[215px]  flex items-center  border-[#D4E7FD] border-2  p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card5.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card5.subHeader"]}
                  </p>
                </div>
              </div>
              <div className="rounded-lg min-w-[200px]  h-[215px] flex items-center border-[#D4E7FD] border-2 p-4 m-auto hirePPLandingPageBoxShadow">
                <div>
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card6.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card6.subHeader"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="rounded-lg min-w-[200px] items-center flex h-[215px] border-[#D4E7FD] border-2 p-4 m-auto hirePPLandingPageBoxShadow">
                <div className=" m-auto">
                  <p className="font-bold text-xl">
                    {currLangJSON["publicLanding.seventhSec.card7.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card7.subHeader"]}
                  </p>
                </div>
              </div>
              <div className="rounded-lg min-w-[200px]  h-[215px] flex items-center  border-[#D4E7FD] border-2  p-4 m-auto hirePPLandingPageBoxShadow">
                <div className=" m-auto">
                  <p className="font-bold text-xl ">
                    {currLangJSON["publicLanding.seventhSec.card8.header"]}
                  </p>
                  <p className="text-[#475467] text-base mt-4">
                    {currLangJSON["publicLanding.seventhSec.card8.subHeader"]}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
          {/* <div>
            <h3 style={contentStyle}>2</h3>
          </div>
          <div>
            <h3 style={contentStyle}>3</h3>
          </div>
          <div>
            <h3 style={contentStyle}>4</h3>
          </div> */}
        </Carousel>
      </div>
      <div className="carousel-container-mobile-view">
        <Carousel autoplay autoplaySpeed={3000}>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
              <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2 px-3 py-5 m-auto hirePPLandingPageBoxShadow">
                <p className="font-bold text-lg lg:text-xl">
                  {currLangJSON["publicLanding.seventhSec.card1.header"]}
                </p>
                <p className="text-[#475467] text-sm lg:text-base mt-2">
                  {currLangJSON["publicLanding.seventhSec.card1.subHeader"]}
                </p>
              </div>
            </div>
          </div>

          <div className="flex m-auto gap-4 px-4 py-8 text-center" style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card2.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card2.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card3.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card3.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card4.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card4.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card5.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card5.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card6.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card6.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card7.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card7.subHeader"]}
              </p>
            </div>
          </div>
          <div className="flex m-auto gap-4 px-4 py-8 text-center " style={contentStyle}>
            <div className="rounded-lg w-[90%] border-[#D4E7FD] border-2  px-3 py-5 m-auto hirePPLandingPageBoxShadow">
              <p className="font-bold text-lg lg:text-xl">
                {currLangJSON["publicLanding.seventhSec.card8.header"]}
              </p>
              <p className="text-[#475467] text-sm lg:text-base mt-2">
                {currLangJSON["publicLanding.seventhSec.card8.subHeader"]}
              </p>
            </div>
          </div>

          {/* </div> */}
          {/* <div>
            <h3 style={contentStyle}>2</h3>
          </div>
          <div>
            <h3 style={contentStyle}>3</h3>
          </div>
          <div>
            <h3 style={contentStyle}>4</h3>
          </div> */}
        </Carousel>
      </div>
    </div>
  );
}

export default SeventhSection;
