import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Icon from "../assets/icon/Icons";
import footerLogo from "../assets/logo/footer-sponser-logo.svg";
import footerThuLogo from "../assets/logo/footer-thub-logo.svg";
import footerThirdLogo from "../assets/logo/dept.svg";
import instagram from "../assets/socialMedia/instagram.svg";
import facebook from "../assets/socialMedia/facebook.svg";
import twitter from "../assets/socialMedia/twitter.svg";
import linkedin from "../assets/socialMedia/linkedin.svg";
import youtube from "../assets/socialMedia/youtube.svg";
import hpp from "../assets/logo/hpp.png";

const socialMediaIcons = {
  Facebook: facebook,
  Twitter: twitter,
  YouTube: youtube,
  LinkedIn: linkedin,
  Instagram: instagram,
};

function Footer({ currLangJSON = {} }) {
  const footerElm = useRef();

  const {
    solutions = {},
    resources = {},
    careers = {},
    policy = {},
    contact = {},
    socialMedia = [],
    copyright = "",
  } = currLangJSON.footer || {};

  let loginUrl = "https://platform.dev.hireplusplus.com/login";
  if (
    window.location.origin &&
    (window.location.origin.includes("https://hireplusplus") ||
      window.location.origin.includes("https://www.hireplusplus"))
  ) {
    loginUrl = "https://platform.hireplusplus.com/login";
  }

  return (
    <div
      className="footer-section"
      ref={footerElm}
      style={{ position: "relative" }}
    >
      <div
        className="left-section flex flex-col items-center gap-1"
        data-aos="slide-right"
        data-aos-duration="1500"
        data-aos-once={true}
        data-aos-easing="ease"
        data-aos-delay="50"
      >
        {/* <Icon type={"hire-pluss-bw"} width={80} height={44} /> */}
        <img src={hpp} width={160} height={44} alt="hireplusplus" />
        <p className="text-[0.6rem]">Hybrid intelligence meets Hiring.</p>
      </div>
      <div className="right-section">
        <div className="sub-section">
          <div className="sub-section-title">{solutions.title}</div>
          {solutions.options.map((option, index) => (
            <div
              key={index}
              className="sub-section-option"
              data-aos="slide-up"
              data-aos-duration="1500"
              data-aos-once={true}
              data-aos-easing="ease"
              data-aos-delay="50"
            >
              <Link key={index} to={option.link}>
                {option.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="sub-section">
          <div className="sub-section-title">{resources.title}</div>
          {resources.options.map((option, index) => (
            <div
              key={index}
              className="sub-section-option"
              data-aos="slide-up"
              data-aos-duration="1500"
              data-aos-once={true}
              data-aos-easing="ease"
              data-aos-delay="50"
            >
              <Link key={index} to={option.link}>
                {option.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="sub-section">
          <div className="sub-section-title">{careers.title}</div>
          {careers.options.map((option, index) => (
            <div
              key={index}
              className="sub-section-option"
              data-aos="slide-up"
              data-aos-duration="1500"
              data-aos-once={true}
              data-aos-easing="ease"
              data-aos-delay="50"
            >
              <Link key={index} to={option.link}>
                {option.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="sub-section policy-section">
          <div className="sub-section-title">{policy.title}</div>
          {policy.options.map((option, index) => (
            <div
              key={index}
              className="sub-section-option"
              data-aos="slide-up"
              data-aos-duration="1500"
              data-aos-once={true}
              data-aos-easing="ease"
              data-aos-delay="50"
            >
              <Link key={index} to={option.link}>
                {option.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="sub-section">
          <div className="sub-section-title">{contact.title}</div>
          {contact.options.map((option, index) => (
            <div
              key={index}
              className="sub-section-option"
              data-aos="slide-up"
              data-aos-duration="1500"
              data-aos-once={true}
              data-aos-easing="ease"
              data-aos-delay="50"
            >
              <Link key={index} to={option.link}>
                {option.name}
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between w-full items-end footer-logos-copyright pt-5">
        <div className="flex flex-col text-white">
          <div className="text-base">Part of 1st AI Scaleup Cohort</div>
          <div className="footer-sponser-logo flex justify-start gap-3 ">
            <img src={footerLogo} alt="" />
            <img src={footerThuLogo} alt="" />
            <img src={footerThirdLogo} alt="" className="" />
          </div>
        </div>
        <div className="text-white copyright text-[0.8rem] mt-1">
          <span className="flex gap-2 justify-end social-logo">
            {socialMedia.map((link, index) => (
              <a key={index} href={link.url} rel="noopener noreferrer">
                <img src={socialMediaIcons[link.name]} alt={link.name} />
              </a>
            ))}
          </span>
          <span className="flex justify-end mt-1">{copyright}</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
