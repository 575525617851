import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function TermsTwelfthSection({ currLangJSON }) {
  return (
    <div className="w-[100%] justify-self-center termsParaMainContainer">
      <div className="w-[100%] m-auto">
        <Row>
          <Col xs={20} md={20} lg={20} className="terms-para">
            <h2 className="terms-section-heading">
              {currLangJSON["employerTerms.twelveSec.heading"]}
            </h2>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.twelveSec.subHeading"]}
            </h3>
            <p>{currLangJSON["employerTerms.twelveSec.para1"]}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermsTwelfthSection;
