import React, { useState } from "react";
import "../../styles/jobsPage/sixthSection.css";
import ReCAPTCHA from "react-google-recaptcha";
import ResumeUploadModal from "./ResumeUploadModal";

function JobsSixthSection({ currLangJSON }) {
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onCaptchaChange(value) {
    setReChapthaDisable(false);
    document.querySelector("#hirePPRecaptcha iframe").style.border = "none";
  }
  //onExpiredChange
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    // sessionStorage.setItem("recaptchaFlag", false);
    setReChapthaDisable(true);
  }
  return (
    <>
      {isModalVisible && (
        <ResumeUploadModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
        />
      )}
      <div className="sixth-sec p-12 mt-[80px] mb-10 mx-auto">
        <div className="item-center w-[70%] m-auto">
          <p className="text-[2em]  text-center font-semibold text-[#101828]">
            {currLangJSON["jobsLanding.sixthSec.heading"]}
          </p>
          <p className="text-[0.9em] mt-4 text-center text-[#475467] ">
            {currLangJSON["jobsLanding.sixthSec.subHeading"]}
          </p>
          <p className=" mt-6 text-center">
            <button
              onClick={() => setIsModalVisible(true)}
              // className={` m-auto ${
              //   reChapthaDisable ? "sixth-sec-btn-disable " : "sixth-sec-btn"
              // }`}
              className="sixth-sec-btn"
            >
              {currLangJSON["jobsLanding.sixthSec.button"]}
            </button>
          </p>
        </div>
      </div>
    </>
  );
}

export default JobsSixthSection;
