import Lottie from "lottie-react";
import React from "react";
import underConstAnimation from "../../assets/lotties/UnderConstruction.json";

function UnderConstructionLandingPage({currLangJSON}) {
  return (
    <div className="w-[80%] pt-[140px] m-auto mb-24">
      <Lottie
        className="m-auto w-[80%] h-[400px]"
        animationData={underConstAnimation}
        loop={true}
        autoplay={true}
      />
      <h1 className="text-center font-semibold text-lg text-[#101828]">{currLangJSON["underConstruction.heading"]}</h1>
      <p className="text-center text-base mt-1 text-[#667085]">{currLangJSON["underConstruction.description"]}</p>
    </div>
  );
}

export default UnderConstructionLandingPage;
