import React, { useEffect, useState } from 'react'
import Topbar from '../components/TopBar'
import NotificationSection from '../components/NotificationSection'
import Footer from '../components/Footer'
import English from "../utils/language/English.json";
import German from "../utils/language/German.json";
import French from "../utils/language/French.json";
import ErrorLandingPage from '../components/others/404LandingPage';

function NotFoundPage () {
    const [globalLang, setGlobalLang] = useState(English);
  const langChange = (storedLang) => {
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
  };
  useEffect(() => {
    var topbardText = document.querySelectorAll('.topbard-text');
    for (let i = 0; i < topbardText.length; i++) {
      topbardText[i].style.color = '#206DC5';

    }
    var hamburger = document.querySelector('.fas.fa-bars');
    hamburger.style.color = '#206DC5';

    const handleScroll = () => {
      var topBar = document.querySelector('.topbar-logo');
      var hamburger = document.querySelector('.fas.fa-bars');
      var backCover = document.querySelector('.back-cover');
      var weServe = document.querySelector('.weServe-text');
      var topbardText = document.querySelectorAll('.topbard-text');
      var navMenuA = document.querySelector('.toggle-options .nav-menu');
      var topBarNav = document.querySelector('.topbar .right-section .nav-menu ');
      if (window.scrollY > 0) {
        topBar.style.background = 'white';
        backCover.style.background = 'white';
        backCover.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        topBar.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        hamburger.style.color = '#0638c3';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = '#206DC5';

        }
        weServe.style.color = '#0638c3';
        topBar.style.transition = 'ease-in-out 3s ';
        topBarNav.style.transition = 'ease-in-out 3s ';
      } else {
        hamburger.style.color = '#206DC5';
        weServe.style.color = 'white';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = '#206DC5';

        }
        topBar.style.background = 'none';
        backCover.style.background = 'none';
        backCover.style.boxShadow = "none"
        topBar.style.boxShadow = "none"
        topBar.style.transition = 'background 0.3s ease';
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="public-landing-page">
      <Topbar page="" topbarCss="css" globalLang={langChange} />
      <ErrorLandingPage currLangJSON={globalLang} />
      <NotificationSection currLangJSON={globalLang} />
      <Footer currLangJSON={globalLang} />
    </div>
  )
}

export default NotFoundPage