import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function TermsEighthSection({ currLangJSON }) {
  return (
    <div className="w-[100%] justify-self-center termsParaMainContainer">
      <div className="w-[100%] m-auto">
        <Row>
          <Col xs={20} md={20} lg={20} className="terms-para">
            <h2 className="terms-section-heading">
              {currLangJSON["employerTerms.eighthSec.heading"]}
            </h2>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.eighthSec.subHeading1"]}
            </h3>
            <p>{currLangJSON["employerTerms.eighthSec.para1"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.eighthSec.subHeading2"]}
            </h3>
            <p>{currLangJSON["employerTerms.eighthSec.para2"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.eighthSec.subHeading3"]}
            </h3>
            <p>{currLangJSON["employerTerms.eighthSec.para3"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.eighthSec.subHeading4"]}
            </h3>
            <p>{currLangJSON["employerTerms.eighthSec.para4"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.eighthSec.subHeading5"]}
            </h3>
            <p>{currLangJSON["employerTerms.eighthSec.para5"]}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermsEighthSection;
