import React from 'react';
import '../../styles/aiParser/aiMain.css';
import img1 from '../../assets/image/aiParser1.png';
import img2 from '../../assets/image/aiParser2.png';
import img3 from '../../assets/image/aiParser3.png';
import img4 from '../../assets/image/aiParser4.png';

const UseCasesSection = ({ currLangJSON }) => {
    return (
        <div className="use-cases-section">
            <p className="use-cases-sub-heading mb-2">{currLangJSON.aiParser.thirdSection.subHeading}</p>
            <div className="font-[600] text-4xl text-center mb-2" >{currLangJSON.aiParser.thirdSection.mainHeading}</div>
            <div className="use-cases-description text-[#667085]">
                {currLangJSON.aiParser.thirdSection.description}
            </div>
            <div className="use-cases-grid">
                <div className="use-case-card hirePPLandingPageBoxShadow">
                    <img src={img1} alt={currLangJSON.aiParser.thirdSection.imgAlt1} />
                    <div className="text-overlay">
                        <h3 className="parserImgHeading">{currLangJSON.aiParser.thirdSection.imgTitle1}</h3>
                        <p className='parserImgTxt'>{currLangJSON.aiParser.thirdSection.imgText1}</p>
                    </div>
                </div>
                <div className="use-case-card hirePPLandingPageBoxShadow">
                    <img src={img2} alt={currLangJSON.aiParser.thirdSection.imgAlt2} />
                    <div className="text-overlay">
                        <h3 className="parserImgHeading">{currLangJSON.aiParser.thirdSection.imgTitle2}</h3>
                        <p className='parserImgTxt'>{currLangJSON.aiParser.thirdSection.imgText2}</p>
                    </div>
                </div>
                <div className="use-case-card hirePPLandingPageBoxShadow">
                    <img src={img3} alt={currLangJSON.aiParser.thirdSection.imgAlt3} />
                    <div className="text-overlay">
                        <h3 className="parserImgHeading">{currLangJSON.aiParser.thirdSection.imgTitle3}</h3>
                        <p className='parserImgTxt'>{currLangJSON.aiParser.thirdSection.imgText3}</p>
                    </div>
                </div>
                <div className="use-case-card hirePPLandingPageBoxShadow">
                    <img src={img4} alt={currLangJSON.aiParser.thirdSection.imgAlt4} />
                    <div className="text-overlay">
                        <h3 className="parserImgHeading">{currLangJSON.aiParser.thirdSection.imgTitle4}</h3>
                        <p className='parserImgTxt'>{currLangJSON.aiParser.thirdSection.imgText4}</p>
                    </div>
                </div>
            </div>
            {/* <div className="m-auto border"> */}
                <p className='use-cases-sub-heading mt-14 mb-2'>{currLangJSON.aiParser.thirdSection.introText1}</p>
                <p className='text-4xl text-center font-[600] mb-2'>{currLangJSON.aiParser.thirdSection.introText2}</p>
                <p className='text-[#667085] text-base w-[80%] text-center  m-auto'>{currLangJSON.aiParser.thirdSection.introText3}</p>
            {/* </div> */}
        </div>
    );
};

export default UseCasesSection;
