import React, { useState, useEffect } from "react";
import "../../styles/landingPage/horizontalSection.css";
import Icon from "../../assets/icon/Icons";

const HorizontalSection = ({ slideChangerFunc, currLangJSON, currTile }) => {
  const buttons = [
    {
      type: "feature-section-ai",
      alt: "Button 1",
      onClick: (e) => {
        slideChangerFunc(e, 1);
        // setActiveButton(1);
      },
      header: currLangJSON["publicLanding.thirdSec.btn1.Header"],
      btnNum: "1",
    },
    {
      type: "feature-section-aiSearch",
      alt: "Button 2",
      onClick: (e) => {
        slideChangerFunc(e, 2);
        // setActiveButton(2);
      },
      header: currLangJSON["publicLanding.thirdSec.btn2.Header"],
      btnNum: "2",
    },
    {
      type: "feature-section-candidate",
      alt: "Button 3",
      onClick: (e) => {
        slideChangerFunc(e, 3);
        // setActiveButton(3);
      },
      header: currLangJSON["publicLanding.thirdSec.btn3.Header"],
      btnNum: "3",
    },
    {
      type: "feature-section-inDepth",
      alt: "Button 4",
      onClick: (e) => {
        slideChangerFunc(e, 4);
        // setActiveButton(4);
      },
      header: currLangJSON["publicLanding.thirdSec.btn4.Header"],
      btnNum: "4",
    },
    {
      type: "feature-section-report",
      alt: "Button 5",
      onClick: (e) => {
        slideChangerFunc(e, 5);
        // setActiveButton(5);
      },
      header: currLangJSON["publicLanding.thirdSec.btn5.Header"],
      btnNum: "5",
    },
  ];

  return (
    <div className="horizontal-section">
      {buttons.map((btn, index) => (
        <div
          key={index}
          className={`btn3ImgContainer mbThirdSecBtn${btn.btnNum} ${
            currTile === parseInt(btn.btnNum) ? "currActive" : ""
          }`}
        >
          <div
            className="icon-container border py-3 rounded-[12px] bg-[#D4E7FD] flex justify-center"
            onClick={btn.onClick}
          >
            <Icon type={btn.type} />
          </div>
          <p onClick={btn.onClick} className="btnMobileText">
            {btn.header}
          </p>
        </div>
      ))}
    </div>
  );
};

export default HorizontalSection;
