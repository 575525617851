import React from 'react';
import "../../styles/jobsPage/thirdSection.css";
import { Row, Col } from "antd";
import icon1 from '../../assets/jobsPage/icon1.png';
import icon2 from '../../assets/jobsPage/icon2.png';
import icon3 from '../../assets/jobsPage/icon3.png';
import icon4 from '../../assets/jobsPage/icon4.png';

function JobsFourthSection({ currLangJSON }) {
    return (
        <div className="w-[80%] mt-10 justify-self-center fourthComponentMainContainer">
            <div className="w-[100%] m-auto">
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} md={8} className="text-center">
                        <img src={icon1} alt="Innovative Culture" className="fourth_icon" />
                        <h3 className="heading">{currLangJSON["jobsPage.fourthSec.innovativeCulture.heading"]}</h3>
                        <p className="description">{currLangJSON["jobsPage.fourthSec.innovativeCulture.description"]}</p>
                    </Col>
                    <Col xs={24} md={8} className="text-center">
                        <img src={icon2} alt="Growth Opportunities" className="fourth_icon" />
                        <h3 className="heading">{currLangJSON["jobsPage.fourthSec.growthOpportunities.heading"]}</h3>
                        <p className="description">{currLangJSON["jobsPage.fourthSec.growthOpportunities.description"]}</p>
                    </Col>
                </Row>
                <div style={{marginTop:"40px"}}></div>
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} md={8} className="text-center">
                        <img src={icon3} alt="Diverse Perspectives" className="fourth_icon" />
                        <h3 className="heading">{currLangJSON["jobsPage.fourthSec.diversePerspectives.heading"]}</h3>
                        <p className="description">{currLangJSON["jobsPage.fourthSec.diversePerspectives.description"]}</p>
                    </Col>
                    <Col xs={24} md={8} className="text-center">
                        <img src={icon4} alt="Meaningful Impact" className="fourth_icon" />
                        <h3 className="heading">{currLangJSON["jobsPage.fourthSec.meaningfulImpact.heading"]}</h3>
                        <p className="description">{currLangJSON["jobsPage.fourthSec.meaningfulImpact.description"]}</p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default JobsFourthSection;
