import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacyTenthSection = ({ currLangJSON }) => {
  const updateColumns = [
    {
      title: currLangJSON["privacyPage.tenthSec.subSection1.table.columns.trigger"],
      dataIndex: 'trigger',
      key: 'trigger',
    },
    {
      title: currLangJSON["privacyPage.tenthSec.subSection1.table.columns.notification"],
      dataIndex: 'notification',
      key: 'notification',
    },
    {
      title: currLangJSON["privacyPage.tenthSec.subSection1.table.columns.effectiveDate"],
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
    },
  ];

  const updateData = currLangJSON["privacyPage.tenthSec.subSection1.table.rows"].map((row, index) => ({
    key: index,
    trigger: row.trigger,
    notification: row.notification,
    effectiveDate: row.effectiveDate,
  }));

  const contactColumns = [
    {
      title: currLangJSON["privacyPage.tenthSec.subSection2.table.columns.method"],
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: currLangJSON["privacyPage.tenthSec.subSection2.table.columns.details"],
      dataIndex: 'details',
      key: 'details',
    },
  ];

  const contactData = currLangJSON["privacyPage.tenthSec.subSection2.table.rows"].map((row, index) => ({
    key: index,
    method: row.method,
    details: row.details,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.tenthSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.tenthSec.description"]}</p>
      
      <h3 className="text-xl font-semibold mb-4">{currLangJSON["privacyPage.tenthSec.subSection1.heading"]}</h3>
      <Table columns={updateColumns} dataSource={updateData} pagination={false} className="mb-6" />
      <h4 className="text-lg font-semibold mb-2">{currLangJSON["privacyPage.tenthSec.subSection1.detailsHeading"]}</h4>
      <ul className="list-disc pl-6 mb-6">
        {currLangJSON["privacyPage.tenthSec.subSection1.details"].map((detail, index) => (
          <li key={index} className="mb-2">
            <strong>{detail.title}: </strong>
            {detail.description}
          </li>
        ))}
      </ul>
      
      <h3 className="text-xl font-semibold mb-4">{currLangJSON["privacyPage.tenthSec.subSection2.heading"]}</h3>
      <p className="mb-6">{currLangJSON["privacyPage.tenthSec.subSection2.description"]}</p>
      <Table columns={contactColumns} dataSource={contactData} pagination={false} className="mb-6" />
      <h4 className="text-lg font-semibold mb-2">{currLangJSON["privacyPage.tenthSec.subSection2.additionalInfoHeading"]}</h4>
      <ul className="list-disc pl-6">
        {currLangJSON["privacyPage.tenthSec.subSection2.additionalInfo"].map((info, index) => (
          <li key={index} className="mb-2">
            <strong>{info.title}: </strong>
            {info.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacyTenthSection;
