import React from "react";
import { Col, Row } from "antd";
import "../../styles/cookiePolicy.css";

function CookiePolicyFourthSection({ currLangJSON }) {
    const details = currLangJSON["cookiePolicyPage"].fourthSec.details;
    const contact = currLangJSON["cookiePolicyPage"].fourthSec.contact;

    return (
        <div className="mt-10 w-full flex justify-center">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="cookie-data-para px-4 md:px-8 lg:px-0">
                    {details.map((section, index) => (
                        <div key={index} className="cookie-data-section">
                            <div className="cookie-data-sub-section-heading">
                                {section.heading}
                            </div>
                            <p className="cookie-data-sub-section-description">
                                {section.description}
                            </p>
                            {section.links && (
                                <ul className="cookie-data-definition-list">
                                    {section.links.map((link, subIndex) => (
                                        <li key={subIndex} className="cookie-data-definition-item">
                                            {link.text} <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                    <div className="cookie-data-section">
                        <div className="cookie-data-sub-section-heading">
                            {contact.heading}
                        </div>
                        <p className="cookie-data-sub-section-description">
                            {contact.description}
                        </p>
                        <ul className="cookie-data-definition-list">
                            {contact.details.map((detail, index) => (
                                <li key={index} className="cookie-data-definition-item">
                                    <strong>{detail.type}:</strong> <a href={detail.url} target="_blank" rel="noopener noreferrer">{detail.text}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default CookiePolicyFourthSection;
