import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacyThirdSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.thirdSec.table.columns.method"],
      dataIndex: 'method',
      key: 'method',
    },
    {
      title: currLangJSON["privacyPage.thirdSec.table.columns.description"],
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const data = currLangJSON["privacyPage.thirdSec.table.rows"].map((row, index) => ({
    key: index,
    method: row.method,
    description: row.description,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.thirdSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.thirdSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.thirdSec.examplesHeading"]}</h3>
      <ul className="list-disc ml-5">
        {currLangJSON["privacyPage.thirdSec.examples"].map((example, index) => (
          <li key={index} className="mb-2">
            <strong>{example.title}: </strong>
            {example.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacyThirdSection;
