import React, { useEffect } from 'react';
import { Table } from 'antd';
import '../../styles/pricing.css';
import checkIcon from '../../assets/image/checkIcon.png';
import popularBadge from '../../assets/image/popularBadge.png';

const PricingComparison = ({ currLangJSON }) => {
  useEffect(() => {
    const premiumColumnCells = document.querySelectorAll('.data-table-1 .premium-cell');
    if (premiumColumnCells.length > 0) {
      premiumColumnCells[0].classList.add('premium-header-cell');
      premiumColumnCells[premiumColumnCells.length - 1].classList.add('premium-cell-bottom');
    }
    const lastRow = document.querySelector('.data-table-1 .ant-table-row:last-child');
    if (lastRow) {
      lastRow.addEventListener('mouseenter', addBottomBorderTable1);
      lastRow.addEventListener('mouseleave', addBottomBorderTable1);
    }
  
    return () => {
      if (lastRow) {
        lastRow.removeEventListener('mouseenter', addBottomBorderTable1);
        lastRow.removeEventListener('mouseleave', addBottomBorderTable1);
      }
    };
  }, []);
  
  const addBottomBorderTable1 = () => {
    const lastRowCell = document.querySelector('.data-table-1 .ant-table-row:last-child .ant-table-cell:nth-child(4)');
    if (lastRowCell) {
      lastRowCell.style.borderBottom = '1px solid black';
      lastRowCell.style.borderRadius = '0 0 10px 10px';
    }
  };
  
  
  const addBottomBorder = () => {
    const lastRowCell = document.querySelector('.pricing-table .ant-table-row:last-child .ant-table-cell:nth-child(4)');
    if (lastRowCell) {
      lastRowCell.style.borderBottom = '1px solid black';
      lastRowCell.style.borderRadius = '0 0 10px 10px';
    }
  };
  

  const columns = [
    {
      title: 'Overview',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    ...currLangJSON.pricingPageComparison.plans.map((plan, index) => ({
      title: (
        <div className={index === 2 ? 'premium-cell' : ''}>
          {plan}
          {index === 2 && (
            <span className="popular-badge">
              <img src={popularBadge} alt="Popular" />
            </span>
          )}
        </div>
      ),
      dataIndex: plan,
      key: plan,
      align: 'center',
      className: index === 2 ? 'premium-cell' : '',
    })),
  ];

  const data = currLangJSON.pricingPageComparison.features.map((feature, index) => {
    const formattedFeature = {};
    for (const key in feature) {
      if (feature[key] === 'checkIcon') {
        formattedFeature[key] = (
          <div className="check-icon">
            <img src={checkIcon} alt="Check" />
          </div>
        );
      } else {
        formattedFeature[key] = feature[key];
      }
    }
    return {
      key: index,
      ...formattedFeature,
    };
  });

  const planDetailsRow = {
    key: 'details',
    name: 'Plan Details',
    ...currLangJSON.pricingPageComparison.details.reduce((acc, detail) => {
      acc[detail.plan] = (
        <div className="plan-detail-cell">
          <strong>{detail.price}</strong>
          <p>{detail.description}</p>
        </div>
      );
      return acc;
    }, {}),
  };

  data.unshift(planDetailsRow);

  return (
    <div className="pricing-section py-16 px-4 lg:px-20">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-orangish">{currLangJSON.pricingPageComparison.title}</h1>
        <p className="text-lg text-gray-600 mt-4">{currLangJSON.pricingPageComparison.description}</p>
      </div>
      <div className="mt-10 overflow-x-auto">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          className="pricing-table min-w-[600px] data-table-1"
        />
      </div>
    </div>
  );
};

export default PricingComparison;
