import React, { useRef, useEffect, useState } from 'react';
import "../../styles/landingPage/heroSection.css";

const VideoPlayer = ({ src, type, poster, width = "100%", height = "100%" }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.25, // Trigger when 25% of the video is in view
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div ref={containerRef} className="video-container" style={{ width }}>
      {isVisible && (
        <>
          {!isPlaying && (
            <div className="video-poster" onClick={handlePlay} style={{ backgroundImage: `url(${poster})`, width }}>
              <div className="play-button"></div>
            </div>
          )}
          <video
            ref={videoRef}
            width={width}
            height={height}
            controls
            poster={poster}
            style={{ borderRadius: '10px', display: isPlaying ? 'block' : 'none' }}
            className="fade-in"
            key={src}  // Add key to force re-render when src changes
          >
            <source src={src} type={type} />
            Your browser does not support the video tag.
          </video>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
