import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacySeventhSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.seventhSec.table.columns.type"],
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: currLangJSON["privacyPage.seventhSec.table.columns.description"],
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const data = currLangJSON["privacyPage.seventhSec.table.rows"].map((row, index) => ({
    key: index,
    type: row.type,
    description: row.description,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.seventhSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.seventhSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.seventhSec.additionalInfoHeading"]}</h3>
      <ul className="list-disc ml-8">
        {currLangJSON["privacyPage.seventhSec.additionalInfo"].map((item, index) => (
          <li key={index} className="mb-2">
            <strong>{item.title}:</strong> {item.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacySeventhSection;
