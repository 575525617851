import React from "react";
import { Col, Row } from "antd";
import "../../styles/dataProtection.css";

function CookiePolicyFirstSection({ currLangJSON }) {
    return (
        <>
            <Row className="data-hero-section" justify="center">
                <Col xs={24} md={20} lg={16} className="data-content">
                    <div className="data-banner">
                        <span>{currLangJSON["cookiePolicyPage"].firstSec.currentDate}</span>
                    </div>
                    <div className="data-main-heading">
                        {currLangJSON["cookiePolicyPage"].firstSec.heading}
                    </div>
                    <div className="data-sub-heading">
                        {currLangJSON["cookiePolicyPage"].firstSec.subHeading}
                    </div>
                </Col>
            </Row>

            <div className="w-[100%] mt-10 justify-self-center termsParaMainContainer">
                <Row justify="center" className="w-full">
                    <Col lg={16} className="data-para px-4 md:px-8 lg:px-0">
                        <div className="data-intro-paragraph">
                            <p className="bold-text">
                                {currLangJSON["cookiePolicyPage"].firstSec.introParagraph1}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default CookiePolicyFirstSection;
