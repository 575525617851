import React, { useEffect, useState } from 'react'
import Topbar from '../components/TopBar'
import Footer from '../components/Footer'
import English from "../utils/language/English.json"
import German from "../utils/language/German.json"
import French from "../utils/language/French.json"
import "../styles/publicLandingPage.css";
import TermsFirstSection from '../components/employerTermsAndCondition/EmpTermsFirstComponent'
import PrivacySecondSection from '../components/employerTermsAndCondition/EmpTermsSecondComponent'
import PrivacyPageSectionThree from '../components/employerTermsAndCondition/EmpTermsThirdComponent'
import TermsFourthSection from '../components/employerTermsAndCondition/EmployerFourthComponent'
import TermsFifthSection from '../components/employerTermsAndCondition/EmployerFifthComponent'
import TermsSixthSection from '../components/employerTermsAndCondition/EmployerSixthComponent'
import TermsSeventhSection from '../components/employerTermsAndCondition/EmployerSeventhComponent'
import TermsEightSection from '../components/employerTermsAndCondition/EmployerEightComponent'
import TermsNinthSection from '../components/employerTermsAndCondition/EmployerNineComponent'
import TermsTenthSection from '../components/employerTermsAndCondition/EmployerTenthComponent'
import TermsElevenSection from '../components/employerTermsAndCondition/EmployerEleventhComponent'
import TermsTwelveSection from '../components/employerTermsAndCondition/EmployerTwelveComponent'
import TermsThirteenSection from '../components/employerTermsAndCondition/EmployerThirteenComponent'
import TermsFourteenSection from '../components/employerTermsAndCondition/EmployerfourteenComponent'


function Jobs() {
  const [globalLang, setGlobalLang] = useState(English)

  useEffect(() => {
    const handleScroll = () => {
      // var topBar = document.querySelector('.topbar .back-cover ');
      var topBar = document.querySelector('.topbar-logo');
      var hamburger = document.querySelector('.fas.fa-bars');
      var backCover = document.querySelector('.back-cover');
      var weServe = document.querySelector('.weServe-text');
      var topbardText = document.querySelectorAll('.topbard-text');
     
      var topBarNav = document.querySelector('.topbar .right-section .nav-menu ');
      var aiIcon = document.querySelector(".ai-topbar-icon path")
      // const topBar = document.querySelector(".topbar");
      if (window.scrollY > 0) {
        // topBar.classList.add("scrolled");
        // console.log(window.scrollY)

        topBar.style.background = 'white';
        backCover.style.background = 'white';
        backCover.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        topBar.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        // backCover.style.opacity = '0.7';
        hamburger.style.color = '#0638c3';
        // navMenuA.style.color = 'blue';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = '#206DC5';

        }
        // console.log(navMenuA)

        weServe.style.color = '#0638c3';
        topBar.style.transition = 'ease-in-out 3s ';
        topBarNav.style.transition = 'ease-in-out 3s ';
        if (aiIcon) {
          // aiIcon.style.fill = 'blue';
          aiIcon.style.stroke = 'rgb(32, 109, 197)';;
          aiIcon.style.transition = 'all ease-in-out 0.2s';
        }
      } else {
        if (aiIcon) {
          // aiIcon.style.fill = 'blue';
          aiIcon.style.stroke = 'white';
          aiIcon.style.transition = 'all ease-in-out 0.2s';
        }
        hamburger.style.color = 'white';
        weServe.style.color = 'white';
        // topbardText.style.color = 'white';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = 'white';

        }
        // navMenuA.style.color = 'white';
        topBar.style.background = 'none';
        backCover.style.background = 'none';
        backCover.style.boxShadow = "none"
        topBar.style.boxShadow = "none"
        // backCover.style.background = 'linear-gradient(to right, black, #1969c4)';
        topBar.style.transition = 'background 0.3s ease';

      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const langChange = (storedLang) => {
    // setGlobalLang(value)
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
    // console.log(value)
  }
  return (
    <div className="public-landing-page">
      <Topbar page="" topbarCss="css" globalLang={langChange} />
      <TermsFirstSection currLangJSON={globalLang} />
      <PrivacySecondSection currLangJSON={globalLang} />
      <PrivacyPageSectionThree currLangJSON={globalLang} />
      <TermsFourthSection currLangJSON={globalLang} />
      <TermsFifthSection currLangJSON={globalLang} />
      <TermsSixthSection currLangJSON={globalLang} />
      <TermsSeventhSection currLangJSON={globalLang} />
      <TermsEightSection currLangJSON={globalLang} />
      <TermsNinthSection currLangJSON={globalLang} />
      <TermsTenthSection currLangJSON={globalLang} />
      <TermsElevenSection currLangJSON={globalLang} />
      <TermsTwelveSection currLangJSON={globalLang} />
      <TermsThirteenSection currLangJSON={globalLang} />
      <TermsFourteenSection currLangJSON={globalLang} />
      <Footer currLangJSON={globalLang} />
    </div>
  )
}

export default Jobs