import React from "react";
import { Col, Row } from "antd";
import "../../styles/dataProtection.css";

function GDPREighthSection({ currLangJSON }) {
  const details1 = currLangJSON["dataProtectionPage"].eighthSec.details1;
  const details2 = currLangJSON["dataProtectionPage"].eighthSec.details2;

  return (
    <div className="mt-10 w-full flex justify-center">
      <Row justify="center" className="w-full">
        <Col xs={24} md={20} lg={16} className="data-para px-4 md:px-8 lg:px-0">
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].eighthSec.heading1}
          </div>
          <ul className="data-definition-list">
            {details1.map((item, index) => (
              <li key={index} className="data-definition-item flex">
                <span className="data-term-number mr-2">{item.subheadingNumber}</span>
                <div>
                  <strong className="data-term">{item.subheading}</strong>
                  <p className="data-definition">{item.description}</p>
                  {item.bullets && (
                    <ul className="data-bullets">
                      {item.bullets.map((bullet, idx) => (
                        <li key={idx} className="data-bullet-item">
                          {bullet}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <div className="data-section-heading mt-10">
            {currLangJSON["dataProtectionPage"].eighthSec.heading2}
          </div>
          <ul className="data-definition-list">
            {details2.map((item, index) => (
              <li key={index} className="data-definition-item flex">
                <span className="data-term-number mr-2">{item.subheadingNumber}</span>
                <div>
                  <strong className="data-term">{item.subheading}</strong>
                  <p className="data-definition">{item.description}</p>
                  {item.bullets && (
                    <ul className="data-bullets">
                      {item.bullets.map((bullet, idx) => (
                        <li key={idx} className="data-bullet-item">
                          {bullet}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default GDPREighthSection;
