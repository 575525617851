import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function TermsSecondSection({ currLangJSON }) {
  return (
    <div className="w-[100%] justify-self-center termsParaMainContainer">
      <div className="w-[100%] m-auto">
        <Row>
          <Col xs={20} md={20} lg={20} className="terms-para">
            <h2 className="terms-section-heading">
              {currLangJSON["employerTerms.secondSec.heading"]}
            </h2>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.secondSec.subHeading1"]}
            </h3>
            <p>{currLangJSON["employerTerms.secondSec.para1"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.secondSec.subHeading2"]}
            </h3>
            <p>{currLangJSON["employerTerms.secondSec.para2"]}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermsSecondSection;
