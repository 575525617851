import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function TermsTwelfthSection({ currLangJSON }) {
  return (
    <div className="w-[100%] justify-self-center termsParaMainContainer">
      <div className="w-[100%] m-auto">
        <Row>
          <Col xs={20} md={20} lg={20} className="terms-para">
            <h2 className="terms-section-heading">
              {currLangJSON["employerTerms.thirteenSec.heading"]}
            </h2>
            <p>{currLangJSON["employerTerms.thirteenSec.para1"]}</p>
            <ul>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem1"]}</li>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem2"]}</li>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem3"]}</li>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem4"]}</li>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem5"]}</li>
              <li>{currLangJSON["employerTerms.thirteenSec.listItem6"]}</li>
            </ul>
            <p>{currLangJSON["employerTerms.thirteenSec.para2"]}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermsTwelfthSection;
