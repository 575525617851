import "../styles/publicLandingPage.css";
import { useState, useEffect, useRef } from "react";
// import axios from "axios";
import { Modal, message, notification } from "antd";
import English from "../utils/language/English.json";
import French from "../utils/language/French.json";
import German from "../utils/language/German.json";
import { subscribeApi } from "../Api";
import ReCAPTCHA from "react-google-recaptcha";

function getOSVersion() {
  const userAgent = navigator.userAgent;
  let osVersion = "";
  if (userAgent.indexOf("Windows") !== -1) {
    // Windows
    const match = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (match) {
      osVersion = match[1];
    }
  } else if (userAgent.indexOf("Mac OS X") !== -1) {
    // macOS
    const match = userAgent.match(/Mac OS X (\d+[_\.]\d+(?:[_\.]\d+)?)/);
    if (match) {
      osVersion = match[1].replace(/_/g, ".");
    }
  } else if (userAgent.indexOf("Linux") !== -1) {
    // Linux
    const match = userAgent.match(/Linux/);
    if (match) {
      osVersion = "Linux";
    }
  } else if (userAgent.indexOf("Android") !== -1) {
    // Android
    const match = userAgent.match(/Android (\d+(\.\d+)?)/);
    if (match) {
      osVersion = match[1];
    }
  } else if (userAgent.indexOf("iOS") !== -1) {
    // iOS
    const match = userAgent.match(/OS (\d+(_\d+)?)/);
    if (match) {
      osVersion = match[1].replace(/_/g, ".");
    }
  }

  return osVersion;
}
function detectOS() {
  var userAgent = navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  } else if (/Windows/i.test(userAgent)) {
    return "Windows";
  } else if (/Linux/i.test(userAgent)) {
    return "Linux";
  } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
    return "Mac";
  } else {
    return "Unknown";
  }
}
let osVer = getOSVersion();
let os = detectOS();

function NotificationSection({ currLangJSON }) {
  // const { lang } = props;
  const [browser, setBrowser] = useState("");
  const [browserVer, setBrowserVer] = useState("");
  const [ip, setIp] = useState();
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const reCaptchaRef = useRef();

  useEffect(() => {}, [reChapthaDisable]);

  const [email, setEmail] = useState("");
  // const [content, setContent] = useState(English.notificationSection);

  const subscribeHandler = async (e) => {
    e.preventDefault();
    let requestBody = {};
    const encodedEmail = btoa(email);
    requestBody["emailId"] = encodedEmail;
    requestBody["osType"] = os;
    requestBody["osVersion"] = osVer;
    requestBody["browserType"] = browser;
    requestBody["browserVersion"] = browserVer;
    requestBody["ipAddress"] = ip;

    // const token = await reCaptchaRef.current.executeAsync();
    // console.log(token);

    const options = {
      method: "POST",
      headers: {
        reCaptcha: "string", // Add your reCaptcha token here
      },
      body: requestBody,
    };

    try {
      const response = await fetch(subscribeApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.resultStatusInfo.resultCode === "Success") {
        setEmail("");
        // console.log("Success:", data);
        notification.success({
          message: "You have successfully subscribed!",
        });
        setReChapthaDisable(true);
      } else {
        console.error("Falied to subscribe!");
        notification.error({
          message: "Failed to subscribe!",
        });
      }
      reCaptchaRef.current.reset();
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Fail to subscribe!",
      });
      reCaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
    browserCheck();
  }, []);

  function browserCheck() {
    let userAgent = navigator.userAgent;
    // let browser = "Unknown";

    // Detect Chrome
    if (/Edg/.test(userAgent)) {
      // browser = "Microsoft Edge";
      setBrowser("Microsoft Edge");
      setBrowserVer(userAgent.match(/Edg\/(\d+)/)[1]);
    } else if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
      // browser = "Google Chrome";
      setBrowser("Google Chrome");
      setBrowserVer(userAgent.match(/Chrome\/(\d+)/)[1]);
    }
    // Detect Chromium-based Edge

    // Detect Firefox
    else if (/Firefox/.test(userAgent)) {
      // browser = "Mozilla Firefox";
      setBrowser("Mozilla Firefox");
      setBrowserVer(userAgent.match(/Firefox\/(\d+)/)[1]);
    }
    // Detect Safari
    else if (/Safari/.test(userAgent)) {
      // browser = "Apple Safari";
      setBrowser("Apple Safari");
      setBrowserVer(userAgent.match(/Version\/(\d+)/)[1]);
    }
    // Detect Internet Explorer
    else if (/Trident/.test(userAgent)) {
      // browser = "Internet Explorer";
      setBrowser("Internet Explorer");
      setBrowserVer(userAgent.match(/Trident\/\d+.*rv:(\d+)/)[1]);
    }
    // console.log("current browser is : " + browser);
    if (browser == "Apple Safari") {
      // showBrowserModal();
      // browser = "Apple Safari";
      setBrowser("Apple Safari");
      setBrowserVer(userAgent.match(/Version\/(\d+)/)[1]);
    }
  }

  // useEffect(() => {
  //     if (lang === "french") {
  //         setContent(French.notificationSection);
  //     } else if (lang === "german") {
  //         setContent(German.notificationSection);
  //     } else {
  //         setContent(English.notificationSection);
  //     }
  // }, [lang]);

  // const submitHandler = (e) => {
  //      if(email){
  //         let formData = {email: email};
  //         if(!formData.role && page == "candidate"){
  //             formData.role="Job Seeker"
  //         } else if(!formData.role && page == "recruiter"){
  //             formData.role="talent Acquisition Professional"
  //         } else if(!formData.role && page == "panelist"){
  //             formData.role="Subject Matter Expert"
  //         } else if(!formData.role && page == "Hiring Manager"){
  //             formData.role="Hiring Manager"
  //         }
  //         formData["occupation"] = "SUBSCRIPTION"
  //         let api = "https://iy7m7644oflg3z52on4wbiygiq0zxeej.lambda-url.us-east-1.on.aws"
  //         axios.post(api,formData).then(res => {
  //             notification["success"]({
  //                 message: res.data,
  //             });
  //             setEmail('')
  //         },err => {
  //         })
  //      }
  // }
  function onCaptchaChange(value) {
    setReChapthaDisable(false);
    document.querySelector("#hirePPRecaptcha iframe").style.border = "none";
  }
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    setReChapthaDisable(true);
  }
  return (
    <>
      <div className="thirdteen-section flex justify-center items-center">
        <div
          className="section-title"
          data-aos="slide-right"
          data-aos-duration="1000"
          data-aos-once={true}
          data-aos-easing="ease"
        >
          <div className="noti-header">
            {currLangJSON.notificationSection.title}
          </div>
          <div className="sub-title">
            {currLangJSON.notificationSection.subtitle}
          </div>
        </div>
        <div
          className="right-section flex flex-col"
          data-aos="slide-left"
          data-aos-duration="1000"
          data-aos-once={true}
          data-aos-easing="ease"
        >
          <form className="input-wrapper" onSubmit={subscribeHandler}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={currLangJSON.notificationSection.placeholder}
            />
            <button disabled={reChapthaDisable || email === ""}>
              {currLangJSON.notificationSection.subscribeButton}
            </button>
          </form>
          <div className="mt-2 flex justify-center">
            <ReCAPTCHA
              // size="invisible"
              // badge="inline"
              // isolated="true"
              id="hirePPRecaptcha"
              sitekey="6LctnlkpAAAAAIG2BRLu70wFnmmIlPi6S-ieqaBp"
              onChange={onCaptchaChange}
              ref={reCaptchaRef}
              onExpired={onExpiredChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationSection;
