import React from "react";
import { Col, Row } from "antd";
import "../../styles/imprint.css";

function ImprintFourthSection({ currLangJSON }) {
    const { subSections, affiliateDisclosure } = currLangJSON["imprintPage"].fourthSec;

    return (
        <div className="w-full flex justify-center imprint-para-main-container">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="imprint-para px-4 md:px-8 lg:px-0">
                    {subSections.map((section, index) => (
                        <div key={index} className="imprint-sub-section">
                            <div className="imprint-sub-heading">{section.subheading}</div>
                            <p>{section.description}</p>
                            {section.bullets && (
                                <ul className="imprint-bullet-list">
                                    {section.bullets.map((bullet, bulletIndex) => (
                                        <li key={bulletIndex} className="imprint-bullet-item">
                                            <strong>{bullet.split(":")[0]}:</strong> {bullet.split(":")[1]}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                    <div className="imprint-sub-section">
                        <div className="imprint-sub-heading">{affiliateDisclosure.subheading}</div>
                        <p>{affiliateDisclosure.description}</p>
                        {affiliateDisclosure.sections.map((section, index) => (
                            <div key={index}>
                                <strong className="affiliate-sub-heading">{section.subheading}</strong>
                                <ul className="imprint-bullet-list">
                                    {section.bullets.map((bullet, bulletIndex) => (
                                        <li key={bulletIndex} className="imprint-bullet-item">
                                            <strong>{bullet.split(":")[0]}:</strong> {bullet.split(":")[1]}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="imprint-sub-section">
                        <div className="imprint-sub-heading">{currLangJSON["imprintPage"].fourthSec.disclaimerHeading}</div>
                        <p>{currLangJSON["imprintPage"].fourthSec.disclaimerText}</p>
                    </div>
                    <div className="imprint-sub-section">
                        <div className="imprint-sub-heading">{currLangJSON["imprintPage"].fourthSec.copyrightHeading}</div>
                        <p>{currLangJSON["imprintPage"].fourthSec.copyrightText}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ImprintFourthSection;
