import React from "react";
import { Col, Row } from "antd";
import "../../styles/cookiePolicy.css";

function CookiePolicyThirdSection({ currLangJSON }) {
    const details = currLangJSON["cookiePolicyPage"].thirdSec.details;
    const choices = currLangJSON["cookiePolicyPage"].thirdSec.choices;

    return (
        <div className="mt-10 w-full flex justify-center">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="cookie-data-para px-4 md:px-8 lg:px-0">
                    {details.map((section, index) => (
                        <div key={index} className="cookie-data-section">
                            <div className="cookie-data-sub-section-heading">
                                {section.heading}
                            </div>
                            <ul className="cookie-data-definition-list">
                                {section.description.map((item, subIndex) => (
                                    <li key={subIndex} className="cookie-data-definition-item">
                                        <strong className="cookie-data-sub-term">{item.type}</strong>
                                        <ul className="cookie-data-sub-details">
                                            <li>
                                                <strong>Administered by:</strong> {item.administeredBy}
                                            </li>
                                            <li>
                                                <strong>Purpose:</strong> {item.purpose}
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <div className="cookie-data-section">
                        <div className="cookie-data-sub-section-heading">
                            {choices.heading}
                        </div>
                        <p className="cookie-data-sub-section-description">
                            {choices.description}
                        </p>
                        <ul className="cookie-data-links-list">
                            {choices.links.map((link, index) => (
                                <li key={index} className="cookie-data-links-item">
                                    {link.text} <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default CookiePolicyThirdSection;
