import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function ExcessLimitModal({ excessAlert, closeExcessAlert }) {
  return (
    <Modal
      title={
      <div className="flex gap-2 items-center">
        <ExclamationCircleOutlined className="text-[18px]" /> Limit Exceeded
      </div>
    }
      open={excessAlert}
      onCancel={closeExcessAlert}
      centered
      footer={null}
      className="font-[Inter]"
    >
      <p className="text-base mb-3 mt-1">
        You have exceeded daily free uses. If you like our AI Parser, please
        reach out to us to enable free trial.
      </p>
      <div className="flex justify-end">
      <Link to="/contact-us" target="_blank">
        <button className="w-[110px] py-[8px] px-3 text-[12px] font-semibold text-white bg-gradient-to-tr from-[#103662] to-[#206DC5] rounded-[6px] shadow-sm">
          Contact Us
        </button>
      </Link>
      </div>
    </Modal>
  );
}

export default ExcessLimitModal;
