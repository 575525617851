import React from "react";
import { Col, Row, Table } from "antd";
import "../../styles/dataProtection.css";

function GDPRExhibitSection({ currLangJSON }) {
  const details1 = currLangJSON["dataProtectionPage"].tenthSec.details1;
  const details2 = currLangJSON["dataProtectionPage"].tenthSec.details2;
  const details3 = currLangJSON["dataProtectionPage"].tenthSec;

  const columns = [
    {
      title: "Sub-Processor",
      dataIndex: "subProcessor",
      key: "subProcessor",
    },
    {
      title: "Country of Processing",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Processing Activities",
      dataIndex: "activities",
      key: "activities",
    },
    {
      title: "Data Protection Safeguards",
      dataIndex: "safeguards",
      key: "safeguards",
    },
  ];

  return (
    <div className="mt-10 w-full flex justify-center">
      <Row justify="center" className="w-full">
        <Col xs={24} md={20} lg={16} className="data-para px-4 md:px-8 lg:px-0">
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].tenthSec.heading1}
          </div>
          <ul className="data-definition-list">
            {details1.map((item, index) => (
              <li key={index} className="data-definition-item flex">
                <span className="data-term-number mr-2">{item.subheadingNumber}</span>
                <div>
                  <strong className="data-term">{item.subheading}</strong>
                  <p className="data-definition">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="data-section-heading mt-10">
            {currLangJSON["dataProtectionPage"].tenthSec.heading2}
          </div>
          <ul className="data-definition-list">
            {details2.map((item, index) => (
              <li key={index} className="data-definition-item">
                <div className="data-term">
                  <strong>{item.subheading}</strong>
                </div>
                <p className="data-definition">{item.description}</p>
                {item.bullets && (
                  <ul className="list-disc list-inside">
                    {item.bullets.map((bullet, bulletIndex) => (
                      <li key={bulletIndex} className="data-definition">{bullet}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="data-section-heading mt-10">
            {currLangJSON["dataProtectionPage"].tenthSec.heading3}
          </div>
          <p className="data-definition">
            {currLangJSON["dataProtectionPage"].tenthSec.description3}
          </p>
          <div className="overflow-x-auto w-full">


          <Table
            columns={columns}
            dataSource={currLangJSON["dataProtectionPage"].tenthSec.table}
            pagination={false}
            className="mb-8"
          />
          </div>
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].tenthSec.complianceHeading}
          </div>
          <ul className="list-disc list-inside">
            {details3.complianceDetails.map((detail, index) => (
              <li key={index} className="data-definition">{detail}</li>
            ))}
          </ul>
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].tenthSec.notificationHeading}
          </div>
          <ul className="list-disc list-inside">
            {details3.notificationDetails.map((detail, index) => (
              <li key={index} className="data-definition">{detail}</li>
            ))}
          </ul>
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].tenthSec.documentationHeading}
          </div>
          <ul className="list-disc list-inside">
            {details3.documentationDetails.map((detail, index) => (
              <li key={index} className="data-definition">{detail}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default GDPRExhibitSection;
