import React from "react";
import Card1 from "../../assets/image/fifthSecCard1.svg";
import Card2 from "../../assets/image/fifthSecCard2.svg";
import Card3 from "../../assets/image/fifthSecCard3.svg";
import Card4 from "../../assets/image/fifthSecCard4.svg";
import "../../styles//landingPage/fifthSection.css";
import { Carousel } from "antd";

function FifthSection({ currLangJSON }) {
  const contentStyle = {
    // height: "160px",
    // color: "#fff",
    // lineHeight: "160px",
    textAlign: "center",
    // background: "#EAF3FE",
  };
  return (
    <div className="mt-20">
      <div className="publicLanding-secondSec-header">
        {currLangJSON["publicLanding.fifthSec.header"]}
      </div>
      <div className="publicLanding-secondSec-secSubHeader w-[90%] m-auto text-center mt-1">
        <span className="yellow-text">
          {currLangJSON["publicLanding.fifthSec.subHeader.yellowText"]}
        </span>{" "}
        {currLangJSON["publicLanding.fifthSec.subHeader"]}
      </div>
      <div className="text-base text-center text-[#475467] w-[80%] m-auto mt-1">
        {currLangJSON["publicLanding.fifthSec.second.subHeader"]}
      </div>
      <div className="publicLanding-fifthSec-card w-[80%] mx-auto my-auto  gap-1 mt-8  flex flex-wrap justify-start items-start">
        <div className="bg-[#F2F8FE]  p-8 flex item-center gap-3 mt-5 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[550px] hirePPLandingPageBoxShadow" data-aos="fade-right" data-aos-duration="1500" data-aos-once={true} data-aos-easing="ease">
          <img src={Card1} alt=""  />
          <span className="">
            <p className="text-[#185294] text-2xl font-bold">
              {currLangJSON["publicLanding.fifthSec.card1.header"]}
            </p>
            <p className="mt-5 text-[#475467] text-[0.9rem]">
              {currLangJSON["publicLanding.fifthSec.card1.subHeader"]}
            </p>
          </span>
        </div>
        <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 mt-5 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[550px] hirePPLandingPageBoxShadow" data-aos="fade-left" data-aos-duration="1500" data-aos-once={true} data-aos-easing="ease">
          <img src={Card2} alt="" className="" />
          <span className="">
            <p className="text-[#185294] text-2xl font-bold">
              {currLangJSON["publicLanding.fifthSec.card2.header"]}
            </p>
            <p className="mt-5 text-[#475467] text-[0.9rem]">
              {currLangJSON["publicLanding.fifthSec.card2.subHeader"]}
            </p>
          </span>
        </div>
        <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 mt-5 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[550px] hirePPLandingPageBoxShadow" data-aos="fade-right" data-aos-duration="1500" data-aos-once={true} data-aos-easing="ease">
          <img src={Card3} alt="" className="" />
          <span className="">
            <p className="text-[#185294] text-2xl font-bold">
              {currLangJSON["publicLanding.fifthSec.card3.header"]}
            </p>
            <p className="mt-5 text-[#475467] text-[0.9rem]">
              {currLangJSON["publicLanding.fifthSec.card3.subHeader"]}
            </p>
          </span>
        </div>
        <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 mt-5 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[550px] hirePPLandingPageBoxShadow" data-aos="fade-left" data-aos-duration="1500" data-aos-once={true} data-aos-easing="ease">
          <img src={Card4} alt="" className="" />
          <span className="">
            <p className="text-[#185294] text-2xl font-bold">
              {currLangJSON["publicLanding.fifthSec.card4.header"]}
            </p>
            <p className="mt-5 text-[#475467] text-[0.9rem]">
              {currLangJSON["publicLanding.fifthSec.card4.subHeader"]}
            </p>
          </span>
        </div>
      </div>
      <div className="carousel-container-fifthSec">
        <Carousel autoplay autoplaySpeed={3000}>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[520px] ">
                <img src={Card1} alt="" id="fifthSec-cards" className="fifth-sec-img" />
                <span className="">
                  <p className="text-[#185294] text-2xl font-bold">
                    {currLangJSON["publicLanding.fifthSec.card1.header"]}
                  </p>
                  <p className="mt-5 text-[#475467] text-[0.9rem]">
                    {currLangJSON["publicLanding.fifthSec.card1.subHeader"]}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[520px] ">
                <img src={Card2} alt=""  className="fifth-sec-img" />
                <span className="">
                  <p className="text-[#185294] text-2xl font-bold">
                    {currLangJSON["publicLanding.fifthSec.card2.header"]}
                  </p>
                  <p className="mt-5 text-[#475467] text-[0.9rem]">
                    {currLangJSON["publicLanding.fifthSec.card2.subHeader"]}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[520px] ">
                <img src={Card3} alt="" className="fifth-sec-img" />
                <span className="">
                  <p className="text-[#185294] text-2xl font-bold">
                    {currLangJSON["publicLanding.fifthSec.card3.header"]}
                  </p>
                  <p className="mt-5 text-[#475467] text-[0.9rem]">
                    {currLangJSON["publicLanding.fifthSec.card3.subHeader"]}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="" style={contentStyle}>
            <div className="flex m-auto gap-4 px-4 py-4 " style={contentStyle}>
              <div className="bg-[#F2F8FE]  p-8 flex item-center gap-2 rounded-2xl border-2 border-[#D4E7FD] items-center mx-auto w-[520px] ">
                <img src={Card4} alt="" className="fifth-sec-img" />
                <span className="">
                  <p className="text-[#185294] text-2xl font-bold">
                    {currLangJSON["publicLanding.fifthSec.card4.header"]}
                  </p>
                  <p className="mt-5 text-[#475467] text-[0.9rem]">
                    {currLangJSON["publicLanding.fifthSec.card4.subHeader"]}
                  </p>
                </span>
              </div>
            </div>
          </div>

          {/* </div> */}
          {/* <div>
            <h3 style={contentStyle}>2</h3>
          </div>
          <div>
            <h3 style={contentStyle}>3</h3>
          </div>
          <div>
            <h3 style={contentStyle}>4</h3>
          </div> */}
        </Carousel>
      </div>
    </div>
  );
}

export default FifthSection;
