import { Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/contact/contactLandingPage.css";
import twitter from "../../assets/socialMedia/twitter-blue.svg";
import facebook from "../../assets/socialMedia/facebook-blue.svg";
import linkedin from "../../assets/socialMedia/linkedin-blue.svg";
import baseUrl, { contactApi, recaptchaApi } from "../../Api";
import ContactOtpModal from "./ContactOtpModal";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
const { TextArea } = Input;

function ContactLandingPage({ currLangJSON }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    phoneNo: null,
    email: "",
    msg: "",
  });

  const [errorHandle, setErrorHandle] = useState({
    errorFirstName: "",
    errorLastName: "",
    errorPhoneNo: "",
    errorEmail: "",
    errorMsg: "",
  });

  const handleChange = (e, name, errorName) => {
    setErrorHandle({
      ...errorHandle,
      [errorName]: " ",
    });
    setInputData({
      ...inputData,
      [name]: e.target.value,
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const submitApi = () => {
    const body = {
      contactUsEvent: "OTP_VALIDATION_REQUIRED",
      priority: 0,
      contactEmailBody: {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        emailId: inputData.email,
        phoneNumber: inputData.phoneNo,
        textMessage: inputData.msg,
        otp: "string",
      },
    };

    if (inputData.firstName === "" || inputData.firstName === null) {
      setErrorHandle({
        ...errorHandle,
        errorFirstName: "Please provide first name!",
      });
      return;
    } else if (inputData.phoneNo === "" || inputData.phoneNo === null) {
      setErrorHandle({
        ...errorHandle,
        errorPhoneNo: "Please provide phone number!",
      });
      return;
    } else if (inputData.email == "" || inputData.phoneNo == null) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Please provide email address!",
      });
      return;
    } else if (!validateEmail(inputData.email)) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Invalid email format!",
      });
      return;
    }
    // console.log(body);

    const options = {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json", // Type of data being sent
      },
      body: JSON.stringify(body), // Data being sent
    };
    setIsModalVisible(true);

    fetch(`${contactApi}`, options)
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        console.log("Success:", data); // Handle the response data
      })
      .catch((error) => {
        console.error("Error:", error); // Handle any errors
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onCaptchaChange(value) {
    var tokenUrl = recaptchaApi + value;
    // if (value) {
    //   fetch(tokenUrl, { method: "GET" })
    //     .then((response) => response.json()) // Convert response to JSON
    //     .then((data) => {
    //       if (data.data === true) {
    //         setReChapthaDisable(false)
    //         document.querySelector("#hirePPRecaptcha iframe").style.border =
    //           "none";
    //         // sessionStorage.setItem("recaptchaFlag", "true"); // Store boolean as string
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    setReChapthaDisable(false)
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "none";
  }
  //onExpiredChange
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    // sessionStorage.setItem("recaptchaFlag", false);
    setReChapthaDisable(true)
  }

  return (
    <div className="py-[80px] px-[20px]">
      {isModalVisible && (
        <ContactOtpModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          inputData={inputData}
        />
      )}
      <div className="w-[80%] m-auto bg-[#F2F8FE] rounded-lg px-14 py-8">
        <div className="flex flex-col lg:flex-row justify-around items-center w-full gap-5">
          <div className="text-center">
            <p className="font-semibold text-2xl lg:text-3xl" dangerouslySetInnerHTML={{ __html: currLangJSON["contactUsPage.secondSec.heading1"] }} />
            <p className=" text-[#081B31] text-sm lg:text-base text-center lg:mt-1 mt-2">
              {currLangJSON["contactUsPage.secondSec.heading2"]}
            </p>
          </div>
          <div className="lg:w-[320px] w-[90%]">
            <Link to='/schedule-demo'>
              <button className=" contact-second-sec-btn">
                {currLangJSON["contactUsPage.secondSec.schedule"]}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="text-center my-6 yellow-text text-2xl font-bold">Or</div>
      <div>
        <div className="font-bold text-base lg:text-[1.2rem] text-[#2888F6] text-start pt-[40px] w-[80%] m-auto">
          {currLangJSON["contactUsPage.firstSec.greeting"]}
        </div>
        <Row className="w-[80%] m-auto gap-10 ">
          <Col md={24} lg={24} xl={9} className="mr-8 ml-0  my-0">
            <div className="font-bold text-start mt-2">
              <div className="yellow-text text-3xl lg:text-4xl">
                {currLangJSON["contactUsPage.firstSec.heading1"]}
              </div>
              <div className="text-3xl lg:text-4xl mt-[6px]">
                {currLangJSON["contactUsPage.firstSec.heading2"]}
              </div>
            </div>
            <div className="text-base text-[#667085] mt-7">
              {currLangJSON["contactUsPage.firstSec.description"]}
            </div>
            <div className="flex gap-5 mt-7 mb-2">
              <a
                href="https://twitter.com/hireplusplus"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/hireplusplus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a
                href="https://www.facebook.com/hireplusplus?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
            </div>
          </Col>
          <Col md={24} lg={24} xl={13} className=" ">
            <Row className="gap-4">
              <Col md={11} sm={24} xs={24}>
                <p className="mb-1">
                  {currLangJSON["contactUsPage.contact.firstName"]}
                  <span style={{ color: "red" }}> *</span>
                </p>

                <Input
                  required
                  className={` ${
                    errorHandle.errorFirstName.length > 1
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={(e) =>
                    handleChange(e, "firstName", "errorFirstName")
                  }
                />
                <p className="text-[red]"> {errorHandle.errorFirstName} </p>
              </Col>
              <Col md={12} sm={24} xs={24} className="items-end">
                <p className="mb-1">
                  {currLangJSON["contactUsPage.contact.lastName"]}
                </p>

                <Input
                  required
                  onChange={(e) => handleChange(e, "lastName", "errorLastName")}
                />
              </Col>
            </Row>
            <Col md={24} className="mt-5">
              <p className="mb-1">
                {currLangJSON["contactUsPage.contact.phoneNo"]}
                <span style={{ color: "red" }}> *</span>
              </p>

              <Input
                required
                className={` ${
                  errorHandle.errorPhoneNo.length > 1 ? "border-red-500" : ""
                }`}
                onChange={(e) => handleChange(e, "phoneNo", "errorPhoneNo")}
              />
              <p className="text-[red]"> {errorHandle.errorPhoneNo} </p>
            </Col>
            <Col md={24} className="mt-5">
              <p className="mb-1">
                {currLangJSON["contactUsPage.contact.email"]}
                <span style={{ color: "red" }}> *</span>
              </p>

              <Input
                required
                className={` ${
                  errorHandle.errorEmail.length > 1 ? "border-red-500" : ""
                }`}
                onChange={(e) => handleChange(e, "email", "errorEmail")}
              />
              <p className="text-[red]"> {errorHandle.errorEmail} </p>
            </Col>
            <Col md={24} className="mt-5">
              <p className="mb-1">
                {currLangJSON["contactUsPage.contact.help"]}
              </p>

              <TextArea
                style={{
                  height: 140,
                  resize: "none",
                }}
                placeholder={`${currLangJSON["contactUsPage.contact.message"]}...`}
                onChange={(e) => handleChange(e, "msg", "errorMsg")}
              />
            </Col>
            <div className="text-[#667085] mt-8 text-sm tracking-wide">
              {currLangJSON["contactUsPage.contact.description"]}
            </div>
            <div
              className="field flex justify-center mt-3"
              // type={config.type}
              style={{
                width: "100%",
                fontSize: "1.1rem",
                
              }}
            >
              <ReCAPTCHA
              className=""
                id="hirePPRecaptcha"
                sitekey="6LctnlkpAAAAAIG2BRLu70wFnmmIlPi6S-ieqaBp"
                onChange={onCaptchaChange}
                onExpired={onExpiredChange}
              />
            </div>
            <Col
              md={24}
              className="mt-7 text-[17px] leading-[26px] text-[#F2F8FE]"
            >
              <button
              
                className={`${reChapthaDisable?"contactLandingPage-submit-btn-disable": "contactLandingPage-submit-btn"}`}
                onClick={submitApi}
                disabled={reChapthaDisable}
              >
                {currLangJSON["contactUsPage.contact.submit"]}
              </button>
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ContactLandingPage;
