import React from "react";
import "../../styles/employerTermsCondition.css";
import { Col, Row, Flex } from "antd";

function TermsThirdSection({ currLangJSON }) {
  return (
    <div className="w-[100%] justify-self-center termsParaMainContainer">
      <div className="w-[100%] m-auto">
        <Row>
          <Col xs={20} md={20} lg={20} className="terms-para">
            <h2 className="terms-section-heading">
              {currLangJSON["employerTerms.thirdSec.heading"]}
            </h2>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading1"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para1"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading2"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para2"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading3"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para3"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading4"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para5"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading4"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para5"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading6"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para6"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading7"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para7"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading8"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para8"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading9"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para9"]}</p>
            <h3 className="terms-subsection-heading">
              {currLangJSON["employerTerms.thirdSec.subHeading10"]}
            </h3>
            <p>{currLangJSON["employerTerms.thirdSec.para10"]}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermsThirdSection;
