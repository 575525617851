import React from "react";
import { Link } from "react-router-dom";
import {Button} from "antd"

function FAQLastSection() {
  return (
    <div className="mt-24">
      <p className="text-4xl font-[600] text-center">Still have questions?</p>
      <p className="text-[#475467] mt-3 text-xl text-center">Curious about something? Reach out for answers!</p>
      <div style={{width:"100%",textAlign:"center"}} className='items-center'> 
      <Link to="/contact-us"> 
        <button  className="font-semibold py-2 px-3 rounded-lg text-sm bg-[#206DC5] text-[#FCFCFD]" style={{marginTop:"24px"}}>Ask us now</button>
      </Link>
      </div>
    </div>
  );
}

export default FAQLastSection;
