import React from "react";
import { Link } from "react-router-dom";
import firstLineFirstLogo from "../../assets/featureImage/pricing/firstLine-logo1.svg";
import firstLineSecondLogo from "../../assets/featureImage/pricing/firstLine-logo2.svg";
import firstLineThirdLogo from "../../assets/featureImage/pricing/firstLine-logo3.svg";
import firstLineFourthLogo from "../../assets/featureImage/pricing/firstLine-logo4.svg";
import secondLineFirstLogo from "../../assets/featureImage/pricing/secondLine-logo1.svg";
import secondLineSecondLogo from "../../assets/featureImage/pricing/secondLine-logo2.svg";
import secondLineThirdLogo from "../../assets/featureImage/pricing/secondLine-logo3.svg";
import secondLineFourthLogo from "../../assets/featureImage/pricing/secondLine-logo4.svg";

const PricingLastSection = ({ currLangJSON }) => {
  return (
    <div className="pt-12 pb-28 px-5 lg:px-[140px]">
        <h1 className="text-3xl font-semibold text-[#101828] text-center">
          {currLangJSON.pricingPageLastSection.header}
        </h1>
      <h1 className="text-lg mx-auto text-center text-gray-600 mt-3 lg:w-[60%] w-[90%]">
        {currLangJSON.pricingPageLastSection.description}
      </h1>
      <div className="flex mt-7 gap-3 lg:gap-8 justify-center items-center flex-wrap">
        <img src={firstLineFirstLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={firstLineSecondLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={firstLineThirdLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={firstLineFourthLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
      </div>
      <div className="flex mt-3 gap-3 lg:gap-8 justify-center items-center flex-wrap">
        <img src={secondLineFirstLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={secondLineSecondLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={secondLineThirdLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
        <img src={secondLineFourthLogo} alt="logo" className="w-[120px] lg:w-[195px]" />
      </div>
      <div className="flex flex-col justify-center gap-2 sm:gap-3 mt-7 sm:flex-row">
          <Link to="/schedule-demo">
            <button className="w-full sm:w-[180px] py-3 sm:py-[10px] px-6 text-[13px] font-semibold text-[#344054] bg-white border border-[#D0D5DD] rounded-[6px] shadow-sm">
              {currLangJSON["buttonScheduleDemo"]}
            </button>
          </Link>
          <Link to="/contact-us">
            <button className="w-full sm:w-[180px] py-3 sm:py-[10px] px-8 text-[13px] font-semibold text-white bg-gradient-to-tr from-[#103662] to-[#206DC5] rounded-[6px] shadow-sm">
              {currLangJSON.pricingPageLastSection.getStarted}
            </button>
          </Link>
        </div>
    </div>
  );
};

export default PricingLastSection;
