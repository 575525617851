import React from "react";
import "../../styles/aboutPage/about.css";

function AboutFirstSection({ currLangJSON }) {
  return (
    <div className="about-hero-section">
      <section className="flex flex-col gap-5 md:gap-[30px] text-center w-[80%] md:w-[80%] justify-center items-center m-auto">
        <p className="text-[#D9D9D9] font-medium text-base">
          {currLangJSON["aboutUsPage.firstSec.firstHeader.greeting"]}
        </p>
        <p className="text-[30px] md:text-[34px] leading-[40px] text-white font-semibold">
          {currLangJSON["aboutUsPage.firstSec.firstHeader.heading.text1"]}{" "}
          <span className="text-[#F79009]">
            {
              currLangJSON[
                "aboutUsPage.firstSec.firstHeader.heading.yellowText"
              ]
            }
          </span>{" "}
          {currLangJSON["aboutUsPage.firstSec.firstHeader.heading.text2"]}
        </p>
        <p className="text-[#D9D9D9] text-base w-[90%] md:w-[80%]">
          {currLangJSON["aboutUsPage.firstSec.firstHeader.heading.description"]}
        </p>
      </section>
    </div>
  );
}

export default AboutFirstSection;
