import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { contactApi } from "../../Api";
import correct from "../../assets/lotties/correct.json"
import Lottie from "lottie-react";

function ContactOtpModal({ isModalVisible, handleCancel, inputData }) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [errors, setErrors] = useState(new Array(6).fill(false));
  const [otpInvalidText, setOtpInvalidText] = useState();
  const [otpResendText, setOtpResendText] = useState();
  const [timer, setTimer] = useState(30);
  const [verified, setVerified] = useState(false);
  const [verifiedText, setVerifiedText] = useState(
    "We have reached your request, our team will contact you soon"
  );
  const [isDisabled, setIsDisabled] = useState(false || isModalVisible);
  const inputRefs = useRef([]);
  const handleKeyDown = (e, index) => {
    // console.log(!otp[index],index)
    if (e.key === "Backspace" && index > 0) {
      if (otp[index]) {
        inputRefs.current[index].focus();
      } else {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const handleChange = (e, index) => {
    const { value } = e.target;
    // console.log(value,e.target.value)
    setOtpInvalidText("");
    const isValid = /^[a-zA-Z0-9]*$/.test(value);
    if (isValid) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
      if (value) {
        const newErrors = [...errors];
        newErrors[index] = false;
        setErrors(newErrors);
      }
    }
  };
  const handleSubmit = () => {
    let validationErrors = otp.map((value) => value === "");
    setErrors(validationErrors);
    if (validationErrors.every((error) => !error)) {
      console.log("Entered OTP:", otp.join(""));
      const body = {
        contactUsEvent: "CONTACT_US",
        priority: 0,
        contactEmailBody: {
          firstName: inputData.firstName,
          lastName: inputData.lastName,
          emailId: inputData.email,
          phoneNumber: inputData.phoneNo,
          textMessage: inputData.msg,
          otp: otp.join(""),
        },
      };

      console.log(body);

      const options = {
        method: "POST", // HTTP method
        headers: {
          "Content-Type": "application/json", // Type of data being sent
        },
        body: JSON.stringify(body), // Data being sent
      };

      // setIsModalVisible(false);
      fetch(`${contactApi}`, options)
        .then((response) => response.json()) // Parse the JSON response
        .then((data) => {
          if (data.resultStatusInfo.resultCode === "Failure") {
            setOtpInvalidText("Invalid otp");
            return;
          } else if (
            data.resultStatusInfo.resultCode === "CONTACT_US_OTP_EXPIRED"
          ) {
            setOtpInvalidText(data.resultStatusInfo.message);
            return;
          }
          //   handleCancel();
          setVerified(true);
          setVerifiedText(
            "We have reached your request, our team will contact you soon"
          );
          console.log("Success:", data); // Handle the response data
        })
        .catch((error) => {
          console.error("Error:", error); // Handle any errors
        });
    }
  };
  const resendApi = () => {
    let validationErrors = otp.map((value) => value === "");
    setErrors(validationErrors);
    // if (validationErrors.every((error) => !error)) {
    setOtp(new Array(6).fill(""));
    setErrors(new Array(6).fill(false));
    // console.log("Entered OTP:", otp.join(""));
    const body = {
      contactUsEvent: "RESEND_OTP",
      priority: 0,
      contactEmailBody: {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        emailId: inputData.email,
        phoneNumber: inputData.phoneNo,
        textMessage: inputData.msg,
        otp: "",
      },
    };

    console.log(body);

    const options = {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json", // Type of data being sent
      },
      body: JSON.stringify(body), // Data being sent
    };
    setIsDisabled(true);
    //   setIsModalVisible(false);
    fetch(`${contactApi}`, options)
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        setOtpResendText("Otp resend");
        console.log("Success:", data); // Handle the response data
      })
      .catch((error) => {
        console.error("Error:", error); // Handle any errors
      });
    // }
  };

  //   const handleSubmit = () => {
  //     let validationErrors = otp.map((value) => value === "");
  //     setErrors(validationErrors);

  //     if (validationErrors.every((error) => !error)) {
  //       console.log("Entered OTP:", otp.join(""));
  //       //   setIsModalVisible(false);
  //     }
  //   };
  useEffect(() => {
    let timerId;
    if (isDisabled) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(timerId);
            setIsDisabled(false);
            return 30;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [isDisabled]);
  const handleClose = ()=>{
    handleCancel()
    window.location.href="/contact-us"
  }
  return (
    <Modal
      title=""
      visible={isModalVisible}
      //   onOk={handleSubmit}
      onCancel={handleCancel}
      centered
      footer={null}
    >
      {/* <Input   placeholder="Enter OTP" /> */}
      {/* <div className="w-100 border"> */}
      <div>
        {verified ? (
          <div className="mt-4 ">
            <Lottie
              className="m-auto w-[80%] h-[100px]"
              animationData={correct}
              loop={true}
              autoplay={true}
            />
            <p className="text-[green] flex justify-center font-semibold">
              {verifiedText}
            </p>
            <Button className="w-full mt-2" type="primary" size="large" onClick={handleClose}>
              Close
            </Button>
          </div>
        ) : (
          <>
            <div>
              <p className="font-semibold text-[1.2rem] flex items-center justify-center ">
                Verification Code
              </p>
              <p className="w-[75%] text-[#667085] text-center mx-auto mt-2 mb-0 ">
                We have sent a verification code to{" "}
                <a
                  href={`mailto:${inputData.email}`}
                  className="text-blue-500 underline ml-1"
                >
                  {inputData.email}.{" "}
                </a>
                Please enter the verification code below.
              </p>
            </div>

            <div
              className="mx-auto mt-3 mb-0"
              style={{
                display: "flex ",
                justifyContent: "space-between",
                gap: "10px",
                width: "80%",
              }}
            >
              {otp.map((_, index) => (
                <input
                  key={index}
                  value={otp[index]}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  maxLength={1}
                  style={{ outline: "none" }}
                  className={`w-10 h-10 text-center border-b-2 ${
                    otp[index] ? "border-blue-500" : ""
                  } ${errors[index] ? "border-red-500" : ""}`}
                />
              ))}
            </div>
            <p className="text-[red] font-semibold m-0 items-center flex justify-center mt-2">
              {otpInvalidText}
            </p>
            <p className="text-[green] font-semibold m-0 items-center flex justify-center mt-2">
              {otpResendText}
            </p>

            <Row className="mt-4">
              <Col md={12} sm={24} xs={24} className="px-3  mt-2">
                <Button
                  className="w-full"
                  size="large"
                  onClick={resendApi}
                  disabled={isDisabled}
                >
                  Resend code {isDisabled && `(${timer}s)`}
                </Button>
              </Col>
              <Col md={12} sm={24} xs={24} className="px-3  mt-2">
                <Button
                  type="primary"
                  className="w-full"
                  size="large"
                  onClick={handleSubmit}
                >
                  Verify
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ContactOtpModal;
