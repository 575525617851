import React from "react";
import { Col, Row, Table } from "antd";
import "../../styles/dataProtection.css";

function GDPRFifthSection({ currLangJSON }) {
  const columns = [
    {
      title: "Name of Authorized Sub-Processor",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description of Processing",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Country in Which Subprocessing Will Take Place",
      dataIndex: "country",
      key: "country",
    },
  ];

  const data = currLangJSON["dataProtectionPage"].fifthSec.subProcessors.map(
    (processor, index) => ({
      key: index,
      name: processor.name,
      description: processor.description,
      country: processor.country,
    })
  );

  const details = currLangJSON["dataProtectionPage"].fifthSec.details;

  return (
    <div className="mt-10 w-full flex justify-center">
      <Row justify="center" className="w-full">
        <Col xs={24} md={20} lg={16} className="data-para px-4 md:px-8 lg:px-0">
          <div className="data-section-heading">
            {currLangJSON["dataProtectionPage"].fifthSec.heading}
          </div>
          <ul className="data-definition-list">
            {details.map((item, index) => (
              <li key={index} className="w-full flex">
                <span className="data-term-number mr-2">
                  {item.subheadingNumber}
                </span>
                <div className="overflow-x-auto w-full">
                  <strong className="data-term">{item.subheading}</strong>
                  <p className="data-definition">{item.description}</p>
                  {item.subheadingNumber === "4.6" && (
                    <div className="overflow-x-auto w-full">
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        className="data-sub-processors-table mt-4"
                        scroll={{ x: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default GDPRFifthSection;
