import React, { useEffect, useState } from "react";
import "../../styles/aiParser/aiMain.css";
import { Alert, Col, Row, Spin, Tabs, Tree, message } from "antd";
import AiModal from "./AiModal";
import {
  DownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Lottie from "lottie-react";
import LoadingL from "../../assets/lotties/AI-parser-loader.json";
import PdfViewer from "./aiPdfView";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ExcessLimitModal from "./ExcessLimitModal";
import { aiParserResumeFilePostApi } from "../../Api";

function AiParserMain() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keySelect, setKeySelect] = useState("1");
  const [uploadFlag, setUploadFlag] = useState(false);
  const [uploadFailFlag, setUploadFailFlag] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [pdfPages, setPdfPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fingerprint, setFingerprint] = useState(null);
  const [excessAlert, setExcessAlert] = useState(false);
  const [jdDummy, setJdDummy] = useState({
    jdUrl:
      "https://hireplusplus-public-de.s3.eu-central-1.amazonaws.com/open-ui-public-documents/sample-jd.pdf",
    jdParsedData: "dummy",
  });
  const [resumeDummy, setResumeDummy] = useState({
    resumeUrl:
      "https://hireplusplus-public-de.s3.eu-central-1.amazonaws.com/open-ui-public-documents/sample-resume.pdf",
    resumeParsedData: null,
  });

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprint(result.visitorId);
    };
    getFingerprint();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://hireplusplus-public-de.s3.eu-central-1.amazonaws.com/open-ui-public-documents/openApiJd.json"
        );
        const data = await response.json();
        setJdDummy((prev) => ({
          ...prev,
          jdParsedData: data,
        }));
        // setJdApiResponseJSON(data);
        // console.log(data);

        const response2 = await fetch(
          "https://hireplusplus-public-de.s3.eu-central-1.amazonaws.com/open-ui-public-documents/openApiResume.json"
        );
        const data2 = await response2.json();
        setResumeDummy((prev) => ({
          ...prev,
          resumeParsedData: data2,
        }));
        // setResumeApiResponseJSON(data2);
        // console.log(data2);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };
    fetchData();
  }, []);

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  // const convertToJDTree = (parsedData) => {
  //   const validateData = (data, fallback = "N/A") => (data ? data : fallback);

  //   const requiredDegreeLevelList =
  //     parsedData?.educationDetails?.requiredDegreeLevelList?.length > 0
  //       ? parsedData?.educationDetails?.requiredDegreeLevelList?.map(
  //           (degree, index) => ({
  //             title: <span className="child-value">{degree}</span>,
  //             key: `0-3-2-${index}`,
  //           })
  //         )
  //       : [{ title: "N/A", key: "0-3-2-0" }];
  //   const responsibilitiesData =
  //     parsedData?.responsibilities?.length > 0
  //       ? parsedData?.responsibilities?.map((degree, index) => ({
  //           title: <span className="child-value">{degree}</span>,
  //           key: `0-7-${index}`,
  //         }))
  //       : [{ title: "N/A", key: "0-7-0" }];
  //   const jobsTitleData =
  //     parsedData?.jobTitle?.otherJobTitles?.length > 0
  //       ? parsedData?.jobTitle?.otherJobTitles?.map((resp, index) => ({
  //           title: resp,
  //           key: `0-0-2-${index}`,
  //         }))
  //       : [{ title: "N/A", key: "0-0-2" }];

  //   const treeData = [
  //     {
  //       title: <span class="parent-title">Job Title</span>,
  //       key: "0-0",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Main Job Title</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.jobTitle?.mainJobTitle)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-0-0",
  //         },
  //         // {
  //         //   title: (
  //         //     <>
  //         //       <span className="child-title">Language</span> :
  //         //       <span className="child-value">
  //         //         {" "}
  //         //         {validateData(parsedData?.jobTitle?.language)}
  //         //       </span>
  //         //     </>
  //         //   ),
  //         //   key: "0-0-1",
  //         // },
  //         {
  //           title: <span class="child-title">Other jobs</span>,
  //           key: "0-0-2",
  //           children: [{ jobsTitleData }],
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Employer</span>,
  //       key: "0-1",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Name</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employer?.name)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-1-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Industry</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employer?.industry)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-1-1",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Exact details</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(
  //                   parsedData?.employer?.exactDetailsAboutOrganization
  //                 )}
  //               </span>
  //             </>
  //           ),
  //           key: "0-1-2",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Summary</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employer?.summaryAboutOrganization)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-1-3",
  //         },
  //         {
  //           title: "Location",
  //           key: "0-1-4",
  //           children: [
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">Address</span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(parsedData?.employer?.location?.address)}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-1-4-0",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">City</span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(parsedData?.employer?.location?.city)}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-1-4-1",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">Country</span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(parsedData?.employer?.location.country)}
  //                   </span>
  //                 </>
  //               ),
  //               key: "0-1-4-2",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">State</span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(parsedData?.employer?.location?.state)}
  //                   </span>
  //                 </>
  //               ),
  //               key: "0-1-4-3",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Experience</span>,
  //       key: "0-2",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Min</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.experienceInMonths?.min)} months
  //               </span>
  //             </>
  //           ),
  //           key: "0-2-0",
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Education Details</span>,
  //       key: "0-3",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Graduation year</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.educationDetails?.graduationYear)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-3-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Highest education</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(
  //                   parsedData?.educationDetails?.highestLevelOfQualification
  //                 )}
  //               </span>
  //             </>
  //           ),
  //           key: "0-3-1",
  //         },
  //         {
  //           title: <span className="child-title">Required degree</span>,
  //           key: "0-3-2",
  //           children: requiredDegreeLevelList,
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Employment Details</span>,
  //       key: "0-4",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Job type</span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employmentDetails?.jobType)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-4-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Is remote </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(
  //                   parsedData?.employmentDetails?.isRemote === false
  //                     ? "No"
  //                     : "Yes"
  //                 )}
  //               </span>
  //             </>
  //           ),
  //           key: "0-4-1",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Employment type </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employmentDetails?.employmentType)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-4-2",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Contact duration </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.employmentDetails?.contractLength)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-4-3",
  //         },
  //         {
  //           title: <span className="parent-title">Job location</span>,
  //           key: "0-4-4",
  //           children: [
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">Address </span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(
  //                       parsedData?.employmentDetails?.jobLocation?.address
  //                     )}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-4-4-0",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">City </span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(
  //                       parsedData?.employmentDetails?.jobLocation?.city
  //                     )}
  //                   </span>
  //                 </>
  //               ),
  //               key: "0-4-4-1",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">Country </span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(
  //                       parsedData?.employmentDetails?.jobLocation?.country
  //                     )}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-4-4-2",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">Postal code </span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(
  //                       parsedData?.employmentDetails?.jobLocation?.postalCode
  //                     )}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-4-4-3",
  //             },
  //             {
  //               title: (
  //                 <>
  //                   <span className="child-title">State </span> :
  //                   <span className="child-value">
  //                     {" "}
  //                     {validateData(
  //                       parsedData?.employmentDetails?.jobLocation?.state
  //                     )}
  //                   </span>
  //                 </>
  //               ),

  //               key: "0-4-4-4",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Salary</span>,
  //       key: "0-5",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Min. </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.salary?.min)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-5-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Max </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.salary?.max)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-5-1",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Currency type </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.salary?.currency)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-5-2",
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Responsibilities</span>,
  //       key: "0-7",
  //       children: responsibilitiesData,
  //     },
  //     {
  //       title: <span class="parent-title">Requirements</span>,
  //       key: "0-8",
  //       children:
  //         parsedData?.requirements?.length > 0
  //           ? parsedData.requirements.map((req, index) => ({
  //               title: <span className="child-value">{validateData(req)}</span>,
  //               key: `0-8-${index}`,
  //             }))
  //           : [{ title: "N/A", key: "0-8-0" }],
  //     },
  //     {
  //       title: <span class="parent-title">Travel Requirements</span>,
  //       key: "0-9",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Domestic </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(
  //                   parsedData?.travelRequirements?.domestic === false
  //                     ? "No"
  //                     : "Yes"
  //                 )}
  //               </span>
  //             </>
  //           ),

  //           key: "0-9-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">International </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(
  //                   parsedData?.travelRequirements?.international === false
  //                     ? "No"
  //                     : "Yes"
  //                 )}
  //               </span>
  //             </>
  //           ),
  //           key: "0-9-1",
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Certifications</span>,
  //       key: "0-10",
  //       children:
  //         parsedData?.certifications?.length > 0
  //           ? parsedData.certifications.map((req, index) => ({
  //               title: <span className="child-value">{validateData(req)}</span>,
  //               key: `0-10-${index}`,
  //             }))
  //           : [{ title: "N/A", key: "0-10-0" }],
  //     },
  //     {
  //       title: <span class="parent-title">Application details</span>,
  //       key: "0-11",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Url </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.applicationDetails?.applicationUrl)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-11-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Dead-line </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.applicationDetails?.deadlineDate)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-11-1",
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Contact details</span>,
  //       key: "0-12",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Email-ID </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.contact?.email)}
  //               </span>
  //             </>
  //           ),
  //           key: "0-12-0",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Name </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.contact?.name)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-12-1",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Phone no. </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.contact?.phone)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-12-2",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Website </span> :
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(parsedData?.contact?.website)}
  //               </span>
  //             </>
  //           ),

  //           key: "0-12-3",
  //         },
  //       ],
  //     },
  //     {
  //       title: <span class="parent-title">Skills</span>,
  //       key: "0-13",
  //       children:
  //         parsedData?.skills?.length > 0
  //           ? parsedData.skills.map((req, index) => ({
  //               title: <span className="child-value">{validateData(req)}</span>,
  //               key: `0-13-${index}`,
  //             }))
  //           : [{ title: "N/A", key: "0-13-0" }],
  //     },
  //     {
  //       title: (
  //         <>
  //           <span className="parent-title">Overall summary</span> :
  //           <span className="child-value">
  //             {" "}
  //             {validateData(parsedData?.overall_summary)}
  //           </span>
  //         </>
  //       ),
  //       key: "0-14",
  //     },
  //   ];

  //   return treeData;
  // };

  const convertToJDTree = (obj, parentKey = "") => {
    if (!obj || typeof obj !== "object") {
      return [];
    }

    return Object.keys(obj)
      .map((key) => {
        const uniqueKey = parentKey ? `${parentKey}-${key}` : key;
        const value = obj[key];

        // Skip null values, empty objects, and empty arrays
        if (
          value === null ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === "object" &&
            !Array.isArray(value) &&
            Object.keys(value).length === 0)
        ) {
          return null;
        }

        // If the value is an object, recurse into its properties
        if (typeof value === "object" && !Array.isArray(value)) {
          return {
            title: <span class="parent-title"> {key}</span>,
            key: uniqueKey,
            children: convertToJDTree(value, uniqueKey),
          };
        }

        // If the value is an array, recurse into its elements
        if (Array.isArray(value)) {
          return {
            title: key,
            key: uniqueKey,
            children: value.map((item, index) => ({
              title:
                typeof item === "object" && Object.keys(item).length === 0
                  ? "N/A"
                  : typeof item === "object"
                  ? `${key}-${index + 1}`
                  : <span className="child-value">{item}</span>,
              key: `${uniqueKey}-${index}`,
              children:
                typeof item === "object"
                  ? convertToJDTree(item, `${uniqueKey}-${index}`)
                  : [],
            })),
          };
        }

        // Otherwise, it's a primitive value, so just display it
        return {
          title: <span className="child-value">{key}: {value ? value : "N/A"}</span>,
          key: uniqueKey,
        };
      })
      .filter(Boolean);
   
  };

  const convertToResumeTree = (obj, firstRecursion, parentKey = "") => {
    if (!obj || typeof obj !== "object") {
      return [];
    }

    return Object.keys(obj)
      .map((key) => {
        const uniqueKey = parentKey ? `${parentKey}-${key}` : key;
        const value = obj[key];

        // Skip null values, empty objects, and empty arrays
        if (
          value === null ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === "object" &&
            !Array.isArray(value) &&
            Object.keys(value).length === 0)
        ) {
          return null;
        }

        // If the value is an object, recurse into its properties
        if (typeof value === "object" && !Array.isArray(value)) {
          return {
            // title:key,
            title: firstRecursion ? (
              <span class="parent-title"> {key}</span>
            ) : (
              key
            ),
            key: uniqueKey,
            children: convertToResumeTree(value, false, uniqueKey),
          };
        }

        // If the value is an array, recurse into its elements
        if (Array.isArray(value)) {
          return {
            title: firstRecursion ? (
              <span class="parent-title"> {key}</span>
            ) : (
              key
            ),
            key: uniqueKey,
            children: value.map((item, index) => ({
              title:
                typeof item === "object" && Object.keys(item).length === 0
                  ? "N/A"
                  : typeof item === "object"
                  ? `${key}-${index + 1}`
                  : <span className="child-value">{item}</span>,
              key: `${uniqueKey}-${index}`,
              children:
                typeof item === "object"
                  ? convertToResumeTree(item, false, `${uniqueKey}-${index}`)
                  : [],
            })),
          };
        }

        // Otherwise, it's a primitive value, so just display it
        return {
          title: <span className="child-value">{key}: {value ? value : "N/A"}</span>,
          key: uniqueKey,
        };
      })
      .filter(Boolean);
  };

  // const convertToResumeTree = (data) => {
  //   const validateData = (data, fallback = "N/A") => (data ? data : fallback);
  //   const {
  //     personalInformation,
  //     workExperience,
  //     education,
  //     skills,
  //     overall_summary,
  //     professionalSummary,
  //     achievements,
  //     projects,
  //   } = data.parsedData;
  //   const createPersonalInfoNode = (personalInformation) => {
  //     const createContactChildren = (contactInformation) => {
  //       const children = [];

  //       if (contactInformation?.phoneNumber?.length > 0) {
  //         children.push({
  //           title: (
  //             <>
  //               <span className="child-title">Phone</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(contactInformation.phoneNumber.join(", "))}
  //               </span>
  //             </>
  //           ),
  //           key: "phone",
  //         });
  //       }

  //       if (contactInformation?.emailAddress?.length > 0) {
  //         children.push({
  //           title: (
  //             <>
  //               <span className="child-title">Email</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(contactInformation.emailAddress.join(", "))}
  //               </span>
  //             </>
  //           ),
  //           key: "email",
  //         });
  //       }

  //       const cityStateCountry = returnCityStateCountry(
  //         contactInformation?.address?.city,
  //         contactInformation?.address?.state,
  //         contactInformation?.address?.country
  //       );
  //       if (cityStateCountry) {
  //         children.push({
  //           title: (
  //             <>
  //               <span className="child-title">City</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(cityStateCountry)}
  //               </span>
  //             </>
  //           ),
  //           key: "city",
  //         });
  //       }

  //       if (contactInformation?.address?.postalCode) {
  //         children.push({
  //           title: (
  //             <>
  //               <span className="child-title">Postal Code</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(contactInformation?.address?.postalCode)}
  //               </span>
  //             </>
  //           ),
  //           key: "postal",
  //         });
  //       }

  //       if (contactInformation?.address?.streetAddress) {
  //         children.push({
  //           title: (
  //             <>
  //               <span className="child-title">Street Address</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(contactInformation?.address?.streetAddress)}
  //               </span>
  //             </>
  //           ),
  //           key: "address",
  //         });
  //       }

  //       return children;
  //     };

  //     const contactChildren = createContactChildren(
  //       personalInformation?.contactInformation
  //     );

  //     if (personalInformation?.socialProfiles) {
  //       const socialProfiles = Object.entries(
  //         personalInformation.socialProfiles
  //       )
  //         .filter(([key, value]) => value.trim())
  //         .map(([key, value]) => ({
  //           title: (
  //             <>
  //               <span className="child-title">
  //                 {key.charAt(0).toUpperCase() + key.slice(1)}
  //               </span>
  //               :<span className="child-value"> {validateData(value)}</span>
  //             </>
  //           ),
  //           key: `socialProfile-${key}`,
  //         }));

  //       if (socialProfiles.length > 0) {
  //         contactChildren.push({
  //           title: <span className="child-title">Social Profiles</span>,
  //           key: "socialProfiles",
  //           children: socialProfiles,
  //         });
  //       }
  //     }

  //     return {
  //       title: <span className="parent-title">Personal Information</span>,
  //       key: "personalInformation",
  //       children: [
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Name</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {personalInformation?.name?.firstName}{" "}
  //                 {personalInformation?.name?.middleName}{" "}
  //                 {personalInformation?.name?.lastName}
  //               </span>
  //             </>
  //           ),
  //           key: "name",
  //         },
  //         {
  //           title: (
  //             <>
  //               <span className="child-title">Date of Birth</span>:
  //               <span className="child-value">
  //                 {" "}
  //                 {validateData(personalInformation?.dateOfBirth)}
  //               </span>
  //             </>
  //           ),
  //           key: "dob",
  //         },
  //         {
  //           title: <span className="child-title">Contact Information</span>,
  //           key: "contactInformation",
  //           children: contactChildren,
  //         },
  //       ].filter(
  //         (child) =>
  //           child.children !== null || child.key !== "contactInformation"
  //       ),
  //     };
  //   };
  //   const createWorkExperienceNode = (workExperience) => {
  //     return {
  //       title: <span className="parent-title">Work Experience</span>,
  //       key: "workExperience",
  //       children: workExperience?.map((exp, index) => {
  //         const children = [];

  //         if (exp?.originalJobTitle) {
  //           children.push({
  //             title: <span className="child-title">Job Title</span>,
  //             key: `jobTitle-title-${index}`,
  //             children: [
  //               {
  //                 title: (
  //                   <span className="child-value">
  //                     {validateData(exp.originalJobTitle)}
  //                   </span>
  //                 ),
  //                 key: `jobTitle-content-${index}`,
  //               },
  //             ],
  //           });
  //         }

  //         if (exp?.translatedJobTitle) {
  //           children.push({
  //             title: <span className="child-title">Translated Job Title</span>,
  //             key: `translatedJobTitle-title-${index}`,
  //             children: [
  //               {
  //                 title: (
  //                   <span className="child-value">
  //                     {validateData(exp.translatedJobTitle)}
  //                   </span>
  //                 ),
  //                 key: `translatedJobTitle-content-${index}`,
  //               },
  //             ],
  //           });
  //         }

  //         if (
  //           exp?.location?.city ||
  //           exp?.location?.state ||
  //           exp?.location?.country
  //         ) {
  //           children.push({
  //             title: <span className="child-title">Location</span>,
  //             key: `location-title-${index}`,
  //             children: [
  //               {
  //                 title: (
  //                   <span className="child-value">
  //                     {validateData(
  //                       returnCityStateCountry(
  //                         exp.location.city,
  //                         exp.location.state,
  //                         exp.location.country
  //                       )
  //                     )}
  //                   </span>
  //                 ),
  //                 key: `location-content-${index}`,
  //               },
  //             ],
  //           });
  //         }

  //         if (exp?.exactWorkExperienceText) {
  //           children.push({
  //             title: <span className="child-title">Work Experience Info</span>,
  //             key: `workExperience-title-${index}`,
  //             children: [
  //               {
  //                 title: (
  //                   <span className="child-value">
  //                     {validateData(exp.exactWorkExperienceText)}
  //                   </span>
  //                 ),
  //                 key: `workExperience-content-${index}`,
  //               },
  //             ],
  //           });
  //         }

  //         if (exp?.skills?.length > 0) {
  //           children.push({
  //             title: <span className="child-title">Skills</span>,
  //             key: `workSkills-title-${index}`,
  //             children: exp.skills.map((skill, ind) => ({
  //               title: <span className="child-value">{skill}</span>,
  //               key: `workSkills-${index}-${ind}`,
  //             })),
  //           });
  //         }

  //         if (exp?.responsibilities?.length > 0) {
  //           children.push({
  //             title: <span className="child-title">Responsibilities</span>,
  //             key: `responsibilities-title-${index}`,
  //             children: exp.responsibilities.map((resp, ind) => ({
  //               title: <span className="child-value">{resp}</span>,
  //               key: `resposibilities-${index}-${ind}`,
  //             })),
  //           });
  //         }

  //         if (exp?.workSummary) {
  //           children.push({
  //             title: <span className="child-title">Work Summary</span>,
  //             key: `workSummary-title-${index}`,
  //             children: [
  //               {
  //                 title: (
  //                   <span className="child-value">
  //                     {validateData(exp.workSummary)}
  //                   </span>
  //                 ),
  //                 key: `workSummary-content-${index}`,
  //               },
  //             ],
  //           });
  //         }

  //         if (exp?.keywords?.length > 0) {
  //           children.push({
  //             title: <span className="child-title">Keywords</span>,
  //             key: `keywords-title-${index}`,
  //             children: exp.keywords.map((keyword, ind) => ({
  //               title: <span className="child-value">{keyword}</span>,
  //               key: `keywords-${index}-${ind}`,
  //             })),
  //           });
  //         }

  //         return {
  //           title: (
  //             <span className="child-title">
  //               Company: {exp?.companyName} ({exp?.employmentPeriod?.startDate}{" "}
  //               - {exp?.employmentPeriod?.endDate})
  //             </span>
  //           ),
  //           key: `workExperience-${index}`,
  //           children: children,
  //           expanded: true,
  //         };
  //       }),
  //     };
  //   };

  //   const createEducationNode = (education) => {
  //     return {
  //       title: <span className="parent-title">Education</span>,
  //       key: "education",
  //       children: education?.map((edu, index) => {
  //         const children = [];

  //         if (edu?.graduationDate) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Graduation Date</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(edu.graduationDate)}
  //                 </span>
  //               </>
  //             ),
  //             key: `graduationDate-${index}`,
  //           });
  //         }

  //         if (edu?.normalizedLevelOfDegree) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Degree</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(edu.normalizedLevelOfDegree)}
  //                 </span>
  //               </>
  //             ),
  //             key: `degreeLevel-${index}`,
  //           });
  //         }

  //         if (edu?.major) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Major</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(edu.major)}
  //                 </span>
  //               </>
  //             ),
  //             key: `major-${index}`,
  //           });
  //         }

  //         if (edu?.institution) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Institution</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(edu.institution)}
  //                 </span>
  //               </>
  //             ),
  //             key: `institute-${index}`,
  //           });
  //         }

  //         if (edu?.achievedMarks) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Marks</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(edu.achievedMarks)}
  //                 </span>
  //               </>
  //             ),
  //             key: `marks-${index}`,
  //           });
  //         }

  //         return {
  //           title: <span className="child-title">{edu?.degree}</span>,
  //           key: `education-${index}`,
  //           children: children,
  //         };
  //       }),
  //     };
  //   };

  //   const createSkillsNode = (skills) => {
  //     const children = [];

  //     if (skills?.technical && skills.technical.length > 0) {
  //       children.push({
  //         title: <span className="child-title">Technical Skills</span>,
  //         key: "technicalSkills",
  //         children: skills.technical.map((skill, index) => ({
  //           title: <span className="child-value">{skill}</span>,
  //           key: `technicalSkill-${index}`,
  //         })),
  //       });
  //     }

  //     if (skills?.soft && skills.soft.length > 0) {
  //       children.push({
  //         title: <span className="child-title">Soft Skills</span>,
  //         key: "softSkills",
  //         children: skills.soft.map((skill, index) => ({
  //           title: <span className="child-value">{skill}</span>,
  //           key: `softSkill-${index}`,
  //         })),
  //       });
  //     }

  //     if (skills?.certifications && skills.certifications.length > 0) {
  //       children.push({
  //         title: <span className="child-title">Certifications</span>,
  //         key: "certifications",
  //         children: skills.certifications.map((skill, index) => ({
  //           title: <span className="child-value">{skill}</span>,
  //           key: `certifications-${index}`,
  //         })),
  //       });
  //     }

  //     if (skills?.languages && skills.languages.length > 0) {
  //       children.push({
  //         title: <span className="child-title">Languages</span>,
  //         key: "languages",
  //         children: skills.languages.map((lang, index) => ({
  //           title: <span className="child-value">{lang}</span>,
  //           key: `language-${index}`,
  //         })),
  //       });
  //     }

  //     return {
  //       title: <span className="parent-title">Skills</span>,
  //       key: "skills",
  //       children: children,
  //     };
  //   };

  //   const overallSummaryNode = {
  //     title: <span class="parent-title">Overall Summary</span>,
  //     key: "overallSummary",
  //     children: [
  //       {
  //         title: <span className="child-value">{overall_summary}</span>,
  //         key: "summary",
  //       },
  //     ],
  //   };
  //   const createProfessionalSummaryNode = (professionalSummary) => {
  //     const children = [];

  //     if (professionalSummary?.objective) {
  //       children.push({
  //         title: (
  //           <>
  //             <span className="child-title">Objective</span>:
  //             <span className="child-value">
  //               {" "}
  //               {validateData(professionalSummary.objective)}
  //             </span>
  //           </>
  //         ),
  //         key: "objective",
  //       });
  //     }

  //     if (professionalSummary?.professionalInfo) {
  //       children.push({
  //         title: (
  //           <>
  //             <span className="child-title">Info</span>:
  //             <span className="child-value">
  //               {" "}
  //               {validateData(professionalSummary.professionalInfo)}
  //             </span>
  //           </>
  //         ),
  //         key: "professionalInfo",
  //       });
  //     }

  //     return {
  //       title: <span className="parent-title">Professional Summary</span>,
  //       key: "professionalSummary",
  //       children: children,
  //     };
  //   };

  //   const createProjectsNode = (projects) => {
  //     return {
  //       title: <span className="parent-title">Projects</span>,
  //       key: "projects",
  //       children: projects?.map((project, index) => {
  //         const children = [];

  //         // Conditionally add child nodes if the property exists
  //         if (project?.role) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Role</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(project.role)}
  //                 </span>
  //               </>
  //             ),
  //             key: `role-${index}`,
  //           });
  //         }

  //         if (
  //           project?.projectPeriod?.startDate ||
  //           project?.projectPeriod?.endDate
  //         ) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Project Period</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(project.projectPeriod.startDate)} -{" "}
  //                   {validateData(project.projectPeriod.endDate)}
  //                 </span>
  //               </>
  //             ),
  //             key: `projectPeriod-${index}`,
  //           });
  //         }
  //         if (project?.projectSummary) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Project Summary</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(project.projectSummary)}
  //                 </span>
  //               </>
  //             ),
  //             key: `projectSummary-${index}`,
  //           });
  //         }
  //         if (project?.skills?.length) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Skills</span>
  //               </>
  //             ),
  //             key: `projectSkills-${index}`,
  //             children: project.skills.map((skill, ind) => ({
  //               title: <span className="child-value">{skill}</span>,
  //               key: `projectSkill-${index}-${ind}`,
  //             })),
  //           });
  //         }

  //         if (project?.keywords?.length) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Keywords</span>
  //               </>
  //             ),
  //             key: `projectKeywords-${index}`,
  //             children: project.keywords.map((keyword, ind) => ({
  //               title: <span className="child-value">{keyword}</span>,
  //               key: `projectKeyword-${index}-${ind}`,
  //             })),
  //           });
  //         }

  //         if (project?.exactProjectText) {
  //           children.push({
  //             title: (
  //               <>
  //                 <span className="child-title">Project Link</span>:
  //                 <span className="child-value">
  //                   {" "}
  //                   {validateData(project.exactProjectText)}
  //                 </span>
  //               </>
  //             ),
  //             key: `projectLink-${index}`,
  //           });
  //         }

  //         return {
  //           title: <span className="child-title">Title: {project?.title}</span>,
  //           key: `project-${index}`,
  //           children: children,
  //         };
  //       }),
  //     };
  //   };

  //   const skillsNode = createSkillsNode(skills);
  //   const personalInfoNode = createPersonalInfoNode(personalInformation);
  //   const projectsNode =
  //     projects && projects.length > 0 ? createProjectsNode(projects) : null;
  //   const workExperienceNode =
  //     workExperience && workExperience.length > 0
  //       ? createWorkExperienceNode(workExperience)
  //       : null;
  //   const educationNode =
  //     education && education.length > 0 ? createEducationNode(education) : null;
  //   const professionalSummaryNode =
  //     createProfessionalSummaryNode(professionalSummary);

  //   const resumeTreeNodes = [
  //     personalInfoNode,
  //     workExperienceNode ? workExperienceNode : null,
  //     educationNode ? educationNode : null,
  //     skillsNode ? skillsNode : null,
  //     projectsNode ? projectsNode : null,
  //     professionalSummaryNode ? professionalSummaryNode : null,
  //     overallSummaryNode,
  //   ].filter((node) => node !== null);
  //   return resumeTreeNodes;
  // };
  const handleZoomIn = () => {
    setZoom(zoom + 0.2); // Increase zoom by 1.5 times
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.2); // Decrease zoom by 1.5 times
  };

  const items = [
    {
      key: "1",
      label: "Resume Parser",
      children: (
        <Row className="pdf-container overflow-y-auto">
          <Col
            md={24}
            lg={15}
            xl={16}
            className="px-1 w-full pdf-box overflow-x-auto border rounded-lg"
          >
            <div
              className="w-full  flex justify-end"
              style={{
                padding: "10px",
                position: "sticky",
                top: 0,
                zIndex: 1,
                right: 0,
                left: 0,
                background: "white",
              }}
            >
              <div className="">
                {/* <span className="mr-5 ml-0">pages:{pdfPages}</span> */}
                <button
                  onClick={handleZoomIn}
                  disabled={zoom > 1.8 ? true : false}
                  style={{
                    marginRight: "8px",
                    border: "none",
                    // borderRadius: "40%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                  }}
                >
                  <PlusCircleOutlined
                    className={`${
                      zoom > 1.8 ? "text-[#848484]" : "text-[black]"
                    } text-[18px]`}
                    // style={{ fontSize: "18px", color: "#555555" }}
                  />
                  {/* {zoom * 100}% */}
                </button>
                <button
                  onClick={handleZoomOut}
                  disabled={zoom < 0.5 ? true : false}
                  style={{
                    border: "none",
                    // borderRadius: "40%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                  }}
                >
                  <MinusCircleOutlined
                    className={`${
                      zoom < 0.5 ? "text-[#848484]" : "text-[black]"
                    } text-[18px]`}
                  />{" "}
                </button>
                <p
                  className="text-center ml-4 mr-0"
                  style={{
                    borderBottom: "1px solid black",
                    // borderRadius: "35%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                    display: "inline-block",
                    width: "40px",
                  }}
                >
                  {Math.round(zoom * 100)}%
                </p>
              </div>
            </div>
            {/* <iframe
              // src={`${resumeDummy.resumeUrl}`}
              src={`https://docs.google.com/viewer?url=${resumeDummy.resumeUrl}&embedded=true`}
              width="100%"
              height="100%"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                borderColor: "#D0D5DD",
              }}
              title="Resume"
              allowfullscreen
            ></iframe> */}
            <PdfViewer
              url={resumeDummy.resumeUrl}
              zoom={zoom}
              setPdfPages={setPdfPages}
            />
            {/* <p>
    If you cannot see the resume, please <a href={resumeDummy.resumeUrl} download>download it here</a>.
  </p> */}
          </Col>
          <Col
            md={24}
            lg={9}
            xl={8}
            className="px-1 overflow-y-auto  json-container "
          >
            {resumeDummy.resumeParsedData && (
              <Tree
                className="font-[Inter] mt-1"
                showLine
                defaultExpandAll
                switcherIcon={<DownOutlined />}
                // defaultExpandedKeys={[
                //   "personalInformation",
                //   "contactInformation",
                //   "workExperience",
                //   "education",
                //   "skills",
                //   "professionalSummary",
                //   "overallSummary",
                //   "projects",
                // ]}
                onSelect={onSelect}
                // treeData={convertToResumeTree(
                //   resumeDummy?.resumeParsedData?.data
                // )}
                treeData={convertToResumeTree(
                  resumeDummy?.resumeParsedData?.data.parsedData,
                  true
                )}
              />
            )}
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Job Description Parser",
      children: (
        <Row className="pdf-container overflow-y-auto">
          <Col
            md={24}
            lg={15}
            xl={16}
            className=" px-1 w-full pdf-box overflow-x-auto border rounded-lg"
          >
            <div
              className="flex justify-end"
              style={{
                padding: "10px",
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
              }}
            >
              <div className="">
                <span className="mr-5 ml-0">pages:{pdfPages}</span>
                <button
                  onClick={handleZoomIn}
                  disabled={zoom > 1.8 ? true : false}
                  style={{
                    marginRight: "8px",
                    border: "none",
                    // borderRadius: "40%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                  }}
                >
                  <PlusCircleOutlined
                    className={`${
                      zoom > 1.8 ? "text-[#555555]" : "text-[black]"
                    } text-[18px]`}
                    // style={{ fontSize: "18px", color: "#555555" }}
                  />
                  {/* {zoom * 100}% */}
                </button>
                <button
                  onClick={handleZoomOut}
                  disabled={zoom < 0.5 ? true : false}
                  style={{
                    border: "none",
                    // borderRadius: "40%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                  }}
                >
                  <MinusCircleOutlined
                    className={`${
                      zoom < 0.5 ? "text-[#555555]" : "text-[black]"
                    } text-[18px]`}
                  />{" "}
                </button>
                <p
                  className="text-center ml-4 mr-0 p-0 "
                  style={{
                    borderBottom: "1px solid black",
                    // borderRadius: "35%",
                    // background: "#f0f0f0",
                    // padding: "8px",
                    display: "inline-block",
                    width: "40px",
                  }}
                >
                  {Math.round(zoom * 100)}%
                </p>
              </div>
            </div>
            {/* <iframe
              src={jdDummy.jdUrl}
              width="100%"
              height="100%"
              style={{
                overflow: "hidden",
                borderRadius: 8,
                borderColor: "#D0D5DD",
              }}
              title="Resume"
            ></iframe> */}
            <PdfViewer
              url={jdDummy.jdUrl}
              zoom={zoom}
              setPdfPages={setPdfPages}
            />
          </Col>
          <Col
            md={24}
            lg={9}
            xl={8}
            className="px-1 overflow-y-auto json-container"
          >
            {jdDummy.jdParsedData && (
              <Tree
                className="font-[Inter] mt-1"
                showLine
                switcherIcon={<DownOutlined />}
                defaultExpandAll
                // defaultExpandedKeys={[
                //   "0-0",
                //   "0-1",
                //   "0-2",
                //   "0-3",
                //   "0-4",
                //   "0-5",
                //   "0-6",
                //   "0-7",
                //   "0-8",
                //   "0-9",
                // ]}
                onSelect={onSelect}
                treeData={convertToJDTree(
                  jdDummy.jdParsedData?.data?.parsedData
                )}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];
  const onChange = (key) => {
    setKeySelect(key);
    setZoom(1);
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const closeExcessAlert = () => {
    setExcessAlert(false);
  };
  useEffect(() => {
    const info = (type) => {
      if (type === "success") {
        message.success("File uploaded successfully!");
      } else {
        message.error("File upload failed!");
      }
    };
    if (uploadFlag) {
      info("success");
      setUploadFlag(false)
    }
    if (uploadFailFlag) {
      info("fail");
      setUploadFailFlag(false)
    }
  }, [uploadFlag, uploadFailFlag]);

  const downloadJSON = () => {
    // Convert JSON data to string
    let jsonString;
    if (keySelect === "1") {
      jsonString = JSON.stringify(
        resumeDummy?.resumeParsedData?.data?.parsedData
      );
    } else {
      jsonString = JSON.stringify(jdDummy?.jdParsedData?.data?.parsedData);
    }

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute with a filename
    if (keySelect === "1") {
      link.download = "resume_data.json";
    } else {
      link.download = "jd_data.json";
    }

    // Programmatically click the link to trigger the download
    link.click();

    // Cleanup: revoke the URL object after the download
    URL.revokeObjectURL(url);
  };
  function on() {
    document.getElementById("overlayAllJd").style.display = "block";
  }

  function off() {
    document.getElementById("overlayAllJd").style.display = "none";
  }
  return (
    <>
      {excessAlert && (
        <ExcessLimitModal
          excessAlert={excessAlert}
          closeExcessAlert={closeExcessAlert}
        />
      )}
      {loading && (
        <div id="overlayAllJd">
          <div id="loaderPos">
            <Lottie
              animationData={LoadingL}
              loop={true}
              autoplay={true}
              style={{ marginTop: "13vh" }}
            />
            {/* <Lottie
              className="border"
              // options={LoadingL}
              animationData={LoadingL}
              loop={true}
              autoplay={true}
              // height={150}
              // width={150}
              style={{ marginTop: "13vh" }}
            /> */}
          </div>
        </div>
      )}
      {/* <Spin
        spinning={loading}
        className="border-2 min-h-screen mt-14"
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
              zIndex: 99999999,
            }}
            spin
            // fullscreen={true}
          />
        }
      > */}
      {isModalVisible && (
        <AiModal
          isModalVisible={isModalVisible}
          handleClose={handleClose}
          keyValue={keySelect}
          setJdDummy={setJdDummy}
          setResumeDummy={setResumeDummy}
          setLoading={setLoading}
          setUploadFlag={setUploadFlag}
          setUploadFailFlag={setUploadFailFlag}
          setIsModalVisible={setIsModalVisible}
          fingerprint={fingerprint}
          setExcessAlert={setExcessAlert}
        />
      )}
      <div className="ai-main-section  mt-16" id="mainParserContainer">
        <div className="w-[90%] m-auto border py-2 px-4 main-box">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="font-[Inter]"
            tabBarExtraContent={
              <div className="extra-content">
                <button className="download-btn" onClick={downloadJSON}>
                  Download JSON
                </button>
              </div>
            }
          />
          {/* <div className="border-2 btn-con"> */}
          {/* </div> */}
          <div className="flex justify-end mt-3 gap-2">
            <button
              className="btn-main"
              onClick={() => setIsModalVisible(true)}
            >
              Try with your document
            </button>
            <button className="download-mobile-btn" onClick={downloadJSON}>
              Download JSON
            </button>
          </div>
        </div>
      </div>
      {/* </Spin> */}
    </>
  );
}

export default AiParserMain;
