import React from "react";
import "../../styles/contact/contactLandingPage.css";
import FAQ from "../../assets/symbol/FAQ.svg";
import Chat from "../../assets/symbol/Chat.svg";
import Call from "../../assets/symbol/Call.svg";
import Map from "../../assets/image/contactUs/Map.png";
import { Link, NavLink } from "react-router-dom";

function ContactSecondPage({ currLangJSON }) {
  return (
    <>
      <section className="w-[80%] m-auto ">
        {/* <div className="flex justify-center gap-7 flex-wrap">
          <div className="border shadow-sm border-[#D0D5DD] rounded-[12px] w-[270px] h-[160px] flex flex-col justify-center items-center gap-5">
            <img className="cursor-pointer" src={FAQ} alt="FAQ" />
            <p className="text-[#185294] font-bold text-base">FAQ</p>
          </div>
          {/* <div className="border shadow-sm border-[#D0D5DD] rounded-[12px] w-[270px] h-[160px] flex flex-col justify-center items-center gap-5">
            <img className="cursor-pointer" src={Chat} alt="FAQ" />
            <p className="text-[#185294] font-bold text-base">{currLangJSON["contactUsPage.secondSec.chat"]}</p>
          </div>
          <div className="border shadow-sm border-[#D0D5DD] rounded-[12px] w-[270px] h-[160px] flex flex-col justify-center items-center gap-5">
            <img className="cursor-pointer" src={Call} alt="FAQ" />
            <p className="text-[#185294] font-bold text-base">{currLangJSON["contactUsPage.secondSec.call"]}</p>
          </div> */}
        {/* </div> */}
        <div className="mt-14">
          <img src={Map} alt="map" />
        </div>
        <p className="mt-8 text-[#101828] font-semibold text-xl text-center">
          {currLangJSON["contactUsPage.secondSec.world"]}
        </p>
        <div className="mt-12 lg:mt-16 flex flex-col gap-8 lg:gap-16 mx-auto w-[80%]">
          <div className="flex flex-col lg:flex-row justify-between gap-8">
            <div className="flex flex-col gap-2 items-center lg:basis-[350px]">
              <p className="text-[#101828] text-lg font-semibold">{currLangJSON["contactUsPage.secondSec.usa"]}</p>
              <p className="text-[#667085] text-base text-center">
                7924 Preston Road, Ste 350, Plano, TX 75024,
                USA
              </p>
            </div>
            <div className="flex flex-col gap-2 justify-center items-center lg:basis-[350px]">
              <p className="text-[#101828] text-lg font-semibold">
                {currLangJSON["contactUsPage.secondSec.netherlands"]}
              </p>
              <p className="text-[#667085] text-base text-center">
                Blaak 520, Rotterdam, Netherlands.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between gap-8">
            <div className="flex flex-col gap-2 justify-center items-center lg:basis-[350px]">
              <p className="text-[#101828] text-lg font-semibold">{currLangJSON["contactUsPage.secondSec.india"]}</p>
              <p className="text-[#667085] text-base text-center">
              Plot No 1/C, Sy No 83/1, Raidurgam panmaktha Hyderabad Knowledge City, Hyderabad, Telangana 500081.
              </p>
            </div>
            <div className="flex flex-col gap-2 justify-center items-center lg:basis-[350px]">
              <p className="text-[#101828] text-lg font-semibold">{currLangJSON["contactUsPage.secondSec.france"]}</p>
              <p className="text-[#667085] text-base text-center">
                101, Rue Docteur Bauer, 93400 Saint Ouen,
                Grand Paris France
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactSecondPage;
