import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import "../../styles/pricing.css";
import { Button, Tooltip } from "antd";
import MobileCarousel from "./MobileCarousel"; // Import the new MobileCarousel component
import { Link } from "react-router-dom";

const PricingHeroSection = ({ currLangJSON = {} }) => {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [currency, setCurrency] = useState("USD");

  return (
    <div className="pricing-hero-section relative">
      <div
        className="bg-gradient-to-b from-blue-500 to-blue-700 text-white p-10 text-center"
        style={{
          background: "linear-gradient(45deg, #121C26, #206DC5)",
          padding: "140px 80px",
        }}
      >
        <div className="pricingHeroTxt">
          <div className="pricing-banner text-[white] font-semibold flex justify-center items-center mb-4">
            {currLangJSON.pricingPage.heroSection.bannerText}
          </div>
          <h1 className="text-4xl font-bold mt-4">
            {currLangJSON.pricingPage.heroSection.title}
          </h1>
          <p className="mt-4">
            {currLangJSON.pricingPage.heroSection.description}
          </p>
          {/* <div className="mt-8 flex flex-col justify-center items-center space-y-4">
            <div className="flex space-x-2">
              <button
                className={`py-2 px-4 radioBtnCssPrice ${billingCycle === "monthly" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setBillingCycle("monthly")}
              >
                {currLangJSON.pricingPage.heroSection.billingOptions.monthly}
              </button>
              <button
                className={`py-2 px-4 radioBtnCssPrice ${billingCycle === "annual" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setBillingCycle("annual")}
              >
                {currLangJSON.pricingPage.heroSection.billingOptions.annual}
              </button>
            </div>
            <div className="flex space-x-2">
              <button
                className={`py-2 px-4 radioBtnCssPrice ${currency === "USD" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setCurrency("USD")}
              >
                USD
              </button>
              <button
                className={`py-2 px-4 radioBtnCssPrice ${currency === "EUR" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setCurrency("EUR")}
              >
                EUR
              </button>
              <button
                className={`py-2 px-4 radioBtnCssPrice ${currency === "GBP" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setCurrency("GBP")}
              >
                GBP
              </button>
              <button
                className={`py-2 px-4 radioBtnCssPrice ${currency === "INR" ? "bg-white text-blue-700" : "bg-transparent text-white border border-white"}`}
                onClick={() => setCurrency("INR")}
              >
                INR
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="relative bg-gray-100">
        <div
          className="hidden md:flex w-full z-10 justify-center"
          style={{ backgroundColor: "#f9fbfe" }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 p-10 w-full max-w-7xl cardContainerPrice">
            {Object.keys(currLangJSON.pricingPage.heroSection.plans).map(
              (planKey, index) => {
                const plan =
                  currLangJSON.pricingPage.heroSection.plans[planKey];
                return (
                  <div
                    key={planKey}
                    className={`hirePPLandingPageBoxShadow  bg-white shadow-lg rounded-lg p-6 w-full text-center relative flex flex-col`}
                    style={{ marginTop: "-100px" }}
                  >
                    <h2
                      className="text-[22px] font-semibold mb-2 priceCardTitle"
                      style={{ marginTop: "20px" }}
                    >
                      {plan.title}
                    </h2>
                    {/* <p className="text-3xl font-bold">{plan.price}</p> */}
                    {/* <p className="text-gray-500 mb-4">{plan.billingCycle}</p> */}
                    <p className="mb-4 flex-grow">{plan.description}</p>
                    {plan.mostPopular && (
                      <Tooltip title={plan.mostPopular}>
                        <span className="absolute right-4 top-4 bg-orangish text-white px-2 py-1 text-xs rounded-full z-10">
                          {plan.mostPopular}
                        </span>
                      </Tooltip>
                    )}
                    <div className="mt-auto">
                      <Link to="/contact-us">
                        <Button type="primary" className="w-full mb-2">
                          {plan.buttons.getStarted}
                        </Button>
                      </Link>
                      {/* <Link to="/contact-us">
                        <Button className="w-full">
                          {plan.buttons.contactSales}
                        </Button>
                      </Link> */}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <MobileCarousel currLangJSON={currLangJSON} />{" "}
        {/* Add the MobileCarousel component here */}
      </div>
    </div>
  );
};

export default PricingHeroSection;
