import { Alert, Button, Col, Form, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
  aiParserJdFilePostApi,
  aiParserJdLandingPostApi,
  aiParserResumeFilePostApi,
  aiParserResumeLandingPostApi,
  parserJdUploadApi,
  parserResumeUploadApi,
  userIdentifier,
} from "../../Api";
import { LoadingOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import correct from "../../assets/lotties/correct.json";
import ReCAPTCHA from "react-google-recaptcha";


function AiModal({
  isModalVisible,
  handleClose,
  keyValue,
  setResumeDummy,
  setJdDummy,
  setLoading,
  setIsModalVisible,
  setUploadFlag,
  fingerprint,
  setExcessAlert,
  setUploadFailFlag,
}) {
  const [resumeFile, setFileResume] = useState();
  const [verified, setVerified] = useState(false);
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [verifiedText, setVerifiedText] = useState();
  const MAX_FILE_SIZE = 3.5 * 1024 * 1024; //3.5 MB in bytes
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleSubmit = () => {
    let api;
    // let regularResumeApi = `${aiParserResumeFilePostApi}?parsingType=REGULAR&userIdentifier=${userIdentifier}`;
    // let regularJDApi = `${aiParserJdFilePostApi}?parsingType=REGULAR&userIdentifier=${userIdentifier}`;
    let FormData = require("form-data");
    let formData = new FormData();

    if (keyValue === "1") {
      api = aiParserResumeLandingPostApi;
      formData.append("resumeFile", resumeFile);
    } else {
      api = aiParserJdLandingPostApi;
      formData.append("jdFile", resumeFile);
    }

    const options = {
      method: "POST",
      body: formData,
      headers: {
        deviceId: fingerprint,
      },
    };
    setLoading(true);
    setIsModalVisible(false);

    fetch(api, options)
      .then((response) => response.json())
      .then((data) => {
        if (
          data.resultStatusInfo.resultCode.toLowerCase() ===
          "success"
        ) {
          setUploadFlag(true);
          if (keyValue === "1") {
            setVerifiedText("Resume upload successfull");
            setVerified(true);
            setResumeDummy((prev) => ({
              ...prev,
              resumeUrl: data.data.url,
              resumeParsedData: data,
            }));
          } else {
            setVerifiedText("JD upload successfull");
            setVerified(true);
            setJdDummy((prev) => ({
              ...prev,
              jdUrl: data.data.url,
              jdParsedData: data,
            }));
          }
          setLoading(false);
        } else if (data.resultStatusInfo.resultCode === "RATE_LIMIT_EXCEEDED") {
          if (keyValue === "1") {
            setExcessAlert(true);
          } else {
            setExcessAlert(true);
          }
          setLoading(false);
        } else {
          setUploadFailFlag(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile.size <= MAX_FILE_SIZE) {
      setFileResume(selectedFile);
      // setErrorHandle({
      //   ...errorHandle,
      //   errorFile: null,
      // });
    } else {
      alert("File size exceeds the 3.5MB limit.");
      event.target.value = null; // Reset the file input
    }
    // setFileResume(file);
  };
  const handleFileReader = (event) => {
    const selectedFile = event.target.files[0];
    // console.log(event[0], event.target.files[0]);

    if (selectedFile) {
      if (selectedFile.size <= MAX_FILE_SIZE) {
        setFileResume(selectedFile);
        // setErrorHandle({
        //   ...errorHandle,
        //   errorFile: null,
        // });
      } else {
        alert("File size exceeds the 3.5MB limit.");
        event.target.value = null; // Reset the file input
      }
    }
  };
  function onCaptchaChange(value) {
    setReChapthaDisable(false);
    document.querySelector("#hirePPRecaptcha iframe").style.border = "none";
  }
  //onExpiredChange
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    // sessionStorage.setItem("recaptchaFlag", false);
    setReChapthaDisable(true);
  }
  return (
    // <Spin
    //   spinning={loading}
    //   indicator={
    //     <LoadingOutlined
    //       style={{
    //         fontSize: 24,
    //       }}
    //       spin
    //     />
    //   }
    // >
    <>
      <Modal
        title={`Upload Your ${keyValue === "1" ? "Resume" : "Job Description"}`}
        visible={isModalVisible || verified}
        onCancel={handleClose}
        centered
        footer={null}
        className="font-[Inter]"
      >
        {verified && <Alert message={verifiedText} type="success" showIcon />}
        {/* {verified ? (
          <div className="mt-4 ">
            <Lottie
              className="m-auto w-[80%] h-[100px]"
              animationData={correct}
              loop={true}
              autoplay={true}
            />
            <p className="text-[green] flex justify-center font-semibold">
              {verifiedText}
            </p>
            <Button
              className="w-full mt-2"
              type="primary"
              size="large"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        ) : ( */}
        <div onDragOver={handleDragOver} onDrop={handleDrop} className=" mt-8">
          {/* <FormGroup> */}
          <input
            onChange={handleFileReader}
            type="file"
            id="file-zip"
            accept=".pdf,.doc,.docx"
            name="uploadZipFile"
            required
            className="w-full border-2 mt-5"
            style={{ display: "none" }}
          />
          <label
            htmlFor="file-zip"
            className="border-2  rounded-3 p-3 text-center flex items-center"
            style={{
              // background: "linear-gradient(45deg, #121C26, #206DC5)",
              borderRadius: "8px",
              height: "150px",
              color: "black",
              cursor: "pointer",
              // border: "none",
              outline: "none",
              display: "block",
              // width:"100%"
            }}
          >
            {" "}
            {resumeFile?.name ? (
              <div
                style={{ color: "green" }}
                className="font-semibold text-center mt-12"
              >
                {resumeFile?.name}
              </div>
            ) : (
              <>
                <p className="mt-10">Click to Upload or drag and drop</p>
                <p> .pdf,doc,docx (max. 3.5 MB)</p>
              </>
            )}
          </label>

          {/* <p className="text-[red]">{errorHandle.errorFile}</p> */}
          {/* </FormGroup> */}
          <div
            className="field flex justify-center mt-3"
            // type={config.type}
            style={{
              width: "100%",
              fontSize: "1.1rem",
            }}
          >
            <ReCAPTCHA
              className=""
              id="hirePPRecaptcha"
              sitekey="6LctnlkpAAAAAIG2BRLu70wFnmmIlPi6S-ieqaBp"
              onChange={onCaptchaChange}
              onExpired={onExpiredChange}
            />
          </div>
          <Row className="mt-4">
            <Col md={12} sm={24} xs={24} className="px-2 mt-2">
              <button
                size="large"
                className="w-full border-2 rounded-[6px] py-2"
                onClick={handleClose}
              >
                Cancel
              </button>
            </Col>
            <Col md={12} sm={24} xs={24} className="px-2 mt-2">
              <button
                size="large"
                className="bg-[#254a6f] w-full border-2 py-2 rounded-[6px] px-4 text-[white]"
                onClick={handleSubmit}
                disabled={reChapthaDisable || !resumeFile}
              >
                Upload document
              </button>
            </Col>
          </Row>
        </div>
        {/* )} */}
      </Modal>
    </>
    // </Spin>
  );
}

export default AiModal;
