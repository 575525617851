import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacySixthSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.sixthSec.table.columns.nature"],
      dataIndex: 'nature',
      key: 'nature',
    },
    {
      title: currLangJSON["privacyPage.sixthSec.table.columns.purpose"],
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: currLangJSON["privacyPage.sixthSec.table.columns.safeguards"],
      dataIndex: 'safeguards',
      key: 'safeguards',
    },
  ];

  const data = currLangJSON["privacyPage.sixthSec.table.rows"].map((row, index) => ({
    key: index,
    nature: row.nature,
    purpose: row.purpose,
    safeguards: row.safeguards,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.sixthSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.sixthSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.sixthSec.additionalInfoHeading"]}</h3>
      <ul className="list-disc pl-6">
        {currLangJSON["privacyPage.sixthSec.additionalInfo"].map((info, index) => (
          <li key={index} className="mb-2">
            <strong>{info.title}:</strong> {info.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacySixthSection;
