import React, { useEffect, useState } from "react";
import NotificationSection from "../components/NotificationSection";
import Topbar from "../components/TopBar";
import Footer from "../components/Footer";
import English from "../utils/language/English.json";
import German from "../utils/language/German.json";
import French from "../utils/language/French.json";
import ContactLandingPage from "../components/contact/ContactLandingPage";
import ContactSecondPage from "../components/contact/ContactSecondPage";

function Contact() {
  const [globalLang, setGlobalLang] = useState(English);

  useEffect(() => {
    var topbardText = document.querySelectorAll(".topbard-text");
    for (let i = 0; i < topbardText.length; i++) {
      topbardText[i].style.color = "#206DC5";
    }
    var hamburger = document.querySelector(".fas.fa-bars");
    hamburger.style.color = "#206DC5";
    let aiIcon = document.querySelector(".ai-topbar-icon path");
    if (aiIcon) {
      // aiIcon.style.fill = 'blue';
      aiIcon.style.stroke = "rgb(32, 109, 197)";
    }

    const handleScroll = () => {
      // var topBar = document.querySelector('.topbar .back-cover ');
      var topBar = document.querySelector(".topbar-logo");
      var hamburger = document.querySelector(".fas.fa-bars");
      var backCover = document.querySelector(".back-cover");
      var weServe = document.querySelector(".weServe-text");
      var topbardText = document.querySelectorAll(".topbard-text");
     
      var topBarNav = document.querySelector(
        ".topbar .right-section .nav-menu "
      );

      // const topBar = document.querySelector(".topbar");
      if (window.scrollY > 0) {
        // topBar.classList.add("scrolled");
        // console.log(window.scrollY)

        topBar.style.background = "white";
        backCover.style.background = "white";
        backCover.style.boxShadow =
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px";
        topBar.style.boxShadow =
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px";
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        // backCover.style.opacity = '0.7';
        hamburger.style.color = "#0638c3";
        // navMenuA.style.color = 'blue';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = "#206DC5";
        }
        // console.log(navMenuA)

        weServe.style.color = "#0638c3";
        topBar.style.transition = "ease-in-out 3ms ";
        topBarNav.style.transition = "ease-in-out 3ms ";
      } else {
        hamburger.style.color = "#206DC5";
        weServe.style.color = "white";
        // topbardText.style.color = 'white';
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = "#206DC5";
        }
        // navMenuA.style.color = 'white';
        topBar.style.background = "none";
        backCover.style.background = "none";
        backCover.style.boxShadow = "none";
        topBar.style.boxShadow = "none";
        // backCover.style.background = 'linear-gradient(to right, black, #1969c4)';
        topBar.style.transition = "background 0.3ms ease";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const langChange = (storedLang) => {
    // setGlobalLang(value)
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
    // console.log(value)
  };
  return (
    <div>
      <div className="public-landing-page">
        <Topbar page="contact" globalLang={langChange} />
        <ContactLandingPage currLangJSON={globalLang} />
        <ContactSecondPage currLangJSON={globalLang} />
        <NotificationSection currLangJSON={globalLang} />
        <Footer currLangJSON={globalLang} />
      </div>
    </div>
  );
}

export default Contact;
