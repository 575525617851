import React from "react";
import "../../styles/termsAndCondition.css";
import dot from "../../assets/logo/Dot.svg";
import { Col, Row, Flex } from "antd";


function TermsFirstSection({ currLangJSON }) {
    return (<>
        <Row className="terms-hero-section" justify="center">
            <Col xs={24} md={20} lg={16} className="terms-content">
                <div className="terms-banner">
                    <span>{currLangJSON["privacyPage.firstSec.currentDate"]}</span>
                </div>
                <div className="terms-main-heading">
                    {currLangJSON["privacyPage.firstSec.heading"]}
                </div>
                <div className="terms-sub-heading">
                    {currLangJSON["privacyPage.firstSec.subHeading"]}
                </div>

            </Col>
        </Row>
        <div className="w-[100%] mt-10 justify-self-center termsParaMainContainer">
            <div className="w-[80%] m-auto">
                <Row >
                    <Col xs={24} md={24} lg={24} className="terms-para">
                        <Flex justify="center">
                            <div className="terms-intro-paragraph">
                                {currLangJSON["privacyPage.firstSec.introParagraph"]}
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </div>

    </>

    );
}

export default TermsFirstSection;
