import React from "react";
import { Link } from "react-router-dom";

function AboutCareerSection({ currLangJSON }) {
  return (
    <>
      <section className="about-career-section">
        <div className="text-bluishText font-medium text-sm mt-10 lg:mt-20 mb-5 text-center lg:text-center">
          {currLangJSON["aboutUsPage.careerSection.firstSection.heading1"]}
        </div>
        <h1 className="text-3xl leading-[40px] font-bold w-full lg:w-[70%] text-center lg:text-center m-auto">
          <span className="text-[#F79009]">
            {
              currLangJSON[
                "aboutUsPage.careerSection.firstSection.heading2.yellowText"
              ]
            }
          </span>{" "}
          {currLangJSON["aboutUsPage.careerSection.firstSection.heading2.text"]}
        </h1>
        <section className="flex flex-wrap justify-between mt-10 gap-8 text-center lg:text-left">
          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card1.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card1.description"]}
            </div>
          </div>
          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card2.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card2.description"]}
            </div>
          </div>
          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card3.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card3.description"]}
            </div>
          </div>
          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card4.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card4.description"]}
            </div>
          </div>

          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card5.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card5.description"]}
            </div>
          </div>
          <div className="p-1 mx-auto lg:mx-0 basis-[370px]">
            <div className="font-semibold text-base">
              {currLangJSON["aboutUsPage.careerSection.card6.heading"]}
            </div>
            <div className="text-aboutBodyText text-sm mt-2">
              {currLangJSON["aboutUsPage.careerSection.card6.description"]}
            </div>
          </div>
        </section>
      </section>
      <section className="about-last-section text-center bg-[#EAF3FE] mt-5 mb-3">
        <div className="text-[#206DC5] font-medium text-sm mb-5">
          {currLangJSON["aboutUsPage.careerSection.lastSection.heading1"]}
        </div>
        <h1 className="text-3xl font-bold">
          {currLangJSON["aboutUsPage.careerSection.lastSection.heading2"]}{" "}
          <span className="text-[#F79009]">
            {
              currLangJSON[
                "aboutUsPage.careerSection.lastSection.heading2.yellowText"
              ]
            }
          </span>
        </h1>
        <p className="text-center mt-4 text-aboutBodyText">
          {currLangJSON["aboutUsPage.careerSection.lastSection.description1"]}
        </p>
        <p className="text-center mt-4 text-aboutBodyText">
          {currLangJSON["aboutUsPage.careerSection.lastSection.description2"]}
        </p>
        <div className="flex flex-col sm:flex-row gap-3 justify-center items-center mt-5">
          <Link to="/jobs"  >
            <button className="w-[130px] py-[10px] px-5 text-[13px] font-semibold text-[#344054] bg-white border border-[#D0D5DD] rounded-[6px] shadow-sm">
              {currLangJSON["aboutUsPage.careerSection.careerButton"]}
            </button>
          </Link>
          <Link to="/contact-us"  >
            <button className="w-[130px] py-[10px] px-5 text-[13px] font-semibold text-white bg-gradient-to-tr from-[#103662] to-[#206DC5] border border-[#7F56D9] rounded-[6px] shadow-sm">
              {currLangJSON["aboutUsPage.careerSection.contactButton"]}
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default AboutCareerSection;
