import React from "react";

function SecondSection() {
  return (
    <div className="second-section-container mt-6">
      <div className="card">
        <p className="flex items-center justify-center">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D4E7FD" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#EAF3FE"
              stroke-width="8"
            />
            <path
              d="M33 24V21L36 18L37 20L39 21L36 24H33ZM33 24L29 27.9999M39 28C39 33.5228 34.5228 38 29 38C23.4772 38 19 33.5228 19 28C19 22.4772 23.4772 18 29 18M34 28C34 30.7614 31.7614 33 29 33C26.2386 33 24 30.7614 24 28C24 25.2386 26.2386 23 29 23"
              stroke="#206DC5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
        <h3>Unparalleled Accuracy</h3>
        <p>
          Extract data precisely across 150+ headers in multiple languages,
          eliminating errors and inefficiencies.
        </p>
      </div>
      <div className="card">
        <p className="flex items-center justify-center">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D4E7FD" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#EAF3FE"
              stroke-width="8"
            />
            <g clip-path="url(#clip0_3724_11553)">
              <path
                d="M24.9859 34.5H19.4859M22.4859 29H17.9859M24.9859 23.5H19.9859M32.9859 20L26.3894 29.235C26.0975 29.6438 25.9515 29.8481 25.9578 30.0185C25.9633 30.1669 26.0344 30.3051 26.152 30.3958C26.287 30.5 26.5381 30.5 27.0404 30.5H31.9859L30.9859 38L37.5823 28.765C37.8743 28.3562 38.0203 28.1519 38.0139 27.9815C38.0084 27.8331 37.9373 27.6949 37.8198 27.6042C37.6848 27.5 37.4336 27.5 36.9313 27.5H31.9859L32.9859 20Z"
                stroke="#206DC5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3724_11553">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(16 17)"
                />
              </clipPath>
            </defs>
          </svg>
        </p>
        <h3>Seamless Integration</h3>
        <p>
          Integrate easily with your ATS, CRM, job board, or any application
          with minimal setup.
        </p>
      </div>
      <div className="card">
        <p className="flex items-center justify-center">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D4E7FD" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#EAF3FE"
              stroke-width="8"
            />
            <path
              d="M29.5295 22.3519C28.9571 22.7599 28.2566 23 27.5 23C25.567 23 24 21.433 24 19.5C24 17.567 25.567 16 27.5 16C28.753 16 29.8522 16.6584 30.4705 17.6481M22 34.0872H24.6103C24.9506 34.0872 25.2889 34.1277 25.6188 34.2086L28.3769 34.8789C28.9753 35.0247 29.5988 35.0388 30.2035 34.9214L33.253 34.3281C34.0585 34.1712 34.7996 33.7854 35.3803 33.2205L37.5379 31.1217C38.154 30.5234 38.154 29.5524 37.5379 28.9531C36.9832 28.4134 36.1047 28.3527 35.4771 28.8103L32.9626 30.6449C32.6025 30.9081 32.1643 31.0498 31.7137 31.0498H29.2855L30.8311 31.0498C31.7022 31.0498 32.4079 30.3633 32.4079 29.5159V29.2091C32.4079 28.5055 31.9156 27.892 31.2141 27.7219L28.8286 27.1417C28.4404 27.0476 28.0428 27 27.6431 27C26.6783 27 24.9319 27.7988 24.9319 27.7988L22 29.0249M36 20.5C36 22.433 34.433 24 32.5 24C30.567 24 29 22.433 29 20.5C29 18.567 30.567 17 32.5 17C34.433 17 36 18.567 36 20.5ZM18 28.6L18 34.4C18 34.9601 18 35.2401 18.109 35.454C18.2049 35.6422 18.3578 35.7951 18.546 35.891C18.7599 36 19.0399 36 19.6 36H20.4C20.9601 36 21.2401 36 21.454 35.891C21.6422 35.7951 21.7951 35.6422 21.891 35.454C22 35.2401 22 34.9601 22 34.4V28.6C22 28.0399 22 27.7599 21.891 27.546C21.7951 27.3578 21.6422 27.2049 21.454 27.109C21.2401 27 20.9601 27 20.4 27L19.6 27C19.0399 27 18.7599 27 18.546 27.109C18.3578 27.2049 18.2049 27.3578 18.109 27.546C18 27.7599 18 28.0399 18 28.6Z"
              stroke="#206DC5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
        <h3>Cost-Effective Solution</h3>
        <p>
          Significantly reduce costs by almost 50% without compromising on
          quality, performance.
        </p>
      </div>
      <div className="card">
        <p className="flex items-center justify-center">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D4E7FD" />
            <rect
              x="4"
              y="4"
              width="48"
              height="48"
              rx="24"
              stroke="#EAF3FE"
              stroke-width="8"
            />
            <path
              d="M24.9996 31C24.9996 31 26.3121 32.5 28.4996 32.5C30.6871 32.5 31.9996 31 31.9996 31M31.2496 26H31.2596M25.7496 26H25.7596M28.4996 37C33.194 37 36.9996 33.1944 36.9996 28.5C36.9996 23.8056 33.194 20 28.4996 20C23.8052 20 19.9996 23.8056 19.9996 28.5C19.9996 29.45 20.1555 30.3636 20.443 31.2166C20.5512 31.5376 20.6053 31.6981 20.6151 31.8214C20.6247 31.9432 20.6174 32.0286 20.5873 32.1469C20.5568 32.2668 20.4894 32.3915 20.3547 32.6408L18.7191 35.6684C18.4857 36.1002 18.3691 36.3161 18.3952 36.4828C18.4179 36.6279 18.5034 36.7557 18.6288 36.8322C18.7728 36.9201 19.0169 36.8948 19.5052 36.8444L24.6262 36.315C24.7813 36.299 24.8588 36.291 24.9295 36.2937C24.999 36.2963 25.0481 36.3029 25.1159 36.3185C25.1848 36.3344 25.2714 36.3678 25.4448 36.4345C26.3928 36.7998 27.4228 37 28.4996 37ZM31.7496 26C31.7496 26.2761 31.5258 26.5 31.2496 26.5C30.9735 26.5 30.7496 26.2761 30.7496 26C30.7496 25.7239 30.9735 25.5 31.2496 25.5C31.5258 25.5 31.7496 25.7239 31.7496 26ZM26.2496 26C26.2496 26.2761 26.0258 26.5 25.7496 26.5C25.4735 26.5 25.2496 26.2761 25.2496 26C25.2496 25.7239 25.4735 25.5 25.7496 25.5C26.0258 25.5 26.2496 25.7239 26.2496 26Z"
              stroke="#206DC5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
        <h3>Continuous Improvement</h3>
        <p>
          Our self-learning AI models evolve with your needs, ensuring your
          parsing stays ahead of the curve.
        </p>
      </div>
    </div>
  );
}

export default SecondSection;
