import React, { useRef, useEffect, useState } from 'react';
import "../../styles/landingPage/thirdSection.css";

const VideoPlayer = ({ src, type, poster, width = "100%", height = "max-content", fixedHeight = "max-content" }) => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.25, // Trigger when 25% of the video is in view
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      setFade(true);
    }
  }, [isVisible]);

  useEffect(() => {
    setFade(false);
    const timer = setTimeout(() => {
      setFade(true);
    }, 50); // Short delay to allow fade out before fade in
    return () => clearTimeout(timer);
  }, [src]);

  return (
    <div ref={videoRef} className="video-container hirePPLandingPageBoxShadow-video" style={{ width, height: fixedHeight,marginTop:"auto",marginBottom:"auto" }}>
      {isVisible && (
        <video
          width={width}
          height={height}
          autoPlay
          poster={poster}
          muted
          loop
          style={{ borderRadius: '10px' }}
          className={fade ? 'fade-in' : 'fade-out'}
          key={src}  // Add key to force re-render when src changes
        >
          <source src={src} type={type} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
