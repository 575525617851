import React from "react";
import { Col, Row } from "antd";
import "../../styles/dataProtection.css";

function GDPRSecondSection({ currLangJSON }) {
    const definitions = currLangJSON["dataProtectionPage"].secondSec.definitions;

    return (
        <div className="mt-10 w-full flex justify-center">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="data-para px-4 md:px-8 lg:px-0">
                    <div className="data-section-heading">
                        {currLangJSON["dataProtectionPage"].secondSec.heading}
                    </div>
                    <ul className="data-definition-list">
                        {definitions.map((item, index) => (
                            <li key={index} className="data-definition-item flex">
                                <span className="data-term-number mr-2">{item.termNumber}</span>
                                <div>
                                    <strong className="data-term">{item.term}</strong>
                                    <p className="data-definition">{item.definition}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    );
}

export default GDPRSecondSection;
