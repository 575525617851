import { Button, Col, Input, Modal, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { contactApi, resumeUploadApi, resumeUploadJdApi } from "../../Api";
import correct from "../../assets/lotties/correct.json";
import Lottie from "lottie-react";
import { LoadingOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";

function ResumeUploadModal({ isModalVisible, handleCancel, jobId }) {
  var FormData = require("form-data");
  var formData = new FormData();
  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedText, setVerifiedText] = useState(
    "Resume uploaded successfully"
  );
  const [resumeFile, setFileResume] = useState();
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    phoneNo: null,
    email: "",
    msg: "",
  });

  const [errorHandle, setErrorHandle] = useState({
    errorFirstName: "",
    errorLastName: "",
    errorPhoneNo: "",
    errorEmail: "",
    errorFile: "",
  });

  const handleChange = (e, name, errorName) => {
    setErrorHandle({
      ...errorHandle,
      [errorName]: " ",
    });
    setInputData({
      ...inputData,
      [name]: e.target.value,
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleFileReader = (event) => {
    const selectedFile = event.target.files[0];
    // console.log(event[0], event.target.files[0]);

    if (selectedFile) {
      if (selectedFile.size <= MAX_FILE_SIZE) {
        setFileResume(selectedFile);
        setErrorHandle({
          ...errorHandle,
          errorFile: null,
        });
      } else {
        alert("File size exceeds the 10MB limit.");
        event.target.value = null; // Reset the file input
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFileResume(file);
  };

  const submitApi = () => {
    // const body = {
    //   firstName: inputData.firstName,
    //   lastName: inputData.lastName,
    //   emailId: inputData.email,
    //   phoneNumber: inputData.phoneNo,
    // };

    const body = {};
    body["firstName"] = inputData.firstName;
    body["lastName"] = inputData.lastName;
    body["mobileNumber"] = inputData.phoneNo;
    body["emailId"] = inputData.email;
    body["resumeFile"] = resumeFile;
    if (jobId) {
      body["jdIdentifier"] = jobId;
    }
    formData.append("candidateDetailsRequest", JSON.stringify(body));
    formData.append("resumeFile", resumeFile);

    if (inputData.firstName === "" || inputData.firstName === null) {
      setErrorHandle({
        ...errorHandle,
        errorFirstName: "Please provide first name!",
      });
      return;
    } else if (inputData.phoneNo === "" || inputData.phoneNo === null) {
      setErrorHandle({
        ...errorHandle,
        errorPhoneNo: "Please provide phone number!",
      });
      return;
    } else if (inputData.email == "" || inputData.phoneNo == null) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Please provide email address!",
      });
      return;
    } else if (!validateEmail(inputData.email)) {
      setErrorHandle({
        ...errorHandle,
        errorEmail: "Invalid email format!",
      });
      return;
    } else if (resumeFile === null || resumeFile === undefined) {
      setErrorHandle({
        ...errorHandle,
        errorFile: "Please upload File!",
      });
      return;
    }
    // console.log(body);

    const options = {
      method: "POST", // HTTP method
      headers: {
        reCaptcha: "string", // Add your reCaptcha token here
      },
      body: formData, // Data being sent
    };
    setLoading(true);

    fetch(`${jobId ? resumeUploadJdApi : resumeUploadApi}`, options)
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        // console.log("Success:", data); // Handle the response data
        if (data.resultStatusInfo.resultCode === "Success") {
          setVerified(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
        // window.location.href="./jobs"
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error); // Handle any errors
      });
  };
  const handleClose = () => {
    handleCancel();
    window.location.href = "/jobs";
  };
  function onCaptchaChange(value) {
    setReChapthaDisable(false);
    document.querySelector("#hirePPRecaptcha iframe").style.border = "none";
  }
  //onExpiredChange
  function onExpiredChange(value) {
    document.querySelector("#hirePPRecaptcha iframe").style.border =
      "2px solid red";
    // sessionStorage.setItem("recaptchaFlag", false);
    setReChapthaDisable(true);
  }
  return (
    <Modal
      title="Upload Resume"
      visible={isModalVisible}
      //   onOk={handleSubmit}
      onCancel={verified ? handleClose : handleCancel}
      centered
      footer={null}
    >
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        {verified ? (
          <div className="mt-4 ">
            <Lottie
              className="m-auto w-[80%] h-[100px]"
              animationData={correct}
              loop={true}
              autoplay={true}
            />
            <p className="text-[green] flex justify-center font-semibold">
              {verifiedText}
            </p>
            <Button
              className="w-full mt-2"
              type="primary"
              size="large"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        ) : (
          <div className="font-[Inter]">
            {jobId && (
              <p className="mb-4">
                JobId: <strong>{jobId}</strong>
              </p>
            )}
            <Row className="">
              <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                <p className="mb-1">
                  First Name <span style={{ color: "red" }}> *</span>
                </p>
                <Input
                  size="large"
                  className={` ${
                    errorHandle.errorFirstName.length > 1
                      ? "border-red-500"
                      : ""
                  }`}
                  onChange={(e) =>
                    handleChange(e, "firstName", "errorFirstName")
                  }
                />
                <p className="text-[red]"> {errorHandle.errorFirstName} </p>
              </Col>
              <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                <p className="mb-1">Last Name</p>
                <Input
                  size="large"
                  onChange={(e) => handleChange(e, "lastName", "errorLastName")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                <p className="mb-1">
                  Phone Number <span style={{ color: "red" }}> *</span>
                </p>
                <Input
                  size="large"
                  className={` ${
                    errorHandle.errorPhoneNo.length > 1 ? "border-red-500" : ""
                  }`}
                  onChange={(e) => handleChange(e, "phoneNo", "errorPhoneNo")}
                />
                <p className="text-[red]"> {errorHandle.errorPhoneNo} </p>
              </Col>
              <Col md={12} sm={24} xs={24} className="px-1 mt-2">
                <p className="mb-1">
                  Email ID <span style={{ color: "red" }}> *</span>
                </p>
                <Input
                  size="large"
                  className={` ${
                    errorHandle.errorEmail.length > 1 ? "border-red-500" : ""
                  }`}
                  onChange={(e) => handleChange(e, "email", "errorEmail")}
                />
                <p className="text-[red]"> {errorHandle.errorEmail} </p>
              </Col>
            </Row>

            <div
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              className=" mt-8"
            >
              {/* <FormGroup> */}
              <input
                onChange={handleFileReader}
                type="file"
                id="file-zip"
                accept=".pdf,.doc,.docx"
                name="uploadZipFile"
                required
                className="w-full border-2 mt-5"
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-zip"
                className="  rounded-3 p-3 text-center font-semibold"
                style={{
                  // background: "linear-gradient(45deg, #121C26, #206DC5)",
                  background: "#E8E8E8",
                  borderRadius: "6px",
                  color: "#383838",
                  cursor: "pointer",
                  // border: "none",
                  outline: "none",
                  display: "block",
                  // width:"100%"
                }}
              >
                Upload or Drop, .pdf,doc,docx
              </label>
              <div
                style={{ color: "green" }}
                className="font-semibold text-center"
              >
                {resumeFile?.name}
              </div>
              <p className="text-[red]">{errorHandle.errorFile}</p>
              {/* </FormGroup> */}
            </div>
            <div
              className="field flex justify-center mt-3"
              // type={config.type}
              style={{
                width: "100%",
                fontSize: "1.1rem",
              }}
            >
              <ReCAPTCHA
                className=""
                id="hirePPRecaptcha"
                sitekey="6LctnlkpAAAAAIG2BRLu70wFnmmIlPi6S-ieqaBp"
                onChange={onCaptchaChange}
                onExpired={onExpiredChange}
              />
            </div>

            <Row className="mt-3">
              <Col md={12} sm={12} xs={24} className="px-2 mt-2">
                <Button className="w-full" size="large" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col md={12} sm={12} xs={24} className="px-2 mt-2">
                <Button
                  className="w-full"
                  size="large"
                  type="primary"
                  onClick={submitApi}
                  disabled={reChapthaDisable}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default ResumeUploadModal;
