import React, { useEffect, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PdfViewer = ({ url, zoom, setPdfPages }) => {
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);

  // Initial zoom factor

  useEffect(() => {
    setLoader(true);
    const loadPdf = async () => {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      setPdfPages(numPages);
      const imgUrls = [];

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 1 }); // Always start with scale 1
        const scale = viewport.scale * 5; // Adjust scale based on zoom factor
        const scaledViewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        await page.render({ canvasContext: context, viewport: scaledViewport })
          .promise;
        imgUrls.push(canvas.toDataURL());
      }

      setImages(imgUrls);
      setLoader(false);
    };

    loadPdf();
  }, [url]);

  const containerStyle = {
    width: `${100 * zoom}%`, // Adjust container width based on zoom factor
    // border: "1px solid #D0D5DD",
    // borderRadius: "8px",
    // overflow: "auto", // Ensure content overflow behaves correctly
    // marginBottom: "20px",
  };

  return (
    <>
      <Spin
        spinning={loader}
        className=" min-h-[400px]"
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        <div className=" overflow-x-auto p-0">
          {/* <p>{loader}</p> */}
          <div className="mx-auto my-0 p-0" style={containerStyle}>
            {images.map((imgUrl, index) => (
              <img
                key={index}
                src={imgUrl}
                alt={`Page ${index + 1}`}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default PdfViewer;
