import React from "react";
import { Col, Row } from "antd";
import "../../styles/imprint.css";

function ImprintSecondSection({ currLangJSON }) {
    const details = currLangJSON["imprintPage"].secondSec.details;

    return (
        <div className="w-full flex justify-center imprint-para-main-container">
            <Row justify="center" className="w-full">
                <Col xs={24} md={20} lg={16} className="imprint-para px-4 md:px-8 lg:px-0">
                    <div className="imprint-section-heading">
                        {currLangJSON["imprintPage"].secondSec.heading}
                    </div>
                    <ul className="imprint-definition-list">
                        {details.map((item, index) => (
                            <li key={index} className="imprint-definition-item">
                                <span className="imprint-term">{item.subheading}:</span>
                                <span className="imprint-definition">{item.description}</span>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    );
}

export default ImprintSecondSection;
