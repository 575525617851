import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacyFifthSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.fifthSec.table.columns.recipient"],
      dataIndex: 'recipient',
      key: 'recipient',
    },
    {
      title: currLangJSON["privacyPage.fifthSec.table.columns.purpose"],
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: currLangJSON["privacyPage.fifthSec.table.columns.type"],
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const data = currLangJSON["privacyPage.fifthSec.table.rows"].map((row, index) => ({
    key: index,
    recipient: row.recipient,
    purpose: row.purpose,
    type: row.type,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.fifthSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.fifthSec.description"]}</p>
      <h3 className="text-xl font-semibold mb-2">{currLangJSON["privacyPage.fifthSec.table.title"]}</h3>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.fifthSec.examplesHeading"]}</h3>
      <ul className="list-disc pl-6">
        {currLangJSON["privacyPage.fifthSec.examples"].map((example, index) => (
          <li key={index} className="mb-2">
            <strong>{example.title}:</strong> {example.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacyFifthSection;
