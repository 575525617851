import { Card } from "antd";
import React, { useState } from "react";
import Icon from "../../assets/icon/Icons";
import ResumeUploadModal from "./ResumeUploadModal";
import { ExportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function JobCard({ job }) {
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobId, setJobId] = useState();
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const truncatedSummary = `${job.jdSummary?.slice(0, 230)}...`;

  const toggleSummary = (e) => {
    e.preventDefault();
    setShowFullSummary(!showFullSummary);
  };

  const converToReadableDate = (value) => {
    const preferredJoiningDate = value;
    let readableDate = "";
    if (preferredJoiningDate) {
      const date = new Date(preferredJoiningDate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      readableDate = date.toLocaleDateString("en-US", options);
    }
    return readableDate;
  };

  return (
    <>
      {isModalVisible && (
        <ResumeUploadModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          jobId={jobId}
        />
      )}
      <Link to={`/jobs/viewJob/${job.jdIdentifier}`} target="_blank">
        <Card className="rounded-2xl shadow hover:shadow-md cursor-pointer transition-shadow font-[Inter] border border-[#e3e3e6]">
          <div className="flex gap-3 justify-between items-start">
            <div className="w-[96%] flex justify-between flex-wrap gap-3 items-start">
              <div className="font-semibold text-base text-[#101828] flex gap-3">
                <div className="flex gap-2 items-start flex-wrap">
                  <div>
                    {job.title
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </div>
                  <div className="bg-[#ECFDF3] rounded-2xl border border-[#ABEFC6] px-[10px] text-[0.73rem] text-[#067647] flex gap-2 items-center">
                    <svg
                      width="6"
                      height="7"
                      viewBox="0 0 6 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="3" cy="3.25" r="3" fill="#17B26A" />
                    </svg>
                    {job.jobDomain}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 items-center">
                <div className=" font-semibold text-[13px] bg-[#eaf3fe] rounded-2xl border border-[#eaf3fe] px-[10px] text-[#185294]">
                  <span className="">{job.numberOfOpenings}</span>
                  <span className=""> Openings</span>
                </div>
                <div className=" font-semibold text-[13px]  bg-[#ECFDF3] rounded-2xl border border-[#ABEFC6] px-[10px] text-[#067647]">
                  <span className="">{job.totalActiveApplicants}</span>
                  <span> Applications</span>
                </div>
              </div>
            </div>
            <Link
              className=""
              to={`/jobs/viewJob/${job.jdIdentifier}`}
              target="_blank"
            >
              <ExportOutlined className="text-[18px]" />
            </Link>
          </div>
          {job.interviewRoomSkillList ? (
            <div className="flex flex-wrap gap-2 mt-[12px]">
              {job.interviewRoomSkillList.map((skill, index) => (
                <div key={index} className="px-3 py-[3px] border border-[#eaf3fe] rounded-lg text-[#185294] bg-[#eaf3fe] text-[13px]">
                  {skill.skillName}
                </div>
              ))}
            </div>
          ) : null}
          {job.jdSummary ? (
            <p className="text-[#475467] mt-5 text-sm ">
              {showFullSummary ? job.jdSummary : truncatedSummary}
              <span
                className="text-blue-400 cursor-pointer text-sm ml-1"
                onClick={toggleSummary}
              >
                {showFullSummary ? " Read Less" : " Read More"}
              </span>
            </p>
          ) : null}
          <div className="flex items-center gap-3 md:gap-6 mt-5 flex-wrap ">
            <div className="flex items-center gap-[6px]">
              <Icon type="job-section-jobLocation" />
              <div className="text-[#475467] text-sm">
                {job.remote ? "Remote" : job.jobLocation}
              </div>
            </div>
            <div className="flex items-center gap-[6px]">
              <Icon type="job-section-jobType" />
              <div className="text-[#475467] text-sm">
                {job.jobType
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </div>
            </div>
            <div className="flex items-center gap-[6px]">
              <div className="text-[#475467] text-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#98A2B3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-landmark"
                >
                  <line x1="3" x2="21" y1="22" y2="22" />
                  <line x1="6" x2="6" y1="18" y2="11" />
                  <line x1="10" x2="10" y1="18" y2="11" />
                  <line x1="14" x2="14" y1="18" y2="11" />
                  <line x1="18" x2="18" y1="18" y2="11" />
                  <polygon points="12 2 20 7 4 7" />
                </svg>
              </div>
              <div className="text-[#475467] text-sm">
                {job.currencyType} {job.minSalary} - {job.maxSalary}{" "}
                <span>per year</span>
              </div>
            </div>
            <div className="flex items-center gap-[6px]">
              <div className="text-[#475467] text-sm">Min Experience:</div>
              <div className="text-[#475467] text-sm">
                {job.minExperience}+ years
              </div>
            </div>
            <div className="text-[#475467] text-sm ml-auto">
              Closing Date:{" "}
              <span className="font-semibold">
                {job.preferredJoiningDate
                  ? converToReadableDate(job.preferredJoiningDate)
                  : "NA"}
              </span>
            </div>
          </div>
          <div className="flex justify-end gap-3 mt-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsModalVisible(true);
                setJobId(job.jdIdentifier);
              }}
              className="apply-btn font-semibold text-[#F2F8FE] shadow-sm bg-gradient-to-tr from-[#103662] to-[#206DC5] px-6 lg:px-10 py-2 text-[13px] rounded-lg"
            >
              Apply Now
            </button>
          </div>
        </Card>
      </Link>
    </>
  );
}

export default JobCard;
