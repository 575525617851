import React from "react";
import { Carousel, Button, Tooltip } from "antd";
import "tailwindcss/tailwind.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const MobileCarousel = ({ currLangJSON }) => {
  const plans = Object.keys(currLangJSON.pricingPage.heroSection.plans).map(
    (planKey) => currLangJSON.pricingPage.heroSection.plans[planKey]
  );
  const carouselRef = React.createRef();

  const next = () => {
    carouselRef.current.next();
  };

  const prev = () => {
    carouselRef.current.prev();
  };

  return (
    <div
      className="md:hidden relative bg-gray-100 py-10"
      style={{ backgroundColor: "#f9fbfe" }}
    >
      <Carousel dotPosition="bottom" ref={carouselRef}>
        {plans.map((plan, index) => (
          <div key={index}>
            <div className="flex justify-center">
              <div className="hirePPLandingPageBoxShadow bg-white shadow-lg rounded-lg p-6 w-10/12 text-center relative flex flex-col mx-4">
                <h2 className="text-xl font-semibold mb-2 priceCardTitle">
                  {plan.title}
                </h2>
                {/* <p className="text-3xl font-bold">{plan.price}</p> */}
                {/* <p className="text-gray-500 mb-4">{plan.billingCycle}</p> */}
                <p className="mb-4 flex-grow">{plan.description}</p>
                {plan.mostPopular && (
                  <Tooltip title={plan.mostPopular}>
                    <span className="absolute right-4 top-4 bg-orangish text-white px-2 py-1 text-xs rounded-full z-10">
                      {plan.mostPopular}
                    </span>
                  </Tooltip>
                )}
                <div className="mt-auto">
                  <Link to="/contact-us">
                    <Button type="primary" className="w-full mb-2">
                      {plan.buttons.getStarted}
                    </Button>
                  </Link>
                  {/* <Link to="/contact-us">
                    <Button className="w-full">
                      {plan.buttons.contactSales}
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <Button
        className="absolute top-1/2 left-2 transform -translate-y-1/2"
        shape="circle"
        icon={<LeftOutlined />}
        onClick={prev}
      />
      <Button
        className="absolute top-1/2 right-2 transform -translate-y-1/2"
        shape="circle"
        icon={<RightOutlined />}
        onClick={next}
      />
    </div>
  );
};

export default MobileCarousel;
