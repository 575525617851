import React, { useState } from 'react'
import Topbar from '../components/TopBar'
import Footer from '../components/Footer'
import NotificationSection from '../components/NotificationSection'
import English from "../utils/language/English.json"
import German from "../utils/language/German.json"
import French from "../utils/language/French.json"

function Resources() {
  const [globalLang, setGlobalLang] = useState(English)

  const langChange = (storedLang)=>{
    // setGlobalLang(value)
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
    // console.log(value)
  }
  return (
    <div className="public-landing-page">
    <Topbar page="resource" globalLang={langChange}/>
    {/* <HeroSection /> */}
    <NotificationSection/>
    <Footer/>
  </div>
  )
}

export default Resources