import { Col, Row } from "antd";
import React, { useState } from "react";
import "../../styles/faq/faqSecSection.css";
import icon1 from "../../assets/faq/secondSection/icon1.svg";
import icon2 from "../../assets/faq/secondSection/icon2.svg";
import icon3 from "../../assets/faq/secondSection/icon3.svg";
import icon4 from "../../assets/faq/secondSection/icon4.svg";
import icon5 from "../../assets/faq/secondSection/icon5.svg";
import secondSecImg from "../../assets/faq/secondSection.svg";

function FAQSecondSection({ currLangJSON }) {
  const [showFullSummary, setShowFullSummary] = useState(
    new Array(5).fill(false)
  );
  function truncateFunc(index) {
    const truncatedSummary = `${currLangJSON.FAQ.secondSection.container[
      index
    ].subHeading?.slice(0, 100)}...`;
    return truncatedSummary;
  }
  //   const truncatedSummary2 = `${currLangJSON.FAQ.secondSection.container.subHeading2?.slice(
  //     0,
  //     100
  //   )}...`;
  //   const truncatedSummary3 = `${currLangJSON.FAQ.secondSection.container.subHeading2?.slice(
  //     0,
  //     100
  //   )}...`;
  //   const truncatedSummary4 = `${currLangJSON.FAQ.secondSection.container.subHeading4.upperText?.slice(
  //     0,
  //     50
  //   )}...`;
  //   const truncatedSummary5 = `${currLangJSON.FAQ.secondSection.container.subHeading5.upperText?.slice(
  //     0,
  //     25
  //   )}...`;
  const toggleSummary = (index) => {
    setShowFullSummary((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  return (
    <div className="w-[90%] m-auto  mt-14">
      <p
        className="text-4xl font-semibold "
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        {currLangJSON.FAQ.secondSection.header}
      </p>
      <p
        className="text-xl text-[#475467]  mt-4"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        {currLangJSON.FAQ.secondSection.subHeader}
      </p>

      <div className=" w-full ">
        <Row className="w-full mt-1 ">
          <Col
            md={12}
            className=" w-full mt-2"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-once={true}
            data-aos-easing="ease"
          >
            {currLangJSON.FAQ.secondSection?.container.map((item, index) => (
              <div
                className="flex gap-4 pl-4 pr-0 items-start mt-4"
                key={index}
              >
                {index == 0 && <img src={icon1} alt="" />}
                {index == 1 && <img src={icon2} alt="" />}
                {index == 2 && <img src={icon3} alt="" />}
                {index == 3 && <img src={icon4} alt="" />}
                {index == 4 && <img src={icon5} alt="" />}
                <div className="mt-3">
                  <p className="text-xl font-semibold"> {item.header} </p>
                  <p className="text-[#475467] mt-2 text-sm text-[#475467]">
                    {showFullSummary[index] ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: item.subHeading }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: truncateFunc(index),
                        }}
                      />
                    )}

                    <span
                      className="text-[#98A2B3] cursor-pointer text-sm ml-1"
                      onClick={() => toggleSummary(index)}
                    >
                      {showFullSummary[index] ? " Read Less" : " Read More"}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </Col>
          <Col
            md={12}
            className="w-full flex items-start mt-8  justify-center "
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-once={true}
            data-aos-easing="ease"
          >
            <img src={secondSecImg} alt="" className="faq-sec-img" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FAQSecondSection;
