import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacyEighthSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.eighthSec.table.columns.type"],
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: currLangJSON["privacyPage.eighthSec.table.columns.period"],
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: currLangJSON["privacyPage.eighthSec.table.columns.reason"],
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  const data = currLangJSON["privacyPage.eighthSec.table.rows"].map((row, index) => ({
    key: index,
    type: row.type,
    period: row.period,
    reason: row.reason,
  }));

  return (
    <div className="privacy-table-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.eighthSec.heading"]}</h2>
      <p className="mb-6">{currLangJSON["privacyPage.eighthSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.eighthSec.additionalInfoHeading"]}</h3>
      <ul className="list-disc ml-6">
        {currLangJSON["privacyPage.eighthSec.additionalInfo"].map((info, index) => (
          <li key={index} className="mb-2">
            <strong>{info.title}</strong>: {info.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrivacyEighthSection;
