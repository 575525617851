import React, { useState, useEffect } from 'react'
import Topbar from '../components/TopBar'
import Footer from '../components/Footer'
import NotificationSection from '../components/NotificationSection'
import English from "../utils/language/English.json"
import German from "../utils/language/German.json"
import French from "../utils/language/French.json"
import PricingHeroSection from '../components/pricing/HeroSection'
import Table3 from '../components/pricing/Table3'
import Table2 from '../components/pricing/Table2'
import Table1 from '../components/pricing/Table1'
import PricingLastSection from '../components/pricing/PricingLastSection'

function Pricing() {
  const [globalLang, setGlobalLang] = useState(English)

  useEffect(() => {
    const handleScroll = () => {
      var topBar = document.querySelector('.topbar-logo');
      var hamburger = document.querySelector('.fas.fa-bars');
      var backCover = document.querySelector('.back-cover');
      var weServe = document.querySelector('.weServe-text');
      var topbardText = document.querySelectorAll('.topbard-text');
      var topBarNav = document.querySelector('.topbar .right-section .nav-menu ');
      var aiIcon = document.querySelector(".ai-topbar-icon path");
      
      if (window.scrollY > 0) {
        topBar.style.background = 'white';
        backCover.style.background = 'white';
        backCover.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        topBar.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';
        hamburger.style.color = '#0638c3';
        
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = '#206DC5';
        }
        
        weServe.style.color = '#0638c3';
        topBar.style.transition = 'ease-in-out 3s ';
        topBarNav.style.transition = 'ease-in-out 3s ';
        
        if (aiIcon) {
          aiIcon.style.stroke = 'rgb(32, 109, 197)';
          aiIcon.style.transition = 'all ease-in-out 0.2s';
        }
      } else {
        if (aiIcon) {
          aiIcon.style.stroke = 'white';
          aiIcon.style.transition = 'all ease-in-out 0.2s';
        }
        
        hamburger.style.color = 'white';
        weServe.style.color = 'white';
        
        for (let i = 0; i < topbardText.length; i++) {
          topbardText[i].style.color = 'white';
        }
        
        topBar.style.background = 'none';
        backCover.style.background = 'none';
        backCover.style.boxShadow = "none";
        topBar.style.boxShadow = "none";
        topBar.style.transition = 'background 0.3s ease';
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const langChange = (storedLang) => {
    if (storedLang === null) {
      setGlobalLang(English);
    } else if (storedLang === "english") {
      setGlobalLang(English);
    } else if (storedLang === "french") {
      setGlobalLang(French);
    } else if (storedLang === "german") {
      setGlobalLang(German);
    }
  }

  return (
    <div className="public-landing-page">
      <Topbar page="pricing" topbarCss="css" globalLang={langChange} />
      <PricingHeroSection currLangJSON={globalLang} />
      <Table1 currLangJSON={globalLang}/>
      <Table2 currLangJSON={globalLang}/>
      <Table3 currLangJSON={globalLang}/>
      <PricingLastSection currLangJSON={globalLang} />
      <NotificationSection currLangJSON={globalLang} />
      <Footer currLangJSON={globalLang} />
    </div>
  )
}

export default Pricing;
