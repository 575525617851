import React from 'react';
import { Table } from 'antd';
import "../../styles/termsAndCondition.css";

const PrivacySecondSection = ({ currLangJSON }) => {
  const columns = [
    {
      title: currLangJSON["privacyPage.secondSec.table1.columns.category"],
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: currLangJSON["privacyPage.secondSec.table1.columns.examples"],
      dataIndex: 'examples',
      key: 'examples',
    },
    {
      title: currLangJSON["privacyPage.secondSec.table1.columns.categories"],
      dataIndex: 'categories',
      key: 'categories',
    },
  ];

  const data = currLangJSON["privacyPage.secondSec.table1.rows"].map((row, index) => ({
    key: index,
    category: row.category,
    examples: row.examples,
    categories: row.categories,
  }));

  return (
    <div className="privacy-second-section p-8 bg-white">
      <h2 className="text-2xl font-bold mb-4">{currLangJSON["privacyPage.secondSec.heading"]}</h2>
      <h3 className="text-xl font-semibold mb-2">{currLangJSON["privacyPage.secondSec.subHeading"]}</h3>
      <p className="mb-6">{currLangJSON["privacyPage.secondSec.description"]}</p>
      <Table columns={columns} dataSource={data} pagination={false} />
      <h3 className="text-xl font-semibold mt-6">{currLangJSON["privacyPage.secondSec.subHeadingSensitive"]}</h3>
      <p className="mb-6">{currLangJSON["privacyPage.secondSec.table2.title"]}</p>
      <Table 
        columns={columns} 
        dataSource={[{
          key: 0,
          category: currLangJSON["privacyPage.secondSec.table2.rows"][0].category,
          examples: currLangJSON["privacyPage.secondSec.table2.rows"][0].examples,
          categories: currLangJSON["privacyPage.secondSec.table2.rows"][0].categories,
        }]} 
        pagination={false} 
      />
      <p className="mt-6">{currLangJSON["privacyPage.secondSec.conclusion"]}</p>
    </div>
  );
};

export default PrivacySecondSection;
