import React from "react";
import { Col, Row } from "antd";
import "../../styles/imprint.css";

function ImprintFirstSection({ currLangJSON }) {
    return (
        <>
            <Row className="data-hero-section" justify="center">
                <Col xs={24} md={20} lg={16} className="data-content">
                    <div className="data-banner">
                        <span>{currLangJSON["imprintPage"].firstSec.currentDate}</span>
                    </div>
                    <div className="data-main-heading">
                        {currLangJSON["imprintPage"].firstSec.heading}
                    </div>
                    <div className="data-sub-heading">
                        {currLangJSON["imprintPage"].firstSec.subHeading}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ImprintFirstSection;
