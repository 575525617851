import React, { useState } from "react";
import "../../styles/jobsPage/jobsHeroSection.css";
import dot from "../../assets/logo/Dot.svg";
import { Col, Row , Button} from "antd";
import LandingPageImg from "../../assets/images/jobs-hero-landing-page.svg";
import { Link } from "react-router-dom";
import ResumeUploadModal from "./ResumeUploadModal";

function JobsHeroSection({ currLangJSON }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [reChapthaDisable, setReChapthaDisable] = useState(true);
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {isModalVisible && (
        <ResumeUploadModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
        />
      )}

      <Row className="jobs-hero-section gap-8 ">
        <Col md={11} className="job-left-sec  m-auto">
          <div className="jobs-hiring-banner text-[#101828] font-semibold">
            <img src={dot} alt="dot" className="" />
            Jobs and Careers
          </div>
          <div className="text-white font-bold subHeading-text flex-inline">
            {currLangJSON["jobsLanding.firstSec.subHeading"]}
            <span className="text-orangish flex-inline break-words">
              {" "}
              {currLangJSON["jobsLanding.firstSec.subHeading.yellowText"]}
            </span>
          </div>
          <div className="text-[white] mt-5 text-base">
            {currLangJSON["jobsLanding.firstSec.sub.subHeading"]}
            <strong>
              {" "}
              {currLangJSON["jobsLanding.firstSec.uploadCv"]}
            </strong>{" "}
            {currLangJSON["jobsLanding.firstSec.sub.subHeading.afterBold"]}
          </div>
          <div className="mt-8 flex justify-start  w-full gap-4">
            <Link
              to="https://www.linkedin.com/company/hireplusplus/"
              target="_blank"
              className="mt-2"
            >
              <Button
                className="font-semibold text-sm w-100 text-[#101828]"
                size="large"
              >
                {currLangJSON["publicLanding.secondSec.card.learnMore"]}
              </Button>
            </Link>

    
              <Button
                className="font-semibold text-sm bg-[#206DC5] text-[#FCFCFD] mt-2"
                size="large"
                style={{ outline: "none", border: "none" }}
                onClick={() => setIsModalVisible(true)}
              >
               {currLangJSON["jobsLanding.sixthSec.button"]}
              </Button>

          </div>
        </Col>
        <Col md={11} className="job-right-sec">
          <img src={LandingPageImg} alt="" className="" />
        </Col>
      </Row>
    </>
  );
}

export default JobsHeroSection;
