import React from "react";
import "../../styles/faq/faqHeroSection.css";

function FAQHeroSection({ currLangJSON }) {
  return (
    <div className="faq-hero-section">
      <p className="text-center text-[white] font-semibold">FAQ's</p>
      <p
        className="text-5xl text-[white] text-center px-1 mt-4 font-semibold"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        {currLangJSON.FAQ.heroSection.heading}
      </p>
      <p
        className="text-xl text-[white] text-center px-1 mt-8"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-once={true}
        data-aos-easing="ease"
      >
        {currLangJSON.FAQ.heroSection.subHeading}
      </p>
    </div>
  );
}

export default FAQHeroSection;
