import React, { useEffect } from "react";
import Cookies from "js-cookie";

const ScriptInjector = ({ scriptFlag }) => {
  useEffect(() => {
    const hirePPInitCookie = Cookies.get("hirePPInitCookie");

    if (hirePPInitCookie === "true") {
      const addScript = (id, src, innerHTML, isAsync = true, defer = false) => {
        if (!document.getElementById(id)) {
          const script = document.createElement("script");
          script.id = id;
          if (src) script.src = src;
          if (innerHTML) script.innerHTML = innerHTML;
          script.async = isAsync;
          script.defer = defer;
          document.body.appendChild(script);
        }
      };

      // Clarity
      addScript("clarity-script", null, `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "l98q21jo9f");
      `);

      // Leadfeeder
      addScript("leadfeeder-script", null, `
        (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; 
        (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; 
        ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('3P1w24d2kQB8mY5n');
      `);

      // Slashmultiple
      addScript("faitracker-script", null, `
        window.faitracker=window.faitracker||function(){this.q=[];var t=new CustomEvent("FAITRACKER_QUEUED_EVENT");return this.init=function(t,e,a){this.TOKEN=t,this.INIT_PARAMS=e,this.INIT_CALLBACK=a,window.dispatchEvent(new CustomEvent("FAITRACKER_INIT_EVENT"))},this.call=function(){var e={k:"",a:[]};if(arguments&&arguments.length>=1){for(var a=1;a<arguments.length;a++)e.a.push(arguments[a]);e.k=arguments[0]}this.q.push(e),window.dispatchEvent(t)},this.message=function(){window.addEventListener("message",function(t){"faitracker"===t.data.origin&&this.call("message",t.data.type,t.data.message)})},this.message(),this.init("yrwdnnpaag85wjxcse4trhc3bqrmrmo5",{host:"https://api.slashmultiple.com"}),this}(),function(){var t=document.createElement("script");t.type="text/javascript",t.src="https://asset.slashmultiple.com/slashmultiple.js",t.async=!0,(d=document.getElementsByTagName("script")[0]).parentNode.insertBefore(t,d)}();
      `);

      // Warmly
      addScript("warmly-script-loader", "https://opps-widget.getwarmly.com/warmly.js?clientId=0e13fb306d619493e3185f222e729133", null, true, true);

      // Google Analytics
      addScript("gtag-script", "https://www.googletagmanager.com/gtag/js?id=G-NVC32BVWD6");
      addScript("gtag-config-script", null, `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-NVC32BVWD6');
      `);
    }
  }, [scriptFlag]);

  return null;
};

export default ScriptInjector;
